export const LANG_ES_NAME = 'es';

export const LANG_ES_TRANS = {
    "GOVERNMENT_ID_DOC": "Licencia de conducir / Identificación del estado",
    "PASSPORT": "Pasaporte",
    "NEXT": "Siguiente",
    "WELCOME": "Bienvenido",
    "WELCOME_TO": "Bienvenido %0",
    "LANDING_MSG": "Vamos a llevarlo a través de los siguientes pasos para validar su identidad. Sólo debe tomar unos minutos.",
    "CHOOSE_ID_TYPE_LANDING_MSG": "Elija el tipo de documento de identidad que desea utilizar",
    "ID_LANDING_MSG": "Captura fotos de la parte delantera y trasera de tu ID",
    "SELFIE_LANDING_MSG": "Tomaremos dos fotos autofotos para la verificación.",
    "THATS_IT": "¡Eso es!",
    "LET_STARTED": "EMPECEMOS",
  
    "FIRST_NAME":"Nombre de pila",
    "LAST_NAME": "Apellido",
    "EMAIL_ID":"Identificación de correo:",
    "COUNTRY_CODE":"Código de país",
    "PHONE_NUMBER":"Número de teléfono",   
    "ID_FRONT_INSTR_MSG_1": "Cómo tomar una foto de su licencia de conducir / identificación estatal",
    "ID_INSTR_MSG_2": "Coloque su licencia de conducir / identificación estatal para garantizar que sea legible y sin deslumbramiento",
    "PASSPORT_INSTR_MSG_1": "Cómo tomar una foto de su pasaporte",
    "PASSPORT_INSTR_MSG_2": "Coloque su pasaporte en forma de L para garantizar la legibilidad y no deslumbrar",
    "ID_BACK_INSTR_MSG_1": "Cómo tomar una foto de la parte posterior de su licencia de conducir / identificación estatal",
    "CONFIRM_STATIC_CONTENT": "Confirmar contenido estático",
    "CAMERA_STATIC_CONTENT": "Contenido estático de la cámara",
    "CONFIRM_PICTURE": "Confirmar imagen",
    "RETAKE": "VOLVER A TOMAR",
    "CONFIRM_NEXT": "SIGUIENTE",
    "ERROR_TITLE": "¡Error!",
    "GOT_IT": "LO TENGO",
  
    "SELFIE_INSTR_MSG_1": "Estaremos tomando dos fotos autofotos.",
    "SELFIE_INSTR_MSG_2": "Por favor continúe tomando dos fotos de su cara en diferentes distancias",
  
    "CONFIRMATION_ID_SUBMITTED":"ID enviado",
    "CONFIRMATION_SELFIE_SUBMITTED":"Selfie Enviado",
    "CONFIRMATION_LIVENESS_SELFIE_SUBMITTED":"Liveness Selfie Enviado",
    "WAIT_MSG":"Procesando .. por favor espere...",
    "DECISION_PROCESSING":"Procesamiento de decisiones",
    "ERROR_NOTIFY_MSG":"Parece un problema?",
    "RESULTS": "RESULTADOS: %0",
    "RETRY_ATTEMPT_MSG": "Reintentar intento: %0",
    "SUCCESS_MSG":"Verificado con éxito",
    "ID_DOC_VERIFIED": "Documento de identidad verificado",
    "PASSPORT_VERIFIED": "Pasaporte verificado",
    "SELFIE_VERIFIED": "Autofoto verificado",
    "LIVENESS_SELFIE_VERIFIED": "Liveness Selfie Verificado", 
    "UNAUTHORIZED_MSG": "El documento de ID dado no es compatible, utilice un documento de ID alternativo",   

    "RESULT_THANK_YOU": "¡Gracias! Parece que tenemos todo lo que necesitamos ahora.",
    "WE_WILL_GET_BACK": "Uno de nuestros representantes se pondrá en contacto con usted pronto.",

    "ERROR_MSG_BAD_REQUEST":"¡Uy! Por favor, compruebe la URL. Parece que la URL no es válida o el servicio no está disponible temporalmente.",
    "ERROR_MSG_LINK_EXPIRED":"Este enlace ya no está activo.",
    "ERROR_MSG_INVITE_FAILURE":"El servicio no está disponible actualmente, inténtalo de nuevo más tarde.",
    "ERROR_MSG_VERIFIED_PASSED":"Gracias, ya has sido verificado.",
    "CONSENT_AUTHORIZATION_HEADER" : "Authorization",
    "COMMONS_NEXT" : "NEXT",
    "CONSENT_AUTHORIZATION_CONTENT" : "<p>The individual understands that, for security, identity verification, and fraud-prevention purposes, %0 (“the Company”) sometimes employs biometric technology to verify identity, to prevent fraud, and to control and limit access to the Company’s services and property.  In furtherance of these security, identity verification, and fraud-prevention purposes, the Company may collect biometric identifiers on individuals.  Under some applicable laws, biometric identifiers include a retina or iris scan, fingerprint, voiceprint, or record of hand or face geometry.</p><p>The biometric identifiers will be used exclusively for security, identity verification, and fraud prevention in connection with your access to the Company’s services or property.  The Company will not sell or lease your biometric identifiers unless required by law.</p><p>Please contact %1 if you have any questions.</p><p class=\"secondli\">I acknowledge that I have read and understand this notice regarding the collection and use of biometric identifiers.  I consent, as a condition of receiving or being considered to receive the Company’s services or access to its property, to providing biometric identifiers for the limited purpose of security, identity verification, and fraud prevention.</p>",
    "PLEASE_WAIT_PAGE_IS_BEING_REDIRECTED" : "Please wait for the next transition.",
    "CHOOSE_DOCUMENT_TYPE" :  "Choose the type of ID Document you'd like to use",
    "ERROR_MSG_DESKTOP_TRUE" : "Lo sentimos, esta capacidad no es compatible con un navegador portátil o de escritorio. Deberá hacer esto en un dispositivo móvil como un teléfono inteligente, utilizando el mismo enlace.",
    "OPTOUT_MODAL_HEADER_1" : "Haga clic en OPT-OUT o haga clic en GO BACK para continuar completando la solicitud.",
    "COMMONS_OPTOUT" : "OPT-OUT",
    "COMMONS_GOBACK" : "GO BACK",
    "ERROR_MSG_OPT_OUT" : "Has optado por no participar. Gracias.",
    "DONT_CONTINUE" : "No quiero continuar"
  };
