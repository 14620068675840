import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@fadv/web-common';
import { TrackEventService } from '../shared/Services/trackEvent.service';
import { SharedService } from '../shared/Services/shared.service';
import { TranslateService } from '../framework/i18n/translate.service';
import { ApplicantStatus } from '../identity-config';

declare var jQuery: any;

@Component({
  selector: 'app-id-select',
  templateUrl: './id-select.component.html',
  styleUrls: ['./id-select.component.css']
})
export class IdSelectComponent implements OnInit {

  private type: string;
  enableNext: boolean = false;
  showNext: boolean = false;
  showAbortOptionLink: boolean = false;
  redirectUrl: string;
  abortPopupMsg: string;
  showAbortOptionPref: string;
  abortLinkLabel: string;
  showNonUsFlow: string;

  constructor(private router: Router, private localStorage: LocalStorageService,
    private trackEventService: TrackEventService,
    private _ts: TranslateService,  private sharedService: SharedService ) { }

  ngOnInit() {
    jQuery('#langGlobe').css('display', 'none');
    this.redirectUrl = this.localStorage.getItem('redirect_url');
    this.showAbortOptionPref = this.localStorage.getItem('show_abort_options');
    this.abortPopupMsg = this.localStorage.getItem('id_select_abort_popup_msg');
    this.abortLinkLabel = this.localStorage.getItem('id_select_abort_link_label');
    if (this.redirectUrl != '' && this.redirectUrl != null && this.redirectUrl != undefined
      && this.showAbortOptionPref != undefined && this.showAbortOptionPref.toUpperCase() === 'YES'){
      this.showAbortOptionLink = true;
    }
    if (this.abortLinkLabel === '' || this.abortLinkLabel === undefined) {
      this.abortLinkLabel = this._ts.instant('ID_SELECT_ABORT_OPTION');
    }
    if (this.abortPopupMsg === '' || this.abortPopupMsg === undefined) {
      this.abortPopupMsg = this._ts.instant('ABORT_MODAL_HEADER_1');
    }
    this.showNonUsFlow = this.localStorage.getItem('show_non_us_flow');
    console.log('SHOW_NON_US_FLOW in id-select compi' + this.showNonUsFlow);
  }

  selectedType(type: string): void {
    this.type = type;
    this.localStorage.setItem("selectedIDType", this.type);
    this.enableNext = true;
    this.next();
  }

  next() {
    this.trackEventService.updateTrackEvent('id ' + this.type + ' selected', {stage_name: 'id select', action_value: 'next clicked'}, true, true);
    this.router.navigate(['/app-id'] , { queryParams: { invite_id: this.localStorage.getItem('invite_id')}, queryParamsHandling: 'merge' });
  }

  showAbortModal(){
    jQuery('#abortmodal').modal('show');
  }

  processAbort(){
    this.trackEventService.updateTrackEvent('id ' + this.type + ' selected', {stage_name: 'id select', action_value: 'abort clicked'}, true, false);
    jQuery('#abortmodal').modal('hide');
    jQuery('#pleaseWaitModal').modal('show');
    let inviteKey = this.localStorage.getItem("invite_key");
    let inviteId = this.localStorage.getItem("invite_id");
    let data = {
      'status' : ApplicantStatus.NO_VALID_DOCUMENTS
    }
    if (inviteKey !== undefined && inviteKey !== '') {
      this.sharedService.updateInviteStatus(data, inviteId).subscribe(response => {
        if (this.redirectUrl != undefined && this.redirectUrl != '') {
          this.trackEventService.updateTrackEvent('redirect to ' + this.redirectUrl, { stage_name: 'id select page', redirectUrl: this.redirectUrl, action_value: 'abort clicked' }, true, false);
          let url = new URL(this.redirectUrl);
          var search_params = url.searchParams;
          search_params.set('id', inviteId);
          search_params.set('action', ApplicantStatus.NO_VALID_DOCUMENTS);
          let new_url = url.toString();
          window.location.href = new_url;
        }
      },
        error => {
          this.trackEventService.updateTrackEvent('update applicant data failed', { stage_name: 'id select page', action_value: 'abort clicked' }, true, false);
          console.log("Error occurred ", error);
          jQuery('#pleaseWaitModal').modal('hide');
        });
    }
  }

  closeAbortModal(){
    jQuery('#abortmodal').modal('hide');
    jQuery('.modal-backdrop').remove();
  }
}
