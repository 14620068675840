import {Component, OnInit, Output, EventEmitter, Input, OnDestroy} from '@angular/core';
import {LocalStorageService} from "@fadv/web-common";
import {ApplicantStatus } from '../identity-config';
import {SharedService} from "../shared/Services/shared.service";
import {TrackEventService} from "../shared/Services/trackEvent.service";
import {Router} from "@angular/router";
import {TranslateService} from "../framework/i18n/translate.service"
import { environment } from '../environments/environment';


declare var jQuery: any;

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit, OnDestroy {
  actionTypeValue: string = 'try alternate methods clicked';
  custTryAltMsg: string = '';
  redirectUrl: string = '';
  isAbort: boolean = true;
  showTryAlt: boolean;
  inviteId: string;
  inviteKey: string;
  gotItButtonClicks: number = 0;
  trackGotItButtonClicks: boolean = true;
  troubleToastTimerTracker: any;
  havingTroubleToastMaxClicks: number;
  havingTroubleToastDelayTimeout: number;
  envVal: any;

  @Output() onInstructionNext: EventEmitter<any> = new EventEmitter();


  constructor(private localStorage: LocalStorageService,
              private sharedService: SharedService,
              public trackEventService: TrackEventService,
              private router: Router,
              private _ts: TranslateService) {
    this.custTryAltMsg = this._ts.instant('COMMONS_TRYALT');
    this.envVal = (!!environment && !!environment[0]) ? environment[0] : environment;
    console.log('envVal are: ', this.envVal);
  }

  ngOnInit() {
    this.inviteId = this.localStorage.getItem('invite_id');
    this.inviteKey = this.localStorage.getItem('invite_key');
    this.redirectUrl = this.localStorage.getItem('redirect_url') || this.localStorage.getItem('try_alt_methods_uri');
    this.havingTroubleToastMaxClicks = this.localStorage.getItem('havingTroubleToastMaxClicks');
    //console.log('Having trouble max click: ', this.havingTroubleToastMaxClicks);
    if (!this.havingTroubleToastMaxClicks) {
      this.localStorage.setItem('havingTroubleToastMaxClicks', this.envVal.havingTroubleToastConfig.gotItButtonClicksThreshold);
      this.havingTroubleToastMaxClicks = this.localStorage.getItem('havingTroubleToastMaxClicks');
      //console.log('Setting Trouble max click from env: ', this.envVal.havingTroubleToastConfig.gotItButtonClicksThreshold);
    }

    this.havingTroubleToastDelayTimeout = this.localStorage.getItem('havingTroubleToastDelayTimeoutSeconds');
    //console.log('Having trouble DelayTimeout: ', this.havingTroubleToastDelayTimeout);
    if (!this.havingTroubleToastDelayTimeout) {
      this.localStorage.setItem('havingTroubleToastDelayTimeoutSeconds', this.envVal.havingTroubleToastConfig.idleTimeThresholdSeconds);
      this.havingTroubleToastDelayTimeout = this.localStorage.getItem('havingTroubleToastDelayTimeoutSeconds');
      //console.log('Setting delay timeout from env: ', this.envVal.havingTroubleToastConfig.idleTimeThresholdSeconds);
    }

    const showAbortOptionPref = this.localStorage.getItem('show_abort_options');
    this.showTryAlt = (!!showAbortOptionPref) && (showAbortOptionPref.toUpperCase()==='YES');

    //console.log('show try alt is: ', this.showTryAlt);

    if(!!this.showTryAlt) {
      this.gotItButtonClicks = 0;
      this.trackGotItButtonClicks = true;
      this.troubleToastTimer();
    }
  }

  ngOnDestroy() {
    this.triggerToastClose();
    this.clearTroubleToastTrackers();
  }


  triggerToastClose() {
    this.closeToast();
    this.trackEventService.updateTrackEvent('having trouble toast dismissed', { stage_name: 'instructions', action_value: "automatic dismissal" }, true, true);
  }

  troubleToastTimer() {
    if(!this.showTryAlt) {
      return;
    }
    let _this = this;
    this.troubleToastTimerTracker = setTimeout(() => {
      this.trackEventService.updateTrackEvent('having trouble toast shown', { stage_name: 'instructions', action_value: "based on timer" }, true, true);
      _this.triggerTroubleToast();
    },_this.havingTroubleToastDelayTimeout*1000);
  }

  clearTroubleToastTimeTracker() {
    if(!!this.troubleToastTimerTracker) {
      clearTimeout(this.troubleToastTimerTracker);
    }
  }

  clearTroubleToastTrackers() {
    this.clearTroubleToastTimeTracker();
    this.gotItButtonClicks = 0;
    this.trackGotItButtonClicks = false;
  }

  triggerTroubleToast() {
    this.clearTroubleToastTrackers();
    jQuery('#tryalt-modal').modal('show');
  }

  troubleToastCounterCheck() {
    if (!!this.showTryAlt) {
      this.gotItButtonClicks++;
      if (this.trackGotItButtonClicks && (this.gotItButtonClicks >= this.havingTroubleToastMaxClicks)) {
        this.trackEventService.updateTrackEvent('having trouble toast shown', { stage_name: 'instructions', action_value: "based on clicks" }, true, true);
        this.triggerTroubleToast();
      }
    }
  }

  clickInstructionNextButton() {
    try {
      let obj = {};
      obj['message']='Clicked next';
      console.log('----- in Instructions- '+obj['message']);
      this.onInstructionNext.emit(obj);
      this.clearTroubleToastTimeTracker(); //Stops time tracking to show Having Trouble Toast
    }
    catch(e) {
      console.log('Some issue occurred in moving to Camera screen.',e);
    }
    finally {
      this.troubleToastCounterCheck();
    }
  }

  processTryAlt() {
    this.trackEventService.updateTrackEvent('having trouble toast proceed', { stage_name: 'instructions', action_value: "manually proceed link clicked" }, true, true);
    jQuery('#tryalt-modal').modal('hide');
    jQuery('#pleaseWaitModal').modal('show');
    this.localStorage.setItem('rid_status', ApplicantStatus.ALTERNATE_WORKFLOW);

    const data = {
      status: ApplicantStatus.ALTERNATE_WORKFLOW
    }

    if (this.inviteKey !== undefined && this.inviteKey !== '') {
      const inviteId = this.localStorage.getItem('invite_id');
      this.sharedService.updateInviteStatus(data, inviteId).subscribe(response => {
          this.trackEventService.updateTrackEvent('applicant data updated', { stage_name: 'instructions', action_value: this.actionTypeValue }, true, true);
          if (!!this.redirectUrl) {
            this.trackEventService.updateTrackEvent('redirect to ' + this.redirectUrl, { stage_name: 'instructions', redirectUrl: this.redirectUrl, action_value: this.actionTypeValue }, true, true);
            const url = new URL(this.redirectUrl);
            const searchParams = url.searchParams;
            searchParams.set('id', inviteId);
            searchParams.set('action', ApplicantStatus.ALTERNATE_WORKFLOW);
            window.location.href = url.toString();
          } else {
            this.trackEventService.updateTrackEvent('redirect to error page', { stage_name: 'instructions', action_value: this.actionTypeValue }, true, true);
            this.sharedService.setErrorCodeAndMsg('ALTERNATE_WORKFLOW', this.custTryAltMsg);
            jQuery('#pleaseWaitModal').modal('hide');
            this.router.navigate(['/app-error']);
          }
        },
        error => {
          this.trackEventService.updateTrackEvent('update applicant data failed', { stage_name: 'instructions', action_value: this.actionTypeValue }, true, true);
          jQuery('#pleaseWaitModal').modal('hide');
          this.router.navigate(['/app-error']);
        });
    }
  }

  closeToast() {
    jQuery('#tryalt-modal').modal('hide');
    jQuery('.modal-backdrop').remove();
    jQuery('body').removeClass('modal-open');
  }

  closeTryAltModal() {
    this.trackEventService.updateTrackEvent('having trouble toast dismissed', { stage_name: 'instructions', action_value: "manually dismiss clicked" }, true, true);
    this.closeToast();
  }
}
