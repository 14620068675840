import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TrackEventService } from '../shared/Services/trackEvent.service';
import { LocalStorageService } from '@fadv/web-common';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})

export class ConfirmComponent implements OnInit {
  @Input() resultImage: string;
  @Output() onConfirmNext: EventEmitter<any> = new EventEmitter();
  @Output() onConfirmRetake: EventEmitter<any> = new EventEmitter();

  constructor(public trackEventService: TrackEventService,
    public localStorage: LocalStorageService) { }

  ngOnInit() {
  }

  clickConfirmRetakeButton(){
    let obj = {};
    console.log('----- in clickConfirmRetakeButton- ');
    this.trackEventService.updateTrackEvent('retake picture', {stage_name: 'confirm', action_value: 'retake clicked'}, true, false);
    this.onConfirmRetake.emit(obj);
  }

  clickConfirmNextButton(){
    let obj = {};
    console.log('----- in clickConfirmNextButton- ');
    this.trackEventService.updateTrackEvent('save captured image', {stage_name: 'confirm', action_value: 'next clicked'}, true, true);
    this.onConfirmNext.emit(obj);
  }

}
