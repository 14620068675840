export const LANG_EN_NAME = "en";

export const LANG_EN_TRANS = {
  "RETRY_AUTO": "RETRY AUTO",
  "RETRY_MANUAL": "RETRY MANUAL",
  "MISNAP_HEAD_OUTSIDE": "Slowly place your face in the oval and wait.",
  "MISNAP_HEAD_SKEWED": "Look straight ahead.",
  "MISNAP_AXIS_ANGLE": "Hold phone upright.",
  "MISNAP_HEAD_TOO_CLOSE": "Slowly move farther away.",
  "MISNAP_HEAD_TOO_FAR": "Slowly get closer.",
  "MISNAP_STAY_STILL": "Hold still in a well-lit space.",
  "MISNAP_SUCCESS": "Success.",
  "MISNAP_STOP_SMILING": "Stop smiling.",
  "MISNAP_SMILE": "Hold a smile.",
  "MISNAP_READY_POSE": "Hold it there.",
  "NO_FACE_FOUND": "Detecting face, please wait.",
  "MITEK_ERROR_GLARE": "Reduce glare.",
  "MITEK_ERROR_FOUR_CORNER": "Document not found.",
  "MITEK_ERROR_TOO_DARK": "Too Dark. Use good lighting",
  "MITEK_ERROR_FOCUS": "The image is too blurry.",
  "MITEK_ERROR_MRZ_MISSING": "Can't read every element on the photo page of your passport.",
  "CV_NO_BARCODE_FOUND": 'Scanning for barcode.',
  "CUSTOM_AUTO_TIMEOUT": "Timeout, Please retry.",
  "GENERAL_MITEK_ERROR_FOUR_CORNER": "We can't find the 4 corners of your document.",
  "GENERAL_MITEK_ERROR_TOO_DARK": "There is not enough light on your document.",
  "GENERAL_MITEK_ERROR_FOCUS": "The image is too blurry.",
  "GENERAL_MITEK_ERROR_GLARE": "The image has glare.",
  "GENERAL_MITEK_ERROR_MIN_PADDING": "Move the camera further away from your document.",
  "GENERAL_MITEK_ERROR_HORIZONTAL_FILL": "Move the camera closer to your document.",
  "GENERAL_MITEK_ERROR_SKEW_ANGLE": "Document is skewed.  Hold camera directly over your document.",
  "GENERAL_MITEK_ERROR_BUSY_BACKGROUND": "The background is too busy.  Please use a solid background.",
  "GENERAL_MITEK_ERROR_MRZ_MISSING": "No MRZ found",
  "GENERAL_CV_NO_BARCODE_FOUND": "We were unable to detect the barcode from the back of your license.",
  "GENERAL_IMAGE_SMALLER_THAN_MIN_SIZE": "The image you provided is too small.",
  "GENERAL_CORRUPT_IMAGE": "The image you provided is unreadable.",
  "GENERAL_MISNAP_HEAD_SKEWED": "Look Straight Ahead",
  "GENERAL_MISNAP_HEAD_TOO_CLOSE": "Move Farther Away",
  "GENERAL_MISNAP_HEAD_TOO_FAR": "Get Closer",
  "GENERAL_NO_FACE_FOUND": "No Face Detected",
  "OTHER_HINT_PLEASE_WAIT": "Please wait...",
  "OTHER_HINT_FILL_GUIDE": "Fill guide window.",
  "OTHER_HINT_NO_IMG_CAPTURED": "No image captured.",
  "MITEK_ERROR_TOO_FAR": "Document too far.",
  "MITEK_ERROR_TOO_CLOSE": "Document too close.",
  "MITEK_ERROR_NOT_CENTERED": "Document not centered.",
  "MITEK_ERROR_MIN_PADDING": "Slowly move farther away.",
  "MITEK_ERROR_HORIZONTAL_FILL": "Slowly move closer.",
  "MITEK_ERROR_LOW_CONTRAST": "Center the document on a dark background.",
  "MITEK_ERROR_BUSY_BACKGROUND": "Center the document on a plain background.",
  "MITEK_ERROR_SKEW_ANGLE": "Reduce the angle.",
  "MITEK_ERROR_PERPENDICULAR_DOCUMENT": "Change the orientation of your document.",
  "DL_FRONT_FIRST_MESSAGE": "Please center the document on a dark background.",
  "DL_FRONT_FOUR_CORNER_MESSAGE": "Center the document on a dark background.",
  "PDF417_BARCODE_FIRST_MESSAGE": "Center the barcode.",
  "PDF417_BARCODE_FOUR_CORNER_MESSAGE": "Scanning for barcode.",
  "PASSPORT_FIRST_MESSAGE": "Center the photo page on a dark background.",
  "PASSPORT_FOUR_CORNER_MESSAGE": "Center the photo page on a dark background.",
  "SELFIE_FIRST_MESSAGE": "Place your face close to the oval and hold still.",
  "SELFIE_FOUR_CORNER_MESSAGE": "Face not found."
};
