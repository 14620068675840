<div>
  <h3>{{ 'CONSENT_AUTHORIZATION_HEADER' | translate }}</h3>
  <br />
  <div class="consent_txt">
    <div [innerHTML]="consentHTML">
    </div>
  </div>
  <img *ngIf="signatureValue != null && signatureValue != ''" [src]="signatureValue" alt="Signature" height="60" />
  <div class="row">
    <div class="col offset-md-8 col-md-6 col-sm-12 col-lg-3 errorMessage"
      *ngIf="errorMessage != '' && errorMessage != undefined">{{errorMessage}}</div>
    <button class="btn button btn-align m-t-15"  
      
      (click)="showDeclineESignaturePopup()">{{ 'BUTTON_DECLINE' | translate }}</button>   
    <button class="btn button btn-align m-t-15"
      (click)="esignature();">{{ 'COMMONS_NEXT'| translate}}</button>
  </div>
</div>


<!-- Signature Pad Modal - Starts -->
<div class="modal fade custom_modal" id="signature_Modal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header m-t-15">
        <button type="button" class="close" data-dismiss="modal" (click)="onSignCancel()"><i
            class="icon-cancel"></i></button>
        <h3  *ngIf="signatureActions" class="modal-title">{{ 'CONSENT_SIGN_TEXT' | translate }}</h3>
        <h3 *ngIf="confirmSignatureActions" class="modal-title" >{{ 'HEAD_E_SIGNATURE_CONFIRM' | translate }}</h3>
        <!--<h4 class="m-t-10">By signing, you understand that this would be a legal representation of your signature for your background verification with {{companyName}}.</h4>-->
      </div>
      <div class="modal-body" id="signature_modal_body">
        <!-- <form [formGroup]="form2"  >  -->
        <lib-signature-pad-extended #signaturePadExtendedContainer [padNote]="signaturePadNote" [confirmSignatureText]="confirmSignatureText"
          [displayLabels]="displayLabels" [useFontBasedSignature]="useFontBasedSignature" [signatureFont]="signatureFont" [signatureLabel]="signatureLabel" [signatureInitialValue] = "signatureInitialValue" (onDrawComplete)="onDrawComplete($event)"></lib-signature-pad-extended>
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4"  #signatureActionsBar [hidden]="!signatureActions">
            <button type="button" class="btn button width-100 mar-r-5"
              (click)="onSignCancel()">{{'COMMONS_CANCEL' | translate}} </button>

            <button type="button" class="btn button width-100 mar-r-5" (click)="onSignClear()"
              [disabled]="disableSignCleanButton">{{'SIGN_MODAL_BTN_3' | translate}} </button>

            <button type="button" class="btn button width-100 mar-r-5" (click)="onSign()"
              [disabled]="disableSignCleanButton">{{'SIGN_MODAL_BTN_1' | translate}} </button>
            
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4 confirm-sign-animate-right" [hidden]="!confirmSignatureActions">            
            <button type="button" class="btn button width-100 mar-r-5" (click)="onSignCancel()">{{'COMMONS_CANCEL' | translate}} </button>

            <button type="button" class="btn button width-100 mar-r-5" (click)="onConfirmSignGoBack('back')">{{'BUTTON_BACK' | translate}} </button>
  
            <button type="button" class="btn button width-100 mar-r-5" (click)="onConfirmSign()" >{{'BUTTON_CONFIRM' | translate}} </button>
          
          </div>
        </div>
       
        <!-- </form>  -->
      </div>
    </div>
  </div>
</div>
<!-- Signature Pad Modal - Ends -->




<!-- Decline ESignature  Pad Modal - Starts -->
<div class="modal fade custom_modal" id="declineEsignature_Modal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header m-t-15">
        <button type="button" class="close" data-dismiss="modal" (click)="closeDecline()"><i
            class="icon-cancel"></i></button>
        <h3 class="modal-title">{{ 'DECLINE_ESIGNATURE_SIGN_TEXT' | translate }}</h3>
        </div>
      <div class="modal-body" id="signature_modal_body">
        <div>
          {{declineESigConfirmText}}
       </div>
       <br />
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4">
              <button type="button" class="btn button width-100 mar-r-5" (click)="closeDecline()"  >{{'BUTTON_GO_BACK' | translate}}</button>
              <button type="button" class="btn button width-100 mar-r-5" (click)="confirmDeclineESignature()"  >{{'BUTTON_DECLINE' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Decline ESignature  Pad Modal - Ends -->

<!-- Please Wait Modal - starts -->
<div class="modal fade custom_modal" id="pleaseWaitModal" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <div class="modal-body text-center">
          <div class="modal-title m-t-10" [innerHTML]="'PLEASE_WAIT_FOR_A_MOMENT' | translate "> </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>
  