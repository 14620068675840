<div class="body_container container">
  <div class="anime-clean signup">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <h3 *ngIf="errorCode === 'CAMERA_PERMISSION'"  class="text-center m-t-15" [innerHTML]="oppsMessage">
             </h3>
        <h3 *ngIf="errorCode === 'VERIFIED_PASSED'" class="text-center m-t-15">
          {{'ERROR_MSG_VERIFIED_PASSED' | translate }}</h3>
        <h3 *ngIf="errorCode === 'BAD_REQUEST'" class="text-center m-t-15"   [innerHTML]="oppsMessage">
        </h3>
        <h3 *ngIf="errorCode === '404'" class="text-center m-t-15"   [innerHTML]="oppsMessage">
        </h3>
        <h3 *ngIf="errorCode === 'LINK_EXPIRED'" class="text-center m-t-15">
          {{'ERROR_MSG_LINK_EXPIRED' | translate }}</h3>
        <h3 *ngIf="errorCode === 'INVITE_FAILURE'" class="text-center m-t-15">
          {{'ERROR_MSG_INVITE_FAILURE' | translate }}</h3>
        <h3 *ngIf="errorCode === 'DESKTOP_TRUE' && errorMessage === 'ERROR_MSG_DESKTOP_TRUE'"
          class="text-center m-t-15" [innerHTML]="'ERROR_MSG_DESKTOP_TRUE' | translate "></h3>
        <h3 *ngIf="errorCode === 'DESKTOP_TRUE' && errorMessage !== 'ERROR_MSG_DESKTOP_TRUE'"
          class="text-center m-t-15" [innerHTML]="errorMessage"></h3>
        <h3 *ngIf="errorCode === 'OPT_OUT' && errorMessage === 'ERROR_MSG_OPT_OUT'" class="text-center m-t-15"
          [innerHTML]="'ERROR_MSG_OPT_OUT' | translate "></h3>
        <h3 *ngIf="errorCode === 'OPT_OUT' && errorMessage !== 'ERROR_MSG_OPT_OUT'" class="text-center m-t-15"
          [innerHTML]="errorMessage"></h3>
        <h3 *ngIf="errorCode === 'MESSAGE' && errorMessage != undefined && errorMessage != '' " class="text-center  m-t-15"  [innerHTML]="errorMessage"></h3>

      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
