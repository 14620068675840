<div class="div-sub" *ngIf="currentStep  === 'submission'">
  <div class="container-fluid">
    <div class="row">
      <div id="waiting" class="text-center">
        <div class="text-info" role="status">
          <img src="../../assets/img/loading.svg" width="130" height="130" />
        </div>
        <h6 class="text-center">{{'WAIT_MSG' | translate}}</h6>
      </div>
      <div id="retryMsg" class="text-center" style="display:none;">
        <h6 class="text-center">{{'RETRY_ATTEMPT_MSG' | translate:[uploadAttempt]}}</h6>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        {{'CONFIRMATION_ID_SUBMITTED' | translate}}
      </div>
    </div>
    <div class="row m-t-15">
      <div class=" col-md-12 col-xs-12 col-sm-12">
        {{'CONFIRMATION_SELFIE_SUBMITTED' | translate}}
      </div>
    </div>
    <div class="row m-t-15">
      <div class="col-md-12 col-xs-12 col-sm-12">
        {{'CONFIRMATION_LIVENESS_SELFIE_SUBMITTED' | translate}}
      </div>
    </div>
    <div class="row m-t-15">
      <div class="col-md-12 col-xs-12 col-sm-12">
        {{'DECISION_PROCESSING' | translate}}
      </div>
    </div>
  </div>
</div>

<!-- Below div is for showing final results -->
<div class="container div-result" *ngIf="currentStep  === 'result'">
  <!-- Below div is to show new UI messages -->
  <div class="row m-t-15" *ngIf="maskDecision !== 'YES'">
    <div class="col-md-6 col-sm-6">
      <!-- <h3 class="text-center m-t-15">{{'RESULTS'|translate:[result] }}</h3> -->
      <!-- Result - Pass/verified -->
      <h3 class="text-center m-t-15" *ngIf="result === 'Verified'">{{ 'RID_RESULT_1' | translate }}</h3>
      <!-- Result - Failed/Not verified -->
      <h3 class="text-center m-t-15" *ngIf="result === 'Not Verified'">{{ 'RID_RESULT_1' | translate }}</h3>
      <!-- Result - Unauthorized/Expired -->
      <h3 class="text-center m-t-15" *ngIf="result === 'Unauthorized' || result === 'Expired'">{{ 'RID_RESULT_2' | translate }}</h3>
      <!-- Result - Expired -->
      <h3 class="text-center m-t-15" *ngIf="result === 'Retake'">{{ 'RID_RESULT_3' | translate }}</h3>
      <!-- Result - Error -->
      <h3 class="text-center m-t-15" *ngIf="result === 'Error'">{{ 'RID_RESULT_4' | translate }}</h3>
    </div>
  </div>

  <!-- Below div is for "Please click on 'Next' to continue" message with Next enabled -->
  <div *ngIf='showNext && enableNext && !inProgress'>
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header-txt header_txt_white">
          <h4 class="m-b-20">{{ 'RID_RESULT_TITLE' | translate }}</h4>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf='(!showNext || !enableNext) && !inProgress'>
    <!-- Below div is for showing Error messages -->
    <div class="row m-t-15" *ngIf="isError && errorMessage != undefined">
      <div *ngIf="result === 'Retake'" class="text-center alert alert-danger">
        {{'RETAKE_MSG' | translate:[errorMessage] }}
      </div>
      <div *ngIf="result === 'Unauthorized'" class="text-center alert alert-danger">
        {{ 'UNAUTHORIZED_MSG' }}
      </div>
      <div *ngIf="result !== 'Retake' && result !== 'Unauthorized'" class="text-center alert alert-danger">
        {{ errorMessage }}
      </div>
    </div>

    <!-- Below div is for success response -->
    <div class="row m-t-15" *ngIf="sucessResponse">
      <div class="">
        {{message}}
      </div>
    </div>
  </div>

  <!-- <div class="div-failed" *ngIf="result !== 'Verified'">
    <div class="row m-t-15" *ngIf="selectedIDType==='gid' && !isIDDocumentVerified">
      <div>      
        <span *ngIf="!isIDDocumentVerified" class="icon-close rightIdConfirmIcons" alt="Not Verified"></span>
        {{'ID_DOC_VERIFIED' | translate}}
      </div>
    </div>
    <div class="row m-t-15" *ngIf="selectedIDType==='passport' && !isPassportVerified">
      <div>
        <span *ngIf="!isPassportVerified" class="icon-close rightIdConfirmIcons" alt="Not Verified"></span>
        {{'PASSPORT_VERIFIED' | translate}}
      </div>
    </div>
    <div class="row m-t-15"  *ngIf="!isSelfieVerified">
      <div>
        <span *ngIf="!isSelfieVerified" class="icon-close rightIdConfirmIcons" alt="Not Verified"></span>
        {{'SELFIE_VERIFIED' | translate}}
      </div>
    </div>
    <div class="row m-t-15"  *ngIf="!isLivenessSelfieVerified">
      <div>
        <span *ngIf="!isLivenessSelfieVerified" class="icon-close rightIdConfirmIcons" alt="Not Verified"></span>
        {{'LIVENESS_SELFIE_VERIFIED' | translate}}
      </div>
    </div>
  </div> -->

  <!-- Below div is for buttons -->
  <div class="row div-retake m-t-15">
    <div class="m-t-15" *ngIf="isRetake">
      <button class="btn button btn-align col offset-md-3 col-md-6 col-sm-12 col-lg-3 mb-2"
        (click)="retake();">{{'RETAKE' | translate}}</button>
    </div>
    <div class="m-t-15" *ngIf="showNext">
      <button class="btn button btn-align col offset-md-3 col-md-6 col-sm-12 col-lg-3 mb-2" (click)="next();"
        [disabled]="!enableNext">{{'CONFIRM_NEXT' | translate}}</button>
    </div>
  </div>

  <!-- Below div is for abort link -->
  <div *ngIf="(showAbortOptionLink && isRetake)" class="link">
      <a [routerLink]="[]" class="col offset-md-3 col-md-6 col-sm-12 col-lg-3"
        (click)="showAbortModal()">{{abortLinkLabel}}</a>
  </div>
</div>

<!-- Please Wait Modal - starts -->
<div class="modal fade custom_modal" id="pleaseWaitModal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="modal-body text-center">
        <div class="modal-title m-t-10" [innerHTML]="'PLEASE_WAIT_PAGE_IS_BEING_REDIRECTED' | translate "> </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<!-- Abort Modal - starts -->
<div class="modal fade custom_modal" id="abortmodal" data-backdrop="static" role="dialog" data-keyboard="false">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeAbortModal()" data-dismiss="modal">&times;</button>
        </div>
        <div class="transparentbg modal-body">
          <h4 class="modal-title">{{ abortPopupMsg }}</h4>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="">
              <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
                (click)="processAbort()">{{'COMMONS_CONTINUE' | translate }}</button>
            </div>
            <div class="">
              <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
                (click)="closeAbortModal()">{{'COMMONS_GOBACK' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>