import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicantInfoService } from './applicant-info.service';
import { SharedService } from '../shared/Services/shared.service';
import { LocalStorageService } from '@fadv/web-common';
import { TrackEventService } from '../shared/Services/trackEvent.service';

declare var $: any;
@Component({
  selector: 'app-applicant-info',
  templateUrl: './applicant-info.component.html',
  styleUrls: ['./applicant-info.component.css']
})
export class ApplicantInfoComponent implements OnInit {
  public registerForm: UntypedFormGroup;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, private shareService: SharedService,
    private applicantInfoService: ApplicantInfoService, private localStorage: LocalStorageService,
    public trackEventService: TrackEventService) { }

  public formDisplay = false;
  public isInvalidEmailID = false;
  public isEmailError = false;
  public enableNextButton = false;
  public emailErrorMessage = '';
  public isValidPhone = true;
  public isNumberList  = true;
  public codeErrorMsg = false;
  public countrycodelist: any;

  ngOnInit() {
    this.createFormControls();
  }

  public createFormControls() {
    let fname = this.localStorage.getItem("first_name");
    let mname = this.localStorage.getItem("middle_name");
    let lname = this.localStorage.getItem("last_name");
    let email = this.localStorage.getItem("email");
    let mobile = this.localStorage.getItem("mobile");
    let countryCode = '';
    let phone = '';

    if (!fname) {
      fname = '';
    }
    if (!mname) {
      mname = '';
    }
    if (!lname) {
      lname = '';
    }
    if (!email) {
      email = '';
    }
    if (mobile) {
      var array = mobile.split(" ");
      if (array.length == 1) {
        phone = array[0];
      } else {
        countryCode = array[0];
        phone = array[1];
      }
    }
    //Modified when phone number is not set from kiosk
    if (phone === 'null') {
      phone = '';
    }
    if (countryCode === 'null') {
      countryCode = '';
    }

    this.registerForm = this.formBuilder.group({
      first_name: [fname, Validators.required],
      middle_name: [mname],
      last_name: [lname, Validators.required],
      email: [email, Validators.required],
      countryCode: [countryCode, Validators.required],
      phone: [phone, Validators.required],
    });

    if (fname !== '' && lname !== '' && email !== '' && countryCode !== '' && phone !== '') {
      this.enableNextButton = true;
    }

    this.formDisplay = true;
  }

  submitData(obj) {
    $('#pleaseWaitModal').modal('show');
    let inviteObj = {};
    let inviteApplicant = {};
    let inviteKey = this.localStorage.getItem("invite_key");
    inviteObj['key'] =  inviteKey;
    inviteApplicant['first_name'] = obj['first_name'];
    inviteApplicant['middle_initial'] = obj['middle_name'];
    inviteApplicant['last_name'] = obj['last_name'];
    inviteApplicant['email'] = obj['email'];
    let countryCode = obj['countryCode'];
    let phone = obj['phone'];
    inviteApplicant['mobile'] = countryCode+' '+phone;
    inviteObj['applicant'] = inviteApplicant;

    let trackObj = {
      stage_name: 'applicant info',
      first_name: obj['first_name'],
      middle_initial: obj['middle_name'],
      last_name : obj['last_name'],
      email: obj['email'],
      countryCode: countryCode,
      phone: phone
    };

    if (inviteKey !== undefined && inviteKey !== '') {
      this.shareService.updateInvite(inviteObj).subscribe(response => {
        trackObj['action_value'] = 'next clicked';
        this.trackEventService.updateTrackEvent('info saved', trackObj, true, true);
        this.hidePleaseWait();
        this.router.navigate(['/app-id-select'], { queryParams: { invite_id:  this.localStorage.getItem("invite_id")}, queryParamsHandling: 'merge' });
      },
      error => {
        trackObj['action_value'] = 'next clicked';
        this.trackEventService.updateTrackEvent('info save error', trackObj, true, true);
        this.hidePleaseWait();
      });
    } else {
      this.hidePleaseWait();
      this.router.navigate(['/app-id-select'], { queryParams: { invite_id: this.localStorage.getItem("invite_id")}, queryParamsHandling: 'merge' });
    }
  }

  hidePleaseWait(){
    $('#pleaseWaitModal').modal('hide');
    $('.modal-backdrop').remove();
  }

  validateEmail(value: string) {
    const inputEmail = value;
    const emailRegex = new RegExp('^[A-Za-z0-9!@#$%&\'*+/=^_`()~.-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$');

    if (inputEmail === '') {
        this.isEmailError = true;
        this.isInvalidEmailID = true;
        this.emailErrorMessage = 'Please enter email.';
        this.enableNextButton = false;
    } else if (!emailRegex.test(inputEmail)) {
        this.isEmailError = true;
        this.isInvalidEmailID = true;
        this.emailErrorMessage = 'Please enter valid email.';
        this.enableNextButton = false;
    } else {
        this.isEmailError = false;
        this.isInvalidEmailID = false;
        this.emailErrorMessage = '';
        this.enableNextButton = true;
    }
  }

  validateEmailOnKeyUp(value: string) {
    const inputEmail = value;
    // tslint:disable-next-line:max-line-length
    const emailRegex = new RegExp('^[A-Za-z0-9!@#$%&\'*+/=^_`()~.-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$');

    if (inputEmail === '') {
        this.isInvalidEmailID = true;
        this.enableNextButton = false;
    } else if (!emailRegex.test(inputEmail)) {
        this.isInvalidEmailID = true;
        this.enableNextButton = false;
    } else {
        this.isInvalidEmailID = false;
        this.enableNextButton = true;
    }
  }

  enterCityInput(data) {
    this.registerForm.controls.countryCode.setValue(data.code);
    this.isNumberList = true;
    this.codeErrorMsg = false;
    this.enableNextButton = true;
  }

  public keyupCountryCode(data, event) {
    if (!!data && data.length > 0) {
        const body = data;
        this.applicantInfoService.countryCodeLookUpService(body).subscribe(response => {
            const serverzipdata = response;
            this.countrycodelist = serverzipdata['isd-code-list'];
            if (this.countrycodelist.length > 0) {
                this.codeErrorMsg = false;
                this.enableNextButton = true;
            } else {
                this.codeErrorMsg = true;
                this.enableNextButton = false;
            }
            this.isNumberList = false;
        },
        error => {
        });

    } else if (!!data && data.length === 0) {
            this.isNumberList  = true;
            this.enableNextButton = false;
    }
  }

  focusOutFunction(data){
    if (data !== '') {
        const regex = /^[0-9]*$/;
        if (regex.test(data) === true) {
          const str1 = '+';
          data = str1.concat(data);
          this.registerForm.controls.countryCode.setValue(data);
          this.enableNextButton = true;
       // this.codeErrorMsg = false;
        } else {
          const regex1 = /^([+]\d+(\.\d+)?)/;
          if (regex1.test(data) === false) {
            this.registerForm.controls.countryCode.setValue('');
            this.codeErrorMsg = true;
            this.enableNextButton = false;
          } else {
            this.codeErrorMsg = false;
            this.enableNextButton = true;
          }
        }
        this.isNumberList = true;
    }
  }
  public keyupPhoneNumber() {
    this.isNumberList = true;
  }

  public validatePhone(input) {
    const phoneRegex = new RegExp('^(?=.*[0-9])[- ()0-9]+$');
    if (input === '' || input === undefined) {
        this.isValidPhone = false;
    } else if (input !== undefined && !phoneRegex.test(input)) {
        this.isValidPhone = false ;
    } else {
        this.isValidPhone = true;
    }
  }

}
