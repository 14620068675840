import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@fadv/web-common';
import { ApplicantionConstants, ApplicantStatus } from '../identity-config';
import { SharedService } from '../shared/Services/shared.service';
import { TrackEventService } from '../shared/Services/trackEvent.service';
import { TranslateService } from '../framework/i18n/translate.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {tutorialVideoUrls} from './landing.config';

declare var jQuery: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  name: string = '';
  showOptOut: string = '';
  custOptOutMsg: string = '';
  optOutPopupMsg: string;

  landingWelcomeText: string;
  landingWelcomeToText: string;
  landingText: string;
  landingChooseIDTypeText: string;
  landingIDText: string;
  landingSelfieText: string;
  landingThatsItText: string;
  landingButtonName: string;
  optOutLinkLabel: string;

  @Input() emitData: boolean;
  @Output() onOptOutConfirmNext: EventEmitter<any> = new EventEmitter();
  videoUrl!: SafeResourceUrl;
  videoId: string = tutorialVideoUrls.default;

  constructor(private router: Router, private localStorage: LocalStorageService,
    private sharedService: SharedService, public trackEventService: TrackEventService,
    private _ts: TranslateService, private _sanitizer: DomSanitizer) {
    if(!!_ts.currentLang && !!tutorialVideoUrls[_ts.currentLang]) {
      this.videoId = tutorialVideoUrls[_ts.currentLang];
    }
    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.videoId);
    }

  ngOnInit() {
    jQuery('#langGlobe').css('display', 'none');
    let fname = this.localStorage.getItem("first_name");
    let lname = this.localStorage.getItem("last_name");
    //Do not display last name in landing page. Display only the first name.
    if (fname) {
      this.name = fname;
    }
    //Set opt out preference
    this.showOptOut = this.localStorage.getItem('show_opt_out');
    //Get the opt out message
    this.custOptOutMsg = this.localStorage.getItem('opt_out_message');
    this.optOutPopupMsg = this.localStorage.getItem('opt_out_popup_msg');
    this.optOutLinkLabel = this.localStorage.getItem('opt_out_link_label');

    this.trackEventService.updateTrackEvent('page loaded successfully', { stage_name: 'Rightid', action_value: 'Rightid landing' }, true, true);
    this.landingWelcomeText = this.localStorage.getItem('landing_welcome_text');
    this.landingWelcomeToText = this._ts.instant(this.localStorage.getItem('landing_welcome_to_text'), this.name);
    this.landingText = this.localStorage.getItem('landing_text');
    this.landingChooseIDTypeText = this.localStorage.getItem('landing_choose_id_type_text');
    this.landingIDText = this.localStorage.getItem('landing_id_text');
    this.landingSelfieText = this.localStorage.getItem('landing_selfie_text');
    this.landingThatsItText = this.localStorage.getItem('landing_thats_it_text');
    this.landingButtonName = this.localStorage.getItem('landing_button_name');

    if (this.landingWelcomeText === '' || this.landingWelcomeText === undefined) {
      this.landingWelcomeText = this._ts.instant('WELCOME');
    }
    if (this.landingWelcomeToText === '' || this.landingWelcomeToText === undefined) {
      this.landingWelcomeToText = this._ts.instant('WELCOME_TO', this.name);
    }
    if (this.landingText === '' || this.landingText === undefined) {
      this.landingText = this._ts.instant('LANDING_MSG');
    }
    if (this.landingChooseIDTypeText === '' || this.landingChooseIDTypeText === undefined) {
      this.landingChooseIDTypeText = this._ts.instant('CHOOSE_ID_TYPE_LANDING_MSG');
    }
    if (this.landingIDText === '' || this.landingIDText === undefined) {
      this.landingIDText = this._ts.instant('ID_LANDING_MSG');
    }
    if (this.landingSelfieText === '' || this.landingSelfieText === undefined) {
      this.landingSelfieText = this._ts.instant('SELFIE_LANDING_MSG');
    }
    if (this.landingThatsItText === '' || this.landingThatsItText === undefined) {
      this.landingThatsItText = this._ts.instant('THATS_IT');
    }
    if (this.landingButtonName === '' || this.landingButtonName === undefined) {
      this.landingButtonName = this._ts.instant('LET_STARTED');
    }
    if (this.optOutLinkLabel === '' || this.optOutLinkLabel === undefined) {
      this.optOutLinkLabel = this._ts.instant('COMMONS_SKIP');
    }
    if (this.optOutPopupMsg === '' || this.optOutPopupMsg === undefined) {
      this.optOutPopupMsg = this._ts.instant('OPTOUT_MODAL_HEADER_1');
    }
  }

  next() {
    jQuery('#langGlobe').css('display', 'none');
    let applicant_type = this.localStorage.getItem("applicant_type");
    if (applicant_type === ApplicantionConstants.APPLICANT.toString() || applicant_type === '' || applicant_type === undefined) {
      this.router.navigate(['/app-id-select'], { queryParams: { invite_id: this.localStorage.getItem("invite_id")}, queryParamsHandling: 'merge' });
    } else {
      //co-applicant
      this.router.navigate(['/app-applicant-info'], { queryParams: { invite_id: this.localStorage.getItem('invite_id')}, queryParamsHandling: 'merge' });
    }
  }

  optOut() {
    jQuery('#optout-modal').modal('show');
  }

  processOptOut() {
    jQuery('#optout-modal').modal('hide');
    jQuery('#pleaseWaitModal').modal('show');
    let redirectUrl = this.localStorage.getItem("redirect_url");
    let inviteKey = this.localStorage.getItem("invite_key");
    let inviteId = this.localStorage.getItem("invite_id");

    let data = {
      'status' : ApplicantStatus.OPTED_OUT
    }
    if (inviteKey !== undefined && inviteKey !== '') {
      this.sharedService.updateInviteStatus(data, inviteId).subscribe(response => {
        this.trackEventService.updateTrackEvent('applicant data updated', { stage_name: 'landing page', action_value: 'opt out clicked' }, true, true);
        if (this.emitData) {
          this.onOptOutConfirmNext.next();
          jQuery('#pleaseWaitModal').modal('hide');
        } else {
          if (redirectUrl != undefined && redirectUrl != '') {
            this.trackEventService.updateTrackEvent('redirect to ' + redirectUrl, { stage_name: 'landing page', redirectUrl: redirectUrl, action_value: 'opt out clicked' }, true, true);
            let url = new URL(redirectUrl);
            var search_params = url.searchParams;
            search_params.set('id', inviteId);
            search_params.set('action', ApplicantStatus.OPTED_OUT);
            let new_url = url.toString();
            window.location.href = new_url;
          } else {
            this.trackEventService.updateTrackEvent('redirect to error page', { stage_name: 'landing page', action_value: 'opt out clicked' }, true, true);
            this.sharedService.setErrorCodeAndMsg('OPT_OUT', this.custOptOutMsg);
            this.router.navigate(['/app-error']);
            jQuery('#pleaseWaitModal').modal('hide');
            return;
          }
        }
      },
        error => {
          this.trackEventService.updateTrackEvent('update applicant data failed', { stage_name: 'landing page', action_value: 'opt out clicked' }, true, false);
          jQuery('#pleaseWaitModal').modal('hide');
          if (this.emitData) {
            this.router.navigate(['/app-error']);
          }
        });
    }
  }

  closeOptOutModal() {
    jQuery('#logout_confirmation_modal').modal('hide');
    jQuery('.modal-backdrop').remove();
  }

  closeVideoModal() {
    // Resetting the video url so that the video oesnt continue playing even after modal is closed
    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.videoId);
    jQuery('#showVideoModal').modal('hide');
    jQuery('.modal-backdrop').remove();
  }

  openVideoModal() {
    jQuery('#showVideoModal').modal('show');
  }
}
