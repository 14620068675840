<div>
  <div class="loader-wrap" id="main-spinner" *ngIf="loading">
    <div class="loader"></div>
  </div>
  <div *ngIf="!loading">
    <br />
    <div id="misnapError" class="text-center alert alert-danger" *ngIf="(misnapError != undefined && misnapError != '')"> {{misnapError}}</div>
    <div class="almost-bottom row" *ngIf="(misnapError != undefined && misnapError != '') || captureMode == 'MANUAL_CAPTURE'">
      <button class="btn button grow btn-align"*ngIf="config.captureMode == 'Auto'" (click)="loadCamera();">{{'RETRY_AUTO' | translate}}</button>
      <button class="btn button grow btn-align" (click)="initializeAndRunManualMode();">{{'RETRY_MANUAL' | translate}}</button>
    </div>
  </div>
  <div class="row m-t-15">
    <img width="100%" src="{{resultImage}}" />
  </div>
</div>