export enum ApplicantionConstants {
    APPLICANT = 'Applicant',
    ACCEPTED = 'ACCEPTED'
};
export enum Result {
    Verified = 'Verified',
    Error = 'Error',
    Retake = 'Retake',
    NotVerified = 'Not Verified',
    Unauthorized = 'Unauthorized',
    Expired = 'Expired'
};

export enum ApplicantStatus {
    OPTED_OUT = 'OPTED_OUT',
    NO_VALID_DOCUMENTS = 'NO_VALID_DOCUMENTS',
    RETAKE_ATTEMPT_EXCEEDED = 'RETAKE_ATTEMPT_EXCEEDED',
    ALTERNATE_WORKFLOW = 'ALTERNATE_WORKFLOW',
    COMPLETED = 'COMPLETED',
    NO_MOBILE_ACCESS = 'NO_MOBILE_ACCESS',
    DECLINED = 'DECLINED'
};

export enum VENDOR {
    AUTHENTIC_ID = 'AUTHENTIC_ID',
    MITEK = 'MITEK'
};
  
export class IdentityConfig {
    static API_ENDPOINT(): string {
        //  return 'https://id-qa.fadv.com';
        return '';
    }

    static getDefaultLanguage(): string {
        return 'en';
    }

    static getDiplayLanguageList(): Object {
        let diplayLanguageList = {
            'en': 'English',
            'es': 'Español',
        };
        return diplayLanguageList;
    }

    static errorCodeMapping(errorCode): any {
        let errorMessage: string;
    
        switch(errorCode) {  
            case 10: 
                errorMessage = 'RIGHTID_ERROR_10';
                break;
            case 12: 
                errorMessage = 'RIGHTID_ERROR_12';
                break;
            case 14: 
                errorMessage = 'RIGHTID_ERROR_14';
                break;
            case 16: 
                errorMessage = 'RIGHTID_ERROR_16';
                break;
            case 20: 
                errorMessage = 'RIGHTID_ERROR_20';
                break;
            case 22: 
                errorMessage = 'RIGHTID_ERROR_22';
                break;
            case 24: 
                errorMessage = 'RIGHTID_ERROR_24';
                break;
            case 50: 
                errorMessage = 'RIGHTID_ERROR_50';
                break;
            case 52:
                errorMessage = 'RIGHTID_ERROR_52';
                break;
            default:
                errorMessage = '';
                break;
        }
    
        return errorMessage;
    }
}