<!--
Screen to display when identity-pre-check happens
-->
<!-- Please Wait Modal - starts -->
<div class="modal fade custom_modal" id="pleaseWaitModal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="modal-body text-center">
        <div class="modal-title m-t-10" [innerHTML]="'PLEASE_WAIT_FOR_A_MOMENT' | translate "> </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
