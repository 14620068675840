<div class="padding-15 " *ngIf="(isFormInit)">
  <div *ngIf="currentSlide  === 'instruction_frontId_0'">
    <div *ngIf="selectedIDType==='gid' || selectedIDType==='non_us_gid'">
      <app-instructions (onInstructionNext)="onInstructionNext($event)">
        <div class="row">
          <h2>{{'ID_FRONT_INSTR_MSG_1' | translate}}</h2>
        </div>
        <div class="row m-t-15">
          {{'ID_INSTR_MSG_2' | translate}}
        </div>
        <div class="row text-center m-t-15">
          <img alt="Government Id" width="80%" style="max-width: 300px !important;" src="assets/img/dl_front_help.gif">
        </div>
      </app-instructions>
    </div>
    <div *ngIf="selectedIDType==='passport'">
      <app-instructions (onInstructionNext)="onInstructionNext($event)">
        <div class="row">
          <h3>{{'PASSPORT_INSTR_MSG_1' | translate}}</h3>
        </div>
        <div class="row m-t-15">
          {{'PASSPORT_INSTR_MSG_2' | translate}}
        </div>
        <div class="row text-center m-t-15">
          <img alt="Passport Id" width="80%" style="max-width: 300px !important;" src="assets/img/pp-help.gif">
        </div>
      </app-instructions>
    </div>
  </div>
  <div *ngIf="currentSlide  === 'id_frontId_0' && webSdkVendor === VENDOR_MITEK">
    <lib-mitek-camera (onCameraTimeout)="onCameraTimeout($event)" (onCameraNext)="onCameraNext($event)" [resultImage]="resultImage" [config]="config">
      <div>

      </div>
    </lib-mitek-camera>
  </div>

  <div *ngIf="currentSlide  === 'confirm_frontId_0'">
    <app-confirm (onConfirmNext)="onConfirmNext($event)" (onConfirmRetake)="onConfirmRetake($event)"
      [resultImage]="resultImage">
      <div>

      </div>
    </app-confirm>
  </div>


  <div *ngIf="currentSlide === 'showBarcodeDecisionPage' && selectedIDType==='non_us_gid'">
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div class="row padding16">
      <button class="btn button btn-select" name="do_not_have_barcode"
        (click)="selectedType('do_not_have_barcode')">{{'ID_DO_NOT_HAVE_BARCODE1' | translate}} <br/> 
        {{'ID_DO_NOT_HAVE_BARCODE2' | translate}} </button>
    </div>
    <div class="row padding16">
      <button class="btn button btn-select" name="do_have_barcode"
        (click)="selectedType('do_have_barcode')">{{'ID_DO_HAVE_BARCODE1' | translate}} <br/>
        {{'ID_DO_HAVE_BARCODE2' | translate}} </button>
    </div>
  </div>



  <div *ngIf="currentSlide === 'instruction_backId_0'">
    <app-instructions (onInstructionNext)="onInstructionNext($event)">
      <div class="row">
        <h3>{{'ID_BACK_INSTR_MSG_1' | translate}}</h3>
      </div>
      <div class="row m-t-15">
        {{'ID_INSTR_MSG_2' | translate}}
      </div>
      <div class="row text-center m-t-15">
        <img alt="Government Id" width="80%" style="max-width: 300px !important;" src="assets/img/dl_back_help.gif">
      </div>
    </app-instructions>
  </div>
  <div *ngIf="currentSlide === 'id_backId_0' && webSdkVendor === VENDOR_MITEK">
    <lib-mitek-camera (onCameraTimeout)="onCameraTimeout($event)" (onCameraNext)="onCameraNext($event)" [resultImage]="resultImage" [config]="config">
      <div>

      </div>
    </lib-mitek-camera>
  </div>
  <div *ngIf="currentSlide === 'confirm_backId_0'">
    <app-confirm (onConfirmNext)="onConfirmNext($event)" (onConfirmRetake)="onConfirmRetake($event)"
      [resultImage]="resultImage">
      <div>

      </div>
    </app-confirm>
  </div>

  <div class="modal fade custom_modal" id="errorModal" role="dialog" data-backdrop="static" data-keyboard="false"
    style="z-index: 16777100;">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="transparentbg modal-body">
          <div style="text-align: center;">
            <h4 class="modal-title">
              {{'ERROR_TITLE' | translate}}
            </h4>
            <h6 class="modal-title">
              {{errorMessage}}
            </h6>            
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary col-md-offset-3 col-md-6 col-sm-12 col-lg-3" type="button" data-dismiss="modal" id="errorModelOKButton">{{ 'BUTTON_OK' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
