export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
  "GOVERNMENT_ID_DOC": "US Driver's License/State ID",
  "GOVERNMENT_ID_DOC_WITHOUT_BARCODE": "Non US Driver's License/State ID",
  "PASSPORT": "Passport",
  "NEXT": "Next",
  "WELCOME": "Welcome",
  "WELCOME_TO": "Welcome %0",
  "LANDING_MSG": "We're going to take you through the following steps to validate your identity. It should only take a few minutes.",
  "CHOOSE_ID_TYPE_LANDING_MSG": "Choose the type of ID Document you'd like to use.",
  "ID_LANDING_MSG": "Capture front and back photos of your ID.",
  "SELFIE_LANDING_MSG": "We'll take two selfie photos for verification.",
  "THATS_IT": "That's It!",
  "LET_STARTED": "LET'S GET STARTED",

  "APPLICANT_INFO_HEADER_RIGHTID" : "Please enter your name exactly as it appears on your ID document, including middle name or initial.",
  "FIRST_NAME":"First Name",
  "MIDDLE_NAME":"Middle Name",
  "LAST_NAME": "LAST / SURNAME",
  "EMAIL_ID":"Email ID",
  "COUNTRY_CODE":"Country Code",
  "PHONE_NUMBER":"Phone Number",
  "ID_FRONT_INSTR_MSG_1": "How to take a photo of your Driver's License/State ID.",
  "ID_INSTR_MSG_2": "Please position your Driver's License/State ID to ensure readability and no glare.",
  "PASSPORT_INSTR_MSG_1": "How to take a photo of your passport",
  "PASSPORT_INSTR_MSG_2": "Please position your passport in a L shape to ensure readability and no glare.",
  "ID_BACK_INSTR_MSG_1": "How to take a photo of the back of your Driver's License/State ID.",
  "CONFIRM_STATIC_CONTENT": "Confirm static content",
  "CAMERA_STATIC_CONTENT": "Camera static content",
  "CONFIRM_PICTURE": "Confirm Picture",
  "RETAKE": "RETAKE",
  "CONFIRM_NEXT": "NEXT",
  "ERROR_TITLE": "Error!",
  "GOT_IT": "GOT IT",

  "SELFIE_INSTR_MSG_1": "We'll be taking two selfie photos",
  "SELFIE_INSTR_MSG_2": "Please continue to take two photos of your face in varying distances",
  "SELFIE_SINGLE_INSTR_MSG_1": "We'll be taking a selfie photo",
  "SELFIE_SINGLE_INSTR_MSG_2": "Please continue to take one photo of your face. This image may be used as a profile photo. Please consider appropriate clothing and background.",

  "CONFIRMATION_ID_SUBMITTED":"ID Submitted",
  "CONFIRMATION_SELFIE_SUBMITTED":"Selfie Submitted",
  "CONFIRMATION_LIVENESS_SELFIE_SUBMITTED":"Liveness Selfie Submitted",
  "WAIT_MSG":"Processing, please wait...",
  "DECISION_PROCESSING":"Decision Processing",
  "ERROR_NOTIFY_MSG":"Looks like an issue?",
  "SUCCESS_MSG":"Successfully Verified",
  "RESULTS": "Result: %0",
  "RETRY_ATTEMPT_MSG": "Retry Attempt: %0",
  "ID_DOC_VERIFIED": "ID Document Verified",
  "PASSPORT_VERIFIED": "Passport Verified",
  "SELFIE_VERIFIED": "Selfie Verified",
  "LIVENESS_SELFIE_VERIFIED": "Liveness Selfie Verified",
  "UNAUTHORIZED_MSG": "Given ID document is not supported, Please use alternate ID document",

  "RESULT_THANK_YOU": "Thank You! Looks like we've got everything we need right now.",
  "WE_WILL_GET_BACK": "One of our representatives will be in touch with you soon.",

  "ERROR_MSG_BAD_REQUEST":"Oops! Please check the URL. It seems the URL is invalid or service is temporary unavailable.",
  "ERROR_MSG_BAD_REQUEST_WITH_LINK":"Oops! Please check the URL. It seems the URL is invalid or service is temporary unavailable. <a href='%0'>Click here to relaunch</a>.",
  "ERROR_MSG_LINK_EXPIRED":"This link is no longer active.",
  "ERROR_MSG_NOT_FOUND":"This link is not available.",
  "ERROR_MSG_NOT_FOUND_WITH_LINK":"This link is not available. <a href='%0'>Click here to relaunch</a>.",
  "ERROR_MSG_INVITE_FAILURE":"Service is currently unavailable, please try again later.",
  "ERROR_MSG_VERIFIED_PASSED":"Thank you, you have already been verified.",
  "CONSENT_AUTHORIZATION_HEADER" : "Authorization",
  "COMMONS_NEXT" : "NEXT",
  "CONSENT_AUTHORIZATION_CONTENT" : "<p>The individual understands that, for security, identity verification, and fraud-prevention purposes, %0 (“the Company”) sometimes employs biometric technology to verify identity, to prevent fraud, and to control and limit access to the Company’s services and property.  In furtherance of these security, identity verification, and fraud-prevention purposes, the Company may collect biometric identifiers on individuals.  Under some applicable laws, biometric identifiers include a retina or iris scan, fingerprint, voiceprint, or record of hand or face geometry.</p><p>The biometric identifiers will be used exclusively for security, identity verification, and fraud prevention in connection with your access to the Company’s services or property.  The Company will not sell or lease your biometric identifiers unless required by law.</p><p>Please contact %1 if you have any questions.</p><p class=\"secondli\">I acknowledge that I have read and understand this notice regarding the collection and use of biometric identifiers.  I consent, as a condition of receiving or being considered to receive the Company’s services or access to its property, to providing biometric identifiers for the limited purpose of security, identity verification, and fraud prevention.</p>",
  "PLEASE_WAIT_PAGE_IS_BEING_REDIRECTED" : "Thank you! We are processing your request, please wait…",
  "CHOOSE_DOCUMENT_TYPE" :  "Choose the type of ID Document you'd like to use:",
  "ERROR_MSG_DESKTOP_TRUE" : "Sorry, this capability isn't supported using a laptop or desktop browser. You'll need to do this on a mobile device, like a smart phone, using the same link.",
  "OPTOUT_MODAL_HEADER_1" : "Valid ID will be required later in the process.",
  "ABORT_MODAL_HEADER_1" : "Valid ID will be required later in the process.",
  "COMMONS_OPTOUT" : "OPT-OUT",
  "COMMONS_GOBACK" : "GO BACK",
  "ERROR_MSG_OPT_OUT" : "You have opted out. Thank You.",
  "DONT_CONTINUE" : "I don't want to continue",
  "CONSENT_SIGN_TEXT" : "Authorization Signature",
  "CONSENT_SIGN_TEXT_1" : " * Use your mouse or touch.",
  "SIGN_MODAL_BOTTOM_1" : "%0",
  "SIGN_MODAL_BTN_3" : "CLEAR",
  "SIGN_MODAL_BTN_1" : "SIGN",
  "COMMONS_CANCEL" : "CANCEL",
  "PLEASE_WAIT_FOR_A_MOMENT" : "Please wait for a moment.",
  "BUTTON_OK": "OK",
  "CAMERA_PERMISSION_BLOCKED": "The Camera is blocked, please open device app settings and unblock the Camera. <a href='%0'>Click here to relaunch</a>.",
  "RETAKE_LIMIT_EXCEEDED": "Retake limit exceeded.",
  "BUTTON_DECLINE" : "DECLINE",
  "DECLINE_ESIGNATURE_SIGN_TEXT" : "Decline e-Signature",
  "DECLINE_ESIGNATURE_CONFIRM_TEXT": "Your signature is required to complete the ID process. Are you sure you want to decline?",
  "DECLINE_ESIGNATURE_TEXT" : "Contact the Company if you are still interested in continuing.",
  "BUTTON_GO_BACK": "GO BACK",
  "ID_SELECT_ABORT_OPTION": "Other Options",
  "RESULT_CONFIRM_ABORT_OPTION": "Other Options",
  "COMMONS_ABORT" : "ABORT",
  "COMMONS_CONTINUE": "CONTINUE",
  "ID_DO_HAVE_BARCODE1" : "I have a barcode on the",
  "ID_DO_HAVE_BARCODE2" : "back of my ID",
  "ID_DO_NOT_HAVE_BARCODE1" : "I don't have anything on",
  "ID_DO_NOT_HAVE_BARCODE2" : "the back of my ID",
  "GO_BACK": "GO BACK",
  "BUTTON_CONFIRM": "CONFIRM",
  "BUTTON_BACK": "BACK",
  "HEAD_E_SIGNATURE_CONFIRM": "E-Signature Confirmation",
  "E_SIGNATURE_PAD_NOTE": "* Use your mouse or touch.",
  "E_SIGNATURE_PAD_CONFIRM_TEXT" : "I confirm and accept this as my signature.",
  "FONT_BASED_SIGNATURE": "Font/Text Based Signature",
  "COMMONS_SKIP": "SKIP",
  "RID_VIDEO_LABEL_1": "Click Here",
  "RID_VIDEO_LABEL_2": " for a video tutorial",
  "RID_RESULT_1": "Thank You",
  "RID_RESULT_2": "Unable to verify ID",
  "RID_RESULT_3": "Recapture Required",
  "RID_RESULT_4": "Error",
  "RIGHTID_ERROR_10": "The quality of the image captured is not excellent, and please re-scan the IDs for better results",
  "RIGHTID_ERROR_12": "The quality of the image captured is not excellent, and please re-scan the IDs for better results",
  "RIGHTID_ERROR_14": "The quality of the image captured is not excellent, and please re-scan the front side of the ID for better results",
  "RIGHTID_ERROR_16": "The quality of the image captured is not excellent, and please re-scan the back side of the ID for better results",
  "RIGHTID_ERROR_20": "The quality of the image captured is not excellent, and please recapture the selfie again for better results",
  "RIGHTID_ERROR_22": "The quality of the image captured is not excellent, and please recapture the front side of the ID and a selfie for better results",
  "RIGHTID_ERROR_24": "The quality of the image captured is not excellent, and please recapture the back side of the ID and a selfie for better results",
  "RIGHTID_ERROR_50": "We do not support the ID provided by you. Would you please use a different ID listed in the user instructions and try again?",
  "RIGHTID_ERROR_52": "We noticed the ID provided by you has expired. Would you please use a different ID listed in the user instructions and try again?",
  "HAVING_TROUBLE": "Having trouble?",
  "TRY_ALTERNATE_METHODS": "to try alternate methods",
  "COMMONS_TRYALT": "Try alternate methods",
  "TRY_ALTERNATE_CLICK_HERE": "Tap here",
  "REVIEW_SELFIE": "Review Picture",
  "DOCUMENT_FRONT_NOT_PROVIDED" : "Document Front not provided.",
  "DOCUMENT_BACK_NOT_PROVIDED" : "Document Back not provided.",
  "SELFIE_NEAR_NOT_PROVIDED" : "Selfie near not provided.",
  "SELFIE_LIVENESS_NOT_PROVIDED" : "Selfie liveness not provided."

};
