import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from '@fadv/web-common';
import { IdentityConfig } from '../../identity-config';

declare var window: any;

var PING_DELAY = 1000;
@Injectable({
  providedIn: 'root'
})
export class TrackEventService {
  payloadForAudits: any;
  
  constructor(private _authHttp: HttpClient, private localStorage: LocalStorageService,) {
  }

  updateTrackEvent(name, metaData, isBehavioralMetrics: boolean, isOperationalMetrics: boolean) {
    // Code modified to send generic stage name + event value as event name because
    // unique event names count was going above 999
    // Ex: If event name was 'United States is the selected country', then for every country
    // new event name is generated. Same holds true for Address, Institution, Address Details, State etc
    // New format is applicant info : next clicked
    let eventName, payload;

    eventName = { stage_name : metaData.stage_name, action_value: metaData.action_value};
    payload = { action: name };

    // isBehavioralMetrics (For Matomo), isOperationalMetrics (For Log Audit)
    if (isOperationalMetrics) {
      this.sendEventsToAudits(name, eventName).subscribe(response => {
        console.log("Events sent to audits successfuly.....");
      }, error => {
        console.log("ERROR : Failed to send events to audits");
      });
    }

    if (isBehavioralMetrics)
      window.track_event(name, metaData);
  }
  
  getHeaderOptions(): any {
    const authHeaders = new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
    });

    let test = this.localStorage.getItem('access_token');

    const OPTIONS = { headers: authHeaders };

    return OPTIONS;
}
  
  sendEventsToAudits(name, body): Observable<any> {
    let headerOptions = this.getHeaderOptions();
    let auditURL = environment.apiUrl + '/api/v1/audit/log-audit';
    
    this.preparePayloadForAudits(name, body);
    
    return this._authHttp.post(auditURL, this.payloadForAudits, headerOptions)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(err => {
          console.log('log audit error', err);
          return of();
        })
      );
  }
  
  convertToSpinalCase(str) {
    return str.replace(/([A-Z])/g,' $1') /*Find all cap and add space at the start*/
              .replace(/[^A-Za-z0-9]/g,' ') /*Find all non alpha numeric and replace it with space*/
              .replace(/\s{1,}/g,"-") /*Convert all spaces to -*/
              .replace(/^\-|[\-]$/g,'') /*Slice - at the start and end*/
              .toLowerCase(); /*LowerCase it*/
  }
  
  preparePayloadForAudits(name, body) {
    let data = {
      "workflow_instance_id": this.localStorage.getItem("workflow_instance_id"),
      "account_id":  this.localStorage.getItem('accountId'),
      "invite_id":this.localStorage.getItem("invite_id"),
      "email": this.localStorage.getItem("email"),
      "invite_key": this.localStorage.getItem("invite_key"),
      "profile_id" : this.localStorage.getItem('profile_id'),
      "visiter_id" : this.localStorage.getItem('visiter_id')
    } ;
    
    this.payloadForAudits = {
      "name" : this.convertToSpinalCase(name),
      "category" : body['stage_name'],
      "at" : new Date(),
      "by" : this.localStorage.getItem('profile_id'),
      "action" : body['action_value'],
      "system" : 'TRUID',
      "data" : data
    }
  }

  processTrackEvent(eventName, stageName, startTime, metaData, isBehavioralMetrics, isOperationalMetrics) {
    let endTime = new Date().getTime();
    let timeDiff = (endTime - startTime) / 1000;
    timeDiff = Math.floor(timeDiff);
    let trackObj = {
      stage_name: stageName,
      time_spent: timeDiff
    };
    if (metaData) {
      Object.assign(trackObj, metaData);
    }
    this.updateTrackEvent(eventName, trackObj, isBehavioralMetrics, isOperationalMetrics);
  }
}
