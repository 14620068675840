<div class="container">
  <div class="row heading">
    <h3 *ngIf="name!==''"> {{landingWelcomeToText}}</h3>
    <h3 *ngIf="name===''"> {{landingWelcomeText}}</h3>
  </div>
  <div class="row sub-heading">
    {{landingText}}
  </div>
  <div class=" col-md-12 col-sm-12 col-lg-12">
    <ul class="timeline list-unstyled">
      <li>
        <!-- <img class="timeline-pic" src="../../assets/img/choose_document.svg" alt="Passport or your ID"> -->
        <span class="icon-choose-document rightIdIcons"></span>
        <div>
          {{landingChooseIDTypeText}}
        </div>
      </li>
      <li>
        <!-- <img class="timeline-pic" src="../../assets/img/license.svg" alt="Generic placeholder image"> -->
        <span class="icon-license rightIdIcons"></span>
        <div>
          {{landingIDText}}
        </div>
      </li>
      <li>
        <!-- <img class="timeline-pic" src="../../assets/img/selfie.svg" alt="Generic placeholder image"> -->
        <span class="icon-selfie rightIdIcons"></span>
        <div>
          {{landingSelfieText}}
        </div>
      </li>
      <li>
        <!-- <img class="timeline-pic" src="../../assets/img/check_mark.svg" alt="Generic placeholder image"> -->
        <span class="icon-check-mark rightIdIcons"></span>
        <div>
          {{landingThatsItText}}
        </div>
      </li>
    </ul>
  </div>
  <div class="almost-bottom row">
    <button name="next" class="btn button grow btn-align" (click)="next()"> {{landingButtonName}}</button>
  </div>
  <div *ngIf="showOptOut === 'YES'" class="link m-b-20">
    <a [routerLink]="[]" class="col offset-md-3 col-md-6 col-sm-12 col-lg-3"
      (click)="optOut()">{{optOutLinkLabel}}</a>
  </div>
  <div class="row video_link text-center">
    <span (click)="openVideoModal()">{{ 'RID_VIDEO_LABEL_1' | translate }}</span>{{ 'RID_VIDEO_LABEL_2' | translate }}
  </div>
</div>
<!-- Opt out Modal - starts -->
<div class="modal fade custom_modal" id="optout-modal" data-backdrop="static" role="dialog" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeOptOutModal()" data-dismiss="modal">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title">{{ optOutPopupMsg}}</h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="">
            <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
              (click)="processOptOut()">{{'COMMONS_OPTOUT' | translate }}</button>
          </div>
          <div class="">
            <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
              (click)="closeOptOutModal()">{{'COMMONS_GOBACK' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Please Wait Modal - starts -->
<div class="modal fade custom_modal" id="pleaseWaitModal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="modal-body text-center">
        <div class="modal-title m-t-10" [innerHTML]="'PLEASE_WAIT_PAGE_IS_BEING_REDIRECTED' | translate "> </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
<!-- Show video modal - starts -->
<div class="modal fade custom_modal" id="showVideoModal" tabindex="-1"  role="dialog" aria-labelledby="videoModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeVideoModal()" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <embed width="100%" height="300px" wmode="transparent" allow="encrypted-media; picture-in-picture" [src]="videoUrl">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn button grow font_extrabold" data-dismiss="modal" (click)="closeVideoModal()">{{'BUTTON_BACK' | translate }}</button>
      </div>
    </div>
  </div>
</div>
