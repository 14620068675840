<div>
  <h3>{{'CONFIRM_PICTURE' | translate}}</h3>
  <ng-content></ng-content>
  <div class="row m-t-15 image-padding">
    <img width="100%" src="{{resultImage}}" />
  </div>
  <div  class="almost-bottom row">
    <button class="btn button grow btn-align" (click)="clickConfirmRetakeButton();">{{'RETAKE' | translate}}</button>
    <button class="btn button grow btn-align" [disabled]="resultImage==='abc'" debounceDirective (debounceClick)="clickConfirmNextButton();">{{'CONFIRM_NEXT' | translate}}</button>
  </div>
</div>
