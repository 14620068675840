import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@fadv/web-common';
import { TranslateService } from '../framework/i18n/translate.service';
import { SharedService } from '../shared/Services/shared.service';
import { TrackEventService } from '../shared/Services/trackEvent.service';
import { ApplicantionConstants, Result, VENDOR, ApplicantStatus } from '../identity-config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { IdentityPreCheckService } from './identity-pre-check.service';
import { v4 as uuidv4 } from 'uuid';

declare var window: any;
declare var jQuery: any;

@Component({
    selector: 'identity-pre-check',
    templateUrl: './identity-pre-check.component.html',
    providers: [IdentityPreCheckService]
})
export class IdentityPreCheckComponent implements OnInit {
    errorMessage: string = '';
    invite_id: string;
    skip_consent: boolean = false;
    trackObj: Object;
    static FONT_BASED_SIGNATURE_PREF = "1453";

    constructor(private router: Router, private _identityPreCheckService: IdentityPreCheckService, private _activatedRouter: ActivatedRoute,
        private localStorage: LocalStorageService, private _ts: TranslateService, private sharedService: SharedService,
        public trackEventService: TrackEventService, private deviceService: DeviceDetectorService) {

    }

    ngOnInit() {
        jQuery('#pleaseWaitModal').modal('show');
        let inviteidtoken = this._activatedRouter.snapshot.params['token'];
        if (inviteidtoken != undefined) {
            this.invite_id = inviteidtoken;
        }

        // console.log('$$$WEB - VENDOR$$$ ' + this.localStorage.getItem('websdk_vendor'));
        // console.log('$$$API - VENDOR$$$ ' + this.localStorage.getItem('verification_api_vendor'));
        let capture_mode = this.localStorage.getItem("capture_mode");
        if (capture_mode == null || capture_mode === undefined || capture_mode == '') {
            // console.log("No capture mode preference - set to default " + environment.camerasettings.captureMode);
            capture_mode = environment.camerasettings.captureMode;
            this.localStorage.setItem("capture_mode", capture_mode);
        }

        this.skip_consent = this._activatedRouter.snapshot.queryParams['skip_consent'];
        // console.log("identity-pre-check.component this.invite_id, skip_consent :" + this.invite_id, this.skip_consent);
        if (this.invite_id != undefined) {
          this.localStorage.setItem("invite_id", this.invite_id);
        }
        this._identityPreCheckService.validateToken(this.invite_id).subscribe(response => this.processResponse(response),
            error => {
                this.sharedService.setErrorCodeAndMsg(error.errorCode, error.errorMessage);
                // console.log("IdentityPreCheckComponent errorCode and errorMessage", error.errorCode, error.errorMessage);
                this.hidePleaseWait();
                this.router.navigate(['/app-error']);
            }
        );

    }

    processResponse(inviteResponse: Object): void {
        // console.log("IdentityPreCheckComponent  response ", inviteResponse);
        let inviteType = inviteResponse['type'];
        let isEARightId = (inviteResponse['account']['is_ea_rightId'] == 'Y');
        if (inviteType !== undefined && inviteType.toLowerCase() === 'rightid' || isEARightId) {
            const customStyle = inviteResponse['custom_styles'];
            if (customStyle != undefined) {
                jQuery('#customStyles').html(customStyle);
            }

            let userPrefLanguageCode = inviteResponse['language'];
            let userPrefCountryCode = inviteResponse['language_country_code'];
            if (userPrefLanguageCode === undefined) {
                userPrefLanguageCode = 'en';
            }
            if (userPrefCountryCode === undefined) {
                userPrefCountryCode = 'US';
            }
            this.localStorage.setItem('language_code', userPrefLanguageCode);
            this._ts.use(userPrefLanguageCode);
            //Initialize/boot
            window.SupportBus.emit('fadv::support::boot',
            {
                initializeUrl: environment.salesForceInitializeUrl,
                chatSettings: environment.salesForceChatSettings,
                initBaseUrl: environment.salesForceChatBaseUrl,
                embeddedServiceMinJS: environment.salesForceChatEmbeddedServiceMinJS,
                chatPosition: environment.salesForceChatPosition
            });

            let signatureFont = inviteResponse['account']['SIGNATURE_FONT'];
            this.localStorage.setItem("SIGNATURE_FONT", signatureFont);

            let accountId = inviteResponse['account']['id'];
            let accountTag = inviteResponse['account']['tag'];
            let inviteSource = inviteResponse['invite_source'];

            if (inviteResponse['configuration'] !== undefined && inviteResponse['configuration']['embedded'] !== undefined) {
                if (inviteResponse['configuration']['embedded']["redirect_url"]) {
                    let redirectUrl = inviteResponse['configuration']['embedded']["redirect_url"];
                    this.localStorage.setItem("redirect_url", redirectUrl);
                }
                if (inviteResponse['configuration']['embedded']["referrer"]) {
                    let referrer = inviteResponse['configuration']['embedded']["referrer"];
                    this.localStorage.setItem("referrer", referrer);
                }
            }

            //Invoke account-api service.
            this._identityPreCheckService.getAccountInfo(inviteType, accountId, accountTag, inviteSource, userPrefCountryCode, userPrefLanguageCode).subscribe(accountResponse => this.processAccountInfo(accountResponse, inviteResponse),
                error => {
                    console.log("IdentityPreCheckComponent getAccountInfo error ", error);
                    this.hidePleaseWait();
                    if (error.errorCode === "404") {
                        this.processAccountInfo(null, inviteResponse);
                    } else {
                        this.sharedService.setErrorCodeAndMsg(error.errorCode, error.errorMessage);
                        console.log("IdentityPreCheckComponent errorCode and errorMessage", error.errorCode, error.errorMessage);
                        this.router.navigate(['/app-error']);
                    }
                }
            );

        } else {
            this.hidePleaseWait();
            this.sharedService.setErrorCodeAndMsg('BAD_REQUEST', 'Invalid invite type');
            //console.log("IdentityPreCheckComponent invalid inviteType", inviteType);
            this.router.navigate(['/app-error']);
        }
    }

    processAccountInfo(accountResponse: Object, inviteResponse: Object): void {
        //Default values when account-api has no entry for the given system and accountId
        let customMessage: String = 'ERROR_MSG_DESKTOP_TRUE';
        let customOptOutMessage: String = 'ERROR_MSG_OPT_OUT';
        let showOptOut = 'YES';
        let retryAttempt = 3;

        this.hidePleaseWait();
        //1. Check if the account has preferences.
        if (accountResponse != null && accountResponse['preferences'] != undefined &&
            accountResponse['preferences'].length > 0) {
            let preferenceList = accountResponse['preferences'];
            //2. Check if the preference for opt-out is set. Iterate the response object and store it.
            for (var preference of preferenceList) {
                if (preference['name'] == 'SHOW_OPT_OUT') {
                    showOptOut = preference['value'];
                    continue;
                }
                if (preference['name'] == 'CAPTURE_MODE' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
                    // console.log(" capture mode = " + preference['value'])
                    this.localStorage.setItem('capture_mode', preference['value']);
                    continue;
                }
                if (preference['name'] == 'RETRY_ATTEMPT') {
                    // console.log(" retry attempt = " + preference['value'])
                    retryAttempt = preference['value'];
                    continue;
                }
                if (preference['name'] == 'REDIRECT_URL' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
                    if ( this.localStorage.getItem('redirect_url') == undefined  || this.localStorage.getItem('redirect_url') == ''){
                        this.localStorage.setItem('redirect_url', preference['value']);
                    }
                    continue;
                }
                if (preference['name'] == 'WEBSDK_VENDOR' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
                    this.localStorage.setItem('websdk_vendor', preference['value']);
                    continue;
                }
                if (preference['name'] == 'VERIFICATION_API_VENDOR' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
                    this.localStorage.setItem('verification_api_vendor', preference['value']);
                    continue;
                }
                if (preference['name'] == 'SHOW_ABORT_OPTIONS' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
                    this.localStorage.setItem('show_abort_options', preference['value']);
                    continue;
                }
                if (preference['name'] == 'SHOW_NON_US_FLOW' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
                    this.localStorage.setItem('show_non_us_flow', preference['value']);
                    continue;
                }
                if (preference['name'] == 'MASK_DECISION' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
                    this.localStorage.setItem('mask_decision', preference['value']);
                    continue;
                }
                if (preference['name'] ==  IdentityPreCheckComponent.FONT_BASED_SIGNATURE_PREF && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
                    this.localStorage.setItem('use_font_based_signature', preference['value']);
                    continue;
                }
            }
        }

        this.localStorage.setItem('show_opt_out', showOptOut);
        this.localStorage.setItem('retry_attempt', retryAttempt);

        if (accountResponse != null && accountResponse['messages'] != undefined &&
            accountResponse['messages'].length > 0) {

            let messages = accountResponse['messages'];

            if (messages != undefined && messages.length > 0) {
                for (var message of messages) {
                    // if value is not availble for the key then we will not consider this message.
                    if (message['value'] === undefined || message['value'] === '' || message['value'] == null){
                        continue;
                    }
                    switch(message['key']){
                        case 'CONTINUE_ON_MOBILE_MSG':
                            customMessage = message['value'];
                            break;
                        case 'OPT_OUT_POPUP_MSG':
                            this.localStorage.setItem('opt_out_popup_msg', message['value']);
                            break;
                        case 'OPT_OUT_MSG':
                            customOptOutMessage = message['value'];
                            break;
                        case 'OPT_OUT_LINK_LABEL':
                            this.localStorage.setItem('opt_out_link_label', message['value']);
                            break;
                        case 'ID_SELECT_ABORT_POPUP_MSG':
                            this.localStorage.setItem('id_select_abort_popup_msg', message['value']);
                            break;
                        case 'ID_SELECT_ABORT_LINK_LABEL':
                            this.localStorage.setItem('id_select_abort_link_label', message['value']);
                            break;
                        case 'RESULT_CONFIRMATION_ABORT_POPUP_MSG':
                            this.localStorage.setItem('result_confirmation_abort_popup_msg', message['value']);
                            break;
                        case 'RESULT_CONFIRMATION_ABORT_LINK_LABEL':
                            this.localStorage.setItem('result_confirmation_abort_link_label', message['value']);
                            break;
                        case 'LANDING_WELCOME_TEXT':
                            this.localStorage.setItem('landing_welcome_text', message['value']);
                            break;
                        case 'LANDING_WELCOME_TO_TEXT':
                            this.localStorage.setItem('landing_welcome_to_text', message['value']);
                            break;
                        case 'LANDING_TEXT':
                            this.localStorage.setItem('landing_text', message['value']);
                            break;
                        case 'LANDING_CHOOSE_ID_TYPE_TEXT':
                            this.localStorage.setItem('landing_choose_id_type_text', message['value']);
                            break;
                        case 'LANDING_ID_TEXT':
                            this.localStorage.setItem('landing_id_text', message['value']);
                            break;
                        case 'LANDING_SELFIE_TEXT':
                            this.localStorage.setItem('landing_selfie_text', message['value']);
                            break;
                        case 'LANDING_THATS_IT_TEXT':
                            this.localStorage.setItem('landing_thats_it_text', message['value']);
                            break;
                        case 'LANDING_BUTTON_NAME':
                            this.localStorage.setItem('landing_button_name', message['value']);
                            break;
                        case 'DECLINE_ESIGNATURE_CONFIRM_TEXT':
                            this.localStorage.setItem('decline_esignature_confirm_text', message['value']);
                            break;
                        case 'DECLINE_ESIGNATURE_TEXT':
                            this.localStorage.setItem('decline_esignature_text', message['value']);
                            break;
                    }

                }
            }
        }
        this.localStorage.setItem('opt_out_message', customOptOutMessage);
        this.localStorage.setItem('visiter_id', uuidv4());
        
        if (this.deviceService.isDesktop()) {
            let profile_id = inviteResponse["profile_id"];
            let fname = inviteResponse["applicant"]['first_name'];
            let lname = inviteResponse["applicant"]['last_name'];
            let email = inviteResponse["applicant"]['email'];
            let userObj = {
                name: fname + ' ' + lname,
                email: email,
                user_id: profile_id
            }
            let inviteKey = inviteResponse["key"];
            if (customMessage !== 'ERROR_MSG_DESKTOP_TRUE') {
                let redirectURL = this.localStorage.getItem('redirect_url');
                if (redirectURL != undefined && redirectURL !== '' ){
                    let url = new URL(redirectURL);
                    var search_params = url.searchParams;
                    let id = this.localStorage.getItem('invite_id');
                    if (id != undefined){
                        search_params.set('id', id);
                    }
                    search_params.set('action', ApplicantStatus.NO_MOBILE_ACCESS);
                    let new_url = url.toString();
                    customMessage = customMessage.replace('{0}', new_url)
                }
            }
          
            // this.trackEventService.updateTrackEvent('validating token from url', { stage_name: 'identity pre check', action_value: 'invite link clicked' });
            this.trackEventService.updateTrackEvent('load error on desktop', { stage_name: 'identity pre check', action_value: 'invite link clicked in desktop' }, true, true);
            this.sharedService.setErrorCodeAndMsg('DESKTOP_TRUE', customMessage);
            this.router.navigate(['/app-error']);
            return;
        }


        this.processInviteResponse(inviteResponse);
    }

    processInviteResponse(response: Object): void {
        // console.log("processInviteResponse  response ", response);
        let profile_id = response["profile_id"];
        let key = response["key"];
        let userPrefLanguageCode = response['language'];
        let userPrefCountryCode = response['language_country_code'];
        let accountId = response['account']['id'];
        let inviteSource = response['invite_source'];
        let accountTag = response['account']['tag'];

        if (userPrefLanguageCode === undefined) {
            userPrefLanguageCode = 'en';
        }
        if (userPrefCountryCode === undefined) {
            userPrefCountryCode = 'US';
        }

        this.localStorage.setItem("companyName", response["account"]['name']);
        this.localStorage.setItem("userPrefCountryCode", userPrefLanguageCode);
        this.localStorage.setItem("userPrefCountryCode", userPrefCountryCode);
        this.localStorage.setItem("invite_key", key);
        this.localStorage.setItem("accountId", accountId);

        let fname = response["applicant"]['first_name'];
        let mname = response["applicant"]['middle_initial'];
        let lname = response["applicant"]['last_name'];
        let email = response["applicant"]['email'];
        let mobile = response['applicant']['mobile'];
        let invite_status = response['applicant']['status'];
        let data = response['applicant']['data'];
        let applicant_type = '';
        let result = '';
        let consent = '';
        let consent_date = '';

        if (data) {
            applicant_type = data['type'];
            consent = data['consent'];
            consent_date = data['consent_date'];
            result = data['result'];
        }
        this.localStorage.setItem("applicant_data", data);
        if (consent != undefined && consent_date != undefined){
            this.localStorage.setItem("consent", consent);
            this.localStorage.setItem("consent_date", consent_date);
        }
        let updateObj = {
            name: fname + ' ' + lname,
            email: email,
            user_id: profile_id
        }

        this.trackEventService.updateTrackEvent('validating token from url', { stage_name: 'identity pre check', action_value: 'invite link clicked' }, true, true);

        if (profile_id != undefined && profile_id !== '') {
            this.localStorage.setItem("profile_id", profile_id);
            this.localStorage.setItem("first_name", fname);
            this.localStorage.setItem("middle_name", mname);
            this.localStorage.setItem("last_name", lname);
            this.localStorage.setItem("email", email);
            this.localStorage.setItem("mobile", mobile);
            this.localStorage.setItem("applicant_type", applicant_type);
            this.localStorage.setItem("status", invite_status);
            this.localStorage.setItem("result", result);
        }
        if (result === Result.Verified) {
            this.sharedService.setErrorCodeAndMsg('VERIFIED_PASSED', 'Verified and passed.');
            this.trackEventService.updateTrackEvent('result is ' + result + ', go to error page', { stage_name: 'identity pre check', action_value: 'invite link clicked' }, true, true);
            this.router.navigate(['/app-error']);
            return;
        }
        if (consent != ApplicantionConstants.ACCEPTED.toString() &&
            (this.skip_consent === undefined || !this.skip_consent)) {
            let workflow_instance_id = null;
            jQuery('#pleaseWaitModal').modal('show');
            if (response['workflow_instance'] != undefined) {
                workflow_instance_id = response['workflow_instance']['id'];
                this.localStorage.setItem('workflow_instance_id', workflow_instance_id);
            }
            if (workflow_instance_id === undefined) {
                this.sharedService.setErrorCodeAndMsg('BAD_REQUEST', 'Workflow not available.');
                this.hidePleaseWait();
                this.router.navigate(['/app-error']);
                return;
            }
            this.sharedService.getWorkflow(workflow_instance_id).subscribe(workflowResponse => {
                let consentForm = this.getConsentForm(workflowResponse);
                this.hidePleaseWait();
                if (consentForm === undefined || consentForm == null) {
                    this.sharedService.setErrorCodeAndMsg('BAD_REQUEST', 'consent not available.');
                    this.router.navigate(['/app-error']);
                    return;
                }
                this.localStorage.setItem('consent_form', consentForm);
                this.router.navigate(['/app-consent'], { queryParams: { invite_id: this.invite_id}, queryParamsHandling: 'merge' });

            }, workflowError => {
                this.sharedService.setErrorCodeAndMsg('BAD_REQUEST', 'Workflow not available.');
                this.hidePleaseWait();
                this.router.navigate(['/app-error']);
                return;
            });

        } else {
            this.router.navigate(['/app-landing'], { queryParams: { invite_id: this.invite_id}, queryParamsHandling: 'merge' });
        }
    }

    hidePleaseWait() {
        jQuery('#pleaseWaitModal').modal('hide');
        try {
            jQuery('.modal-backdrop').remove();
        } catch (err) {
            console.log("error on hidePleaseWait()");
        }
    }

    getConsentForm(data: any) {
        let stages = data['workflow']['stages'];
        if (stages != undefined) {
            for (let i = 0; i < stages.length; i++) {
                let stage = stages[i];
                if (stage['name'] === 'agreement-v1') {
                    let steps = stage['steps'];
                    if (steps != undefined) {
                        for (let j = 0; j < steps.length; j++) {
                            let step = steps[j];
                            if (step['name'] == 'consent-step-v1') {
                                let forms = step['forms'];
                                if (forms != undefined) {
                                    for (let k = 0; k < forms.length; k++) {
                                        let form = forms[k];
                                        if (form['form_code'] == 'CONSENT') {
                                            return form;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return null;
    }
}
