import { NgModule } from '@angular/core';
import { IdCameraComponent } from './id-camera.component';


@NgModule({
  declarations: [IdCameraComponent],
  imports: [
  ],
  exports: [IdCameraComponent]
})
export class IdCameraModule { }
