export class ConfirmationConfig {
    static steps: any = [
        'submission',
        'result',
    ];

    static getSteps(): any {
        return ConfirmationConfig.steps;
    }
}
