/*
 * Public API Surface of identity
 */

export * from './lib/consent/consent.component';
export * from './lib/confirm/confirm.component';
export * from './lib/instructions/instructions.component';
export * from './lib/landing/landing.component';
export * from './lib/identity-pre-check/identity-pre-check.component';
export * from './lib/applicant-info/applicant-info.component';
export * from './lib/id/id-select.component';
export * from './lib/id/id.component';
export * from './lib/selfie/selfie.component';
export * from './lib/confirmation/confirmation.component';
export * from './lib/error/error.component';
export * from './lib/camera-flow/camera-flow.component';
export * from './lib/selfie-review/selfie-review.component';
export * from './lib/framework/i18n/translate.pipe';
export * from './lib/identity-routing.module';
export * from './lib/identity.module';
