import { Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[debounceDirective]'
})
export class DebounceDirective implements OnInit, OnDestroy {


  @Output() debounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  debounceTime = 5000;


  constructor() { }

  ngOnInit() {
    console.info('****** debounce ng on init **********');
    this.subscription = this.clicks.pipe(
      throttleTime(this.debounceTime)
    ).subscribe(e => {
      console.log('************* Emitted');
      this.debounceClick.emit(e)

    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
