<div class="padding-15">
  <div class="selfie-review-main-div">
    <h3>{{pageHeading}}</h3>
    <ng-content></ng-content>
    <div class="row m-t-15 image-padding">
      <img width="100%" src="{{selfieImage}}" />
    </div>
    <div  class="almost-bottom row">
      <button class="btn button grow btn-align" (click)="onConfirmRetake($event)">{{retakeButtonHeading}}</button>
      <button class="btn button grow btn-align" (click)="onConfirmNext($event)">{{nextButtonHeading}}</button>
    </div>
  </div>
</div>
