import { NgModule } from '@angular/core';
import { MitekCameraComponent } from './mitek-camera.component';
import { CommonModule, DatePipe } from '@angular/common';

import { TranslatePipe } from './framework/i18n/translate.pipe';
import { TranslateService } from './framework/i18n/translate.service';
import { TRANSLATION_PROVIDERS } from './framework/i18n/translations';



@NgModule({
  declarations: [MitekCameraComponent, TranslatePipe, ],
  imports: [CommonModule
  ],
  providers: [TranslateService, TRANSLATION_PROVIDERS, DatePipe, TranslatePipe],
  exports: [MitekCameraComponent]
})
export class MitekCameraModule { }
