import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IdCameraModule } from '@fadv/id-camera';
import { TranslateService, TRANSLATION_PROVIDERS } from 'src/framework/i18n';
import { environment } from './../environments/environment';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { IdentityModule, IdentityRoutingModule } from '@fadv/identity';
import { AppRoutingModule } from './app-routing.module';
import { ApmService } from '@elastic/apm-rum-angular';
import { SharedService } from 'projects/fadv/identity/src/lib/shared/Services/shared.service';
import { MitekCameraModule } from '@fadv/mitek-camera';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    IdCameraModule,
    IdentityRoutingModule,
    IdentityModule,
    MitekCameraModule,
    AppRoutingModule,   
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    TranslateService,
    TRANSLATION_PROVIDERS,
    {
      provide: ApmService,
      useClass: ApmService,
      deps: [Router]
    },
    SharedService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(@Inject(ApmService) service: ApmService, @Inject(SharedService) sharedService: SharedService) {

    var cLog = console.log;
    var cError = console.error;
    try{
      console.log = ( msg :any, ...optionalParams: any[] ) => { 
        cLog(msg, optionalParams);
        try{
          let body = {};
          body['message'] = msg;
          body['log_type'] = 'INFO';
          sharedService.log(body).subscribe(response => {
          
          },
          error => {
            cError(error);
          });
        } catch(e){
          cError(e);
        }
      }
      console.error = ( msg :any, ...optionalParams: any[] ) => { 
        cError(msg, optionalParams);
        try{
          let body = {};
          body['message'] = msg;
          body['log_type'] = 'ERROR';
          sharedService.log(body).subscribe(response => {
          
          },
          error => {
            cError(error);
          });
        } catch(e){
          cError(e);
        }
      }
    } catch(e){
      cError(e);
    }


    // API is exposed through this apm instance
    const env = environment;

    // Enable APM only for QCA and UAT
    // if (env.apm_environment === 'qca' || env.apm_environment === 'uat' || env.apm_environment === 'dev') {
    //   let serviceName = 'rightid-web-' + env.apm_environment;
    //   let logLevelEnable = '';

    //   // Enable debug only for Dev and QCA
    //   if (env.apm_environment === 'qca' || env.apm_environment === 'dev')
    //     logLevelEnable = 'debug';

    //   console.log('Test Node - ' + serviceName);
    //   console.log('LL - ' + logLevelEnable);

    //   const apm = service.init({
    //     serviceName: serviceName,
    //     serverUrl: '/api/v1/apm',
    //     serviceVersion: '1.0',
    //     logLevel: logLevelEnable,
    //     environment: env.apm_environment
    //   })

    //   apm.setUserContext({
    //     'username': 'foo',
    //     'id': 'bar'
    //   });
    // }
  }

 }
