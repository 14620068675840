<div>
  <ng-content></ng-content>
  <div>
    <button class="btn button btn-align m-t-15"
      debounceDirective (debounceClick)="clickInstructionNextButton();">{{'GOT_IT' | translate }}</button>
  </div>
</div>

<div class="modal fade tryAltToast" id="tryalt-modal" tabindex="-1"  role="dialog" aria-labelledby="tryAltModalPop" data-backdrop="false" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header"></div>
      <div class="modal-body text-center">
        <button type="button" class="close" (click)="closeTryAltModal()" data-dismiss="modal">&times;</button>
        <div class="modal-title m-t-10" id="tryAltModalPop">
          <div class="having_trouble_title">{{'HAVING_TROUBLE' | translate }}</div>
          <a (click)="processTryAlt()">{{'TRY_ALTERNATE_CLICK_HERE' | translate }}</a>&nbsp;<span>{{'TRY_ALTERNATE_METHODS' | translate }}</span>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>

<!-- Please Wait Modal - starts -->
<div class="modal fade custom_modal" id="pleaseWaitModal" tabindex="-1"  role="dialog" aria-labelledby="rightIdPleaseWaitPop" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="modal-body text-center">
        <div class="modal-title m-t-10" id="rightIdPleaseWaitPop" [innerHTML]="'PLEASE_WAIT_PAGE_IS_BEING_REDIRECTED' | translate "> </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
