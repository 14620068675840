<h4 class="text-center confirm-signature-text confirm-sign-animate-right" #confirmSignatureTextSection style="display:none" >{{confirmSignatureText}}</h4>
<div class="sign-container" (window:resize)="resize()">
    <div #signatureContent class="">
        <div class="custommodal_body_title">
            <!--* You can use a tablet, mouse or touch (smartphones & tablets).-->
            {{padNote}}
        </div>

        <div class="canvas_bg"  id="canvas_bg">
            <signature-pad 
                id="sigPad"
                (onBeginEvent)="drawBegin()" (onEndEvent)="drawComplete()" ></signature-pad> 
        </div>


        <div class="canvas_name" >
            <!--
            <div class="icon">
                <i class="fa fa-times" aria-hidden="true" (click)="clear()"></i>
            </div>
            -->
            <div class="carl">
                <span>{{displayLabels}}</span>
            </div>  
        </div>
        <div *ngIf="useFontBasedSignature" class="mo-l-0 row m-t-40 pad_30">
            <div class="col-xs-12 col-sm-12 col-md-12 m-b-10 ">
                <div class="group">
                    <label class="placeholder_white custom_label" for="fbsign">{{ signatureLabel }}</label>
                    <input type="text" class="form-control gray50" id="fbsign" maxlength="150" [(ngModel)]="fontBasedSignature"
                        (input)="drawFontBasedSignature()" />
                </div>
            </div>
        </div>
    </div>
    <div class="canvas_bg confirm-sign-animate-right" style="display:none"  #confirmSignatureContent >
        <div class="canvas_bg">     <img #confirmSignatureImage src=""/></div>
    </div>
    <br />
</div>