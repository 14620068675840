import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@fadv/web-common';
import { SharedService } from './shared.service';


@Injectable({
  providedIn: 'root'
})
export class DirectAccessGuardService implements CanActivate {

  constructor(private localStorage: LocalStorageService, private sharedService: SharedService, private router: Router, private _activatedRouter: ActivatedRoute) { }

  canActivate() {
    let inviteKey = this.localStorage.getItem("invite_key");
    let inviteid = this.localStorage.getItem("invite_id");
    let inviteidtoken = inviteid;
    if (inviteidtoken === undefined) {
      inviteidtoken = this.sharedService.getInviteIdFromQueryParam();
    }
    if (inviteKey === undefined) {
      this.sharedService.setErrorCodeAndMsg('BAD_REQUEST', 'Invalid url');
      
      let redirecturl = '/app-error';
      if (inviteidtoken != undefined) {
        this.sharedService.setInviteIdToken(inviteidtoken);
      }
      this.router.navigate([redirecturl]);
    } else {
      return true;
    }
  }
}
