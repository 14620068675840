export interface CameraConfig {
   documentType?: string;
   documentSide?: string;
   captureType?: string;
   enableFarSelfie?: boolean;
   captureMode?: string;
}

  export enum CAPTURE_TYPE {
    DOCUMENTID = 'DOCUMENTID',
    SELFIE = 'SELFIE'
  };

  export enum DOCUMENT_MODE  {
    LICENSE = 'License',
    PASSPORT = 'Passport'
  };

  export enum DOCUMENT_SIDE  {
    FRONT = 'Front',
    BACK = 'Back'
  };

  export const objDocumentSettingsDefault = {
    documentType : 'License',
    documentSide : 'Front',
    captureMode : 'Auto',
    frontFocusThreshold : 40,
    nativeFrontFocusThreshold: 40,
    frontGlareThreshold : 2.5,  
    nativeFrontGlareThreshold: 2.5,
    frontCaptureAttempts : 8,
    backFocusThreshold : 50,
    nativeBackFocusThreshold: 110,
    backGlareThreshold : 2.5,
    nativeBackGlareThreshold: 2.5,
    backCaptureAttempts : 8,
    compressionQuality : 50,
    compressionType : 'JPEG',
    enableFaceDetection : true,
    overlayText : "Align ID within Frame and Tap Screen to Capture.",
    overlayTextAuto : "Align ID within Frame and Hold for Auto Capture.",
    overlayColor : "red",
    setManualTimeout : 20,
    isBarcodeDetectedEnabled : false,
    autoCaptureCheckNFrames: 3,
    showCaptureModeText: true,
    overlayTextManualADA: "Align ID and click when you hear notification",
    overlayTextAutoADA: "Align ID and hold till you hear notification.",
    notificationADA: "Valid image identified, hold steady and tap on screen",
    enableLocationDetection: false,
    goodImageFoundADA: "Good Image Found”",
    notGoodImageADA: "Captured image is not good",
    manualPostClickTimeout: 4
 }

 export const objSelfieSettingssDefault = {
    captureMode : 'Auto',
    compressionQuality : 50,
    useBackCamera : false,
    enableFarSelfie : false,
    enableFaceDetection : true,
    captureAttempts: 8,
    overlayText : "Align face and capture",
    overlayColor : "#808080",
    orientationErrorText : "Landscape orientation is not supported. Kindly rotate your device to Portrait orientation.",
    faceDetectorQuality : "medium",
    overlayTextAuto : "Align Face and Hold",
    setManualTimeout : 20,
    autoCaptureCheckNFrames: 3,
    overlayTextManualADA: "Align ID and click when you hear notification",
    overlayTextAutoADA: "Align ID and hold till you hear notification",
    notificationADA: "Valid image identified, hold steady and tap on screen",
    goodImageFoundADA: "Good Image Found",
    notGoodImageADA: "Captured image is not good"
 }