import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageService } from '@fadv/web-common';
import { IdentityConfig, VENDOR } from '../identity-config';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private _http: HttpClient, private localStorage: LocalStorageService, ) { }

  uploadImages(inviteId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let body = {
      "id": inviteId,
      "front_only_id": false,
      "id_document": {
      },
      "selfie": {
      }
    }
    let retakesteps = this.localStorage.getItem("retakesteps");
    let selectedIDType = this.localStorage.getItem("selectedIDType");
    let frontIdImg = '';
    let backIdImg = '';
    let selfieNearImg = '';
    let selfieFarImg = '';
    let key = '';

    let sendFrontId = true;
    let sendBackId = true;
    let sendSelfieNear = true;
    let sendSelfieFar = true;


    if (!!retakesteps) {
      let retakeIDFrontStep = (retakesteps.indexOf('id-front') !== -1);
      let retakeIDBackStep = (retakesteps.indexOf('id-back') !== -1);
      let retakeSelfieNearStep = (retakesteps.indexOf('selfie-near') !== -1);
      let retakeSelfieFarStep = (retakesteps.indexOf('selfie-far') !== -1);

      if (!retakeIDFrontStep) {
        sendFrontId = false;
      }
      if (!retakeIDBackStep) {
        sendBackId = false;
      }
      if (!retakeSelfieNearStep) {
        sendSelfieNear = false;
      }
      if (!retakeSelfieFarStep) {
        sendSelfieFar = false;
      }
    }
    let isSingleSelfieSupported = this.localStorage.getItem("isSingleSelfieSupported");
    if (isSingleSelfieSupported) {
      sendSelfieFar = false;
      body['single_selfie'] =  true;
    }

    if(selectedIDType === 'gid' || selectedIDType === 'non_us_gid'){
      body['id_document']['type'] = 'ID';
    } else if(selectedIDType === 'passport'){
      body['id_document']['type'] = 'PASSPORT';
    }

    if (sendFrontId && selectedIDType === 'gid') {
      key = 'rightId-' + inviteId + '-' + 'idFront';
      frontIdImg = this.localStorage.getItem(key);
      body['id_document']['front'] = frontIdImg;
    }

    if (selectedIDType === 'non_us_gid') {
      key = 'rightId-' + inviteId + '-' + 'idFront';
      frontIdImg = this.localStorage.getItem(key);
      body['id_document']['front'] = frontIdImg;
      body['front_only_id'] = true;
    }

    if (sendBackId && selectedIDType === 'gid') {
      key = 'rightId-' + inviteId + '-' + 'idBack';
      backIdImg = this.localStorage.getItem(key);
      body['id_document']['back'] = backIdImg;
    }
    if (sendFrontId && selectedIDType === 'passport') {
      key = 'rightId-' + inviteId + '-' + 'passport';
      frontIdImg = this.localStorage.getItem(key);
      body['id_document']['front'] = frontIdImg;
    }
    if (sendSelfieNear) {
      key = 'rightId-' + inviteId + '-' + 'selfieNear';
      selfieNearImg = this.localStorage.getItem(key);
      body['selfie']['near'] = selfieNearImg;
    }
    if (sendSelfieFar) {
      key = 'rightId-' + inviteId + '-' + 'selfieFar';
      selfieFarImg = this.localStorage.getItem(key);
      body['selfie']['far'] = selfieFarImg;
    }

    let vendorApi = this.localStorage.getItem('verification_api_vendor');
    body['vendor_api'] = vendorApi;
    let path = '';
    path = IdentityConfig.API_ENDPOINT()+'/api/v1/verifiedid';

    //TODO make endpoint configured
    //return this._http.post('http://localhost:8180/VerifiedId', body, httpOptions).pipe(//timeout(5000),
    return this._http.post(path, body, httpOptions).pipe(
      catchError(this._handleError)
    );
  }

  private _handleError (error: Response | any) {
    let errorMessage: string;
    let errorCode = 'BAD_REQUEST';
    let status = error.status;
    let errorObj = error.error;
    let type: any;
    let result: string;
    if (errorObj != undefined) {
      errorMessage = errorObj.error
      result = errorObj['result'];
      if (errorObj["data"] && errorObj["data"]["type"]) {
        type = errorObj["data"]["type"];
      }
    }
    switch (status) {
      case 400:
        break;
      case 401:
        errorMessage = errorObj.error || 'Unauthorized';
        break;
      case 404:
        break;
      case 410:
        errorMessage = errorObj.error || 'RightID link has expired';
        errorCode = 'LINK_EXPIRED';
        break;
      case 502:
      case 503:
      case 504:
          errorMessage = errorObj.error || 'Server Error';
          errorCode = 'RETRY';
          break;
    }
    return throwError({errorMessage, errorCode, type, result});
  }

}
