import { Component, OnInit } from '@angular/core';
import { CameraFlowComponent } from '../camera-flow/camera-flow.component';
import { Router } from '@angular/router';
import { SelfieConfig } from './selfie-config';
import { DOCUMENT_MODE, CAPTURE_TYPE, DOCUMENT_SIDE } from '@fadv/id-camera';
import { LocalStorageService } from '@fadv/web-common';
import { TrackEventService } from '../shared/Services/trackEvent.service';
import { SharedService } from '../shared/Services/shared.service';
import { VENDOR } from '../identity-config';

declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'app-selfie',
  templateUrl: './selfie.component.html',
  styleUrls: ['./selfie.component.css']
})
export class SelfieComponent extends CameraFlowComponent implements OnInit {
  private selfieSteps: any;
  private sliderIndex: number;
  errorMessage: string;
  selfieNearImage: any;
  selfieFarImage: any;
  private startTime: any;
  private inviteKey: any;
  captureMode: any;
  webSdkVendor: string;
  VENDOR_MITEK = VENDOR.MITEK;

  constructor(public router: Router, public localStorage: LocalStorageService, public trackEventService: TrackEventService,
    public sharedService: SharedService) {
    super(trackEventService, localStorage, sharedService, router);
    this.captureMode = this.localStorage.getItem('capture_mode');
    this.webSdkVendor = this.localStorage.getItem('websdk_vendor');
  }

  ngOnInit() {
    this.startTime = new Date().getTime();
    this.selfieSteps = SelfieConfig.getSteps();
    this.inviteKey = this.localStorage.getItem("invite_key");
    this.sliderIndex = 0;
    if (this.isSingleSelfieSupported()) {
      this.localStorage.setItem("isSingleSelfieSupported", true);
      let index = this.selfieSteps.indexOf('selfie_far');
      if (index != -1) {
        this.selfieSteps.splice(index, 1);
      }
    }
    let retakesteps = this.localStorage.getItem("retakesteps");

    if (!!retakesteps) {
      let retakeSelfieNearStep = (retakesteps.indexOf('selfie-near') !== -1);
      let retakeSelfieFarStep = (retakesteps.indexOf('selfie-far') !== -1);

      if (!retakeSelfieNearStep && !retakeSelfieFarStep) {
        // Need to redirect to app confirmation if no selfie retake is required
        this.localStorage.removeItem('retakesteps');
        this.router.navigate(['/app-confirmation'], { queryParams: { invite_id: this.localStorage.getItem("invite_id")}, queryParamsHandling: 'merge' });
        return;
      }

      this.onInstructionNext({});

      if (!retakeSelfieNearStep) {
        let index = this.selfieSteps.indexOf('selfie_near');
        if (index != -1) {
          this.selfieSteps.splice(index, 1);
        }
        this.config = {
          captureType: CAPTURE_TYPE.SELFIE,
          enableFarSelfie: true,
          captureMode: this.captureMode
        }
      }
      if (!retakeSelfieFarStep) {
        let index = this.selfieSteps.indexOf('selfie_far');
        if (index != -1) {
          this.selfieSteps.splice(index, 1);
        }
      }
      this.sliderIndex = 1;
    }
    this.stepConfig = this.selfieSteps;
    this.setSlider(this.sliderIndex, 0);
  }

  onInstructionNext(obj) {
    super.onInstructionNext(obj);
    this.config = {
      captureType: CAPTURE_TYPE.SELFIE,
      enableFarSelfie: false,
      captureMode: this.captureMode
    }
  }

  onCameraNext(obj) {
    let currentStep = this.currentStep;
    super.onCameraNext(obj);
    let trackObj = {
      action_value: 'next clicked'
    }
    if (currentStep === 'selfie_near') {
      this.selfieNearImage = this.resultImage;
      this.addImageToStore('selfieNear', this.selfieNearImage);
      this.startTime = new Date().getTime();
      this.trackEventService.processTrackEvent('take near selfie', 'selfie', this.startTime, trackObj, true, true);

      let index = this.selfieSteps.indexOf('selfie_far');
      if (index != -1) {
        this.config = {
          captureType: CAPTURE_TYPE.SELFIE,
          enableFarSelfie: true,
          captureMode: this.captureMode
        }
      } else {
        this.router.navigate(['/selfie-review'], { queryParams: { invite_id: this.localStorage.getItem("invite_id")}, queryParamsHandling: 'merge' });
      }
    } else {
      this.selfieFarImage = this.resultImage;
      this.addImageToStore('selfieFar', this.selfieFarImage);
      this.trackEventService.processTrackEvent('take far selfie', 'selfie', this.startTime, trackObj, true, true);
      this.router.navigate(['/selfie-review'], { queryParams: { invite_id: this.localStorage.getItem("invite_id")}, queryParamsHandling: 'merge' });
    }
  }

  onCameraUIEvent(obj) {
    super.onCameraUIEvent(obj);
    this.errorMessage = obj.message;
    jQuery('#errorModal').modal('show');
  }

  setSlider(index, i?, j?): void {
    let seprator: string = '_';
    this.currentSlide = this.selfieSteps[index];
    this.currentStep = this.selfieSteps[index];
    if (i > -1) {
      this.currentSlide += `${seprator}${i}`;
    }
    if (j > -1) {
      this.currentSlide += `${seprator}${j}`;
    }
  }

  addImageToStore(imageType, image) {
    let inviteId = this.localStorage.getItem("invite_id");
    if (inviteId === undefined) {
      inviteId = '';
    }
    let key = 'rightId-' + inviteId + '-' + imageType;
    this.localStorage.setItem(key, image);
  }

  isSingleSelfieSupported() {
    let isSingleSelfieSupported = false;
    if (this.isIOS()) {
      if (/CriOS/i.test(navigator.userAgent) || /Firefox/i.test(navigator.userAgent)) {
        isSingleSelfieSupported = true;
      }
    }

    if (this.webSdkVendor == this.VENDOR_MITEK){
      isSingleSelfieSupported = true;
    }
    console.log("selfiecomponent isSingleSelfieSupported ", isSingleSelfieSupported);
    return isSingleSelfieSupported;
  }

  isIOS(): boolean {
    let iOS = /iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream;
    console.log("selfiecomponent isIOS() ", iOS);
    return iOS;
  }
}
