import { NgModule } from '@angular/core';
import { SignaturePadExtendedComponent } from './signature-pad-extended.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
@NgModule({
  declarations: [SignaturePadExtendedComponent],
  imports: [
    SignaturePadModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [SignaturePadExtendedComponent]
})
export class SignaturePadExtendedModule { }
