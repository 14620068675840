<div class="mainContainer container" *ngIf="formDisplay" [formGroup]="registerForm">
  <div class="row">
    <div class="col-md-12 col-xs-12 col-sm-12">
      <div class="header_txt_white">
        <h5>{{ 'APPLICANT_INFO_HEADER_RIGHTID' | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-10 col-sm-12 col-xs-12">
        <div class="group">
          <label class="placeholder_white custom_label">{{'FIRST_NAME' | translate}}</label>
          <input type="text" class="form-control" maxlength="50" formControlName="first_name" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 col-sm-12 col-xs-12">
        <div class="group">
          <label class="placeholder_white custom_label">{{'MIDDLE_NAME' | translate}}</label>
          <input type="text" class="form-control" maxlength="50" formControlName="middle_name" />
        </div>
      </div>
    </div>    
    <div class="row">
      <div class="col-md-10 col-sm-12 col-xs-12">
        <div class="group">
          <label class="placeholder_white custom_label">{{'LAST_NAME' | translate}}</label>
          <input type="text" class="form-control" maxlength="50" formControlName="last_name" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 col-sm-12 col-xs-12">
        <div class="group">
          <label class="placeholder_white custom_label">{{'EMAIL_ID' | translate}}</label>
          <input type="text" class="form-control" maxlength="50" formControlName="email"
            (blur)="validateEmail(registerForm.value.email)" (keyup)=validateEmailOnKeyUp(registerForm.value.email) />
          <!--
          <div class="custom-error error_new_ui" *ngIf="isEmailError">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="fa fa-exclamation-triangle"></i>OOPS!</li>
                <li class="message">{{emailErrorMessage}}</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true" (click)="closeEmailError()">
                <i class="fa fa-times"></i>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-4 col-sm-4 col-xs-4" style="padding-right: 5px;">
        <div class="group padv-dropdown">
          <label class="placeholder_white custom_label">{{'COUNTRY_CODE' | translate }}</label>
          <input type="text" class="form-control" maxlength="100" formControlName="countryCode" value="+"
            (focusout)="focusOutFunction(registerForm.value.countryCode)" />
          <!--
          <div class="custom-error error_new_ui countryCodeDropdownErr" *ngIf="codeErrorMsg">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="fa fa-exclamation-triangle"></i>OOPS!</li>
                <li class="message">Invalid country code.</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true" (click)="closeCodeError()">
                <i class="fa fa-times"></i>
              </div>
            </div>
          </div>
        -->
          <span class="highlight"></span>
          <ul [class.hide]="isNumberList" class="generic-dropdown-list countryCodeList">
            <li *ngFor="let citydata of countrycodelist" (mousedown)="enterCityInput(citydata)">
              <div>
                <span>{{citydata.disp_value}}</span>
              </div>
            </li>
          </ul>
          <span class="bar"></span>
        </div>
      </div>
      <div class="col col-md-6 col-sm-8 col-xs-8" style="padding-left: 5px;">
        <div class="group">
          <label class="placeholder_white custom_label">{{'PHONE_NUMBER' | translate }}</label>
          <input type="text" class="form-control" maxlength="50" formControlName="phone" (keyup)="keyupPhoneNumber"
            (keyup)="validatePhone(registerForm.value.phone)" pattern="^(?=.*[0-9])[- ()0-9]+$" />
          <!--
          <div class="custom-error error_new_ui" *ngIf="!isValidPhone">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="fa fa-exclamation-triangle"></i>OOPS!</li>
                <li class="message">Please enter valid phone number</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true" (click)="closePhoneError()">
                <i class="fa fa-times"></i>
              </div>
            </div>
          </div>
        -->
        </div>
      </div>
    </div>
    <div class="almost-bottom row">
      <button name="next" class="btn button btn-align m-t-15"
        [disabled]="!registerForm.valid || !enableNextButton || isInvalidEmailID"
        (click)="submitData(registerForm.value)"> {{'CONFIRM_NEXT' | translate}}</button>
    </div>
  </div>
</div>

<!-- Please Wait Modal - starts -->
<div class="modal fade custom_modal" id="pleaseWaitModal" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <div class="modal-body text-center">
          <div class="modal-title m-t-10" [innerHTML]="'PLEASE_WAIT_FOR_A_MOMENT' | translate "> </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>
  