import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {LocalStorageService} from '@fadv/web-common';
import {TrackEventService} from "../shared/Services/trackEvent.service";
import {SharedService} from "../shared/Services/shared.service";
import { TranslateService } from '../framework/i18n/translate.service';


@Component({
  selector: 'selfie-review',
  templateUrl: './selfie-review.component.html',
  styleUrls: ['./selfie-review.component.css']
})

export class SelfieReviewComponent implements OnInit {
  selfieImage: any;
  selfieImageType = 'selfieNear';
  inviteId: string;
  pageHeading: string;
  retakeButtonHeading: string;
  nextButtonHeading: string;
  startTime: any;

  constructor(public router: Router,
              public localStorage: LocalStorageService,
              public trackEventService: TrackEventService,
              public sharedService: SharedService,
              private translateService: TranslateService) {
    this.inviteId = this.localStorage.getItem('invite_id');
    this.pageHeading = translateService.instant('REVIEW_SELFIE');
    this.retakeButtonHeading = translateService.instant('RETAKE');
    this.nextButtonHeading = translateService.instant('CONFIRM_NEXT');
  }

  ngOnInit() {
    this.selfieImage = this.getImageFromStore(this.selfieImageType);
    this.startTime = new Date().getTime();
  }

  getImageFromStore(imageType) {
    const key = 'rightId-' + (this.inviteId || '') + '-' + imageType;
    return this.localStorage.getItem(key);
  }

  onConfirmNext($event) {
    this.localStorage.removeItem('retakesteps');
    this.trackEventService.processTrackEvent('review selfie', 'selfie', this.startTime, {action_value: 'selfie confirm clicked'}, true, true);
    this.router.navigate(['/app-confirmation'], { queryParams: { invite_id: this.inviteId}, queryParamsHandling: 'merge' });
  }

  onConfirmRetake($event) {
    this.trackEventService.processTrackEvent('review selfie', 'selfie', this.startTime, {action_value: 'retake clicked'}, true, true);
    const retakeSteps = this.localStorage.getItem('retakesteps') || [];
    retakeSteps.push('selfie-near');
    retakeSteps.push('selfie-far');
    this.localStorage.setItem('retakesteps', retakeSteps);
    this.router.navigate(['/app-selfie'], { queryParams: { invite_id: this.inviteId}, queryParamsHandling: 'merge' });
  }
}
