<div class="container-fluid">
  <ng-content></ng-content>
  <div class="row heading">
    <h3>{{'CHOOSE_DOCUMENT_TYPE' | translate}}</h3>
  </div>
  <div class="row padding16">
    &nbsp;
  </div>
  <div class="row padding16">
    <button class="btn button btn-select" name="gid"
      (click)="selectedType('gid')">{{'GOVERNMENT_ID_DOC' | translate}}</button>
  </div>
  <div *ngIf="showNonUsFlow === 'Yes'">
    <div class="row padding16">
      <button class="btn button btn-select" name="non_us_gid"
        (click)="selectedType('non_us_gid')">{{'GOVERNMENT_ID_DOC_WITHOUT_BARCODE' | translate}}</button>
    </div>
  </div>
  <div class="row padding16">
    <button class="btn button btn-select" name="passport"
      (click)="selectedType('passport')">{{'PASSPORT' | translate}}</button>
  </div>
  <div class="row padding16">
    &nbsp;
  </div>
  <div *ngIf="showAbortOptionLink" class="link">
      <a [routerLink]="[]" class="col offset-md-3 col-md-6 col-sm-12 col-lg-3"
        (click)="showAbortModal()">{{abortLinkLabel}}</a>
    </div>
  <!-- Do not show the Next button-->
  <div class="row padding16" *ngIf="showNext">
    <button class="btn button btn-align" name="next" (click)="next()"
      [disabled]="!enableNext">{{'NEXT' | translate}}</button>
  </div>
</div>

<!-- Abort Modal - starts -->
<div class="modal fade custom_modal" id="abortmodal" data-backdrop="static" role="dialog" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeAbortModal()" data-dismiss="modal">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title">{{ abortPopupMsg }}</h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="">
            <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
              (click)="processAbort()">{{'COMMONS_CONTINUE' | translate }}</button>
          </div>
          <div class="">
            <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
              (click)="closeAbortModal()">{{'COMMONS_GOBACK' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- Please Wait Modal - starts -->
<div class="modal fade custom_modal" id="pleaseWaitModal" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <div class="modal-body text-center">
          <div class="modal-title m-t-10" [innerHTML]="'PLEASE_WAIT_FOR_A_MOMENT' | translate "> </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>
  