import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/Services/shared.service';
import { TranslateService } from '../framework/i18n/translate.service';
import { LocalStorageService } from '@fadv/web-common';

declare var jQuery : any

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  public errorCode: string;
  public errorMessage: string;
  public link: string;
  public oppsMessage: string;
  constructor(private sharedService: SharedService, private transalteService: TranslateService, private localStorage: LocalStorageService) { }

  ngOnInit() {
    this.errorCode = this.sharedService.getErrorCode();
    this.errorMessage = this.sharedService.getErrorMessage();
    let invite_id = this.sharedService.getInviteIdToken();
    if (invite_id == undefined){
      invite_id = this.localStorage.getItem('invite_id');
    }

    if (invite_id === undefined) {
      invite_id = this.sharedService.getInviteIdFromQueryParam();
    }

    console.log('Error page invite id -----------------------' + invite_id);
    if (this.errorCode === 'BAD_REQUEST'){
      if (invite_id != undefined){
        this.link = "/invite/" + invite_id;
        this.oppsMessage = this.transalteService.instant('ERROR_MSG_BAD_REQUEST_WITH_LINK',this.link);
      } else {
        this.oppsMessage = this.transalteService.instant('ERROR_MSG_BAD_REQUEST');
      }
    }
    if (this.errorCode === 'CAMERA_PERMISSION'){
      if (invite_id != undefined){
        this.link = "/invite/" + invite_id;
        this.oppsMessage = this.transalteService.instant('CAMERA_PERMISSION_BLOCKED', this.link);
      } 
    }
    if (this.errorCode === '404'){
      if (invite_id != undefined){
        this.link = "/invite/" + invite_id;
        this.oppsMessage = this.transalteService.instant('ERROR_MSG_NOT_FOUND_WITH_LINK',this.link);
      } else {
        this.oppsMessage = this.transalteService.instant('ERROR_MSG_NOT_FOUND');
      }
    }
    var existingStyles = jQuery('#customStyles');
    if (existingStyles[0].innerHTML === undefined || existingStyles[0].innerHTML === '') {
      var customStyle = '.logo{width:150px!important;height:45px!important;background:url(./assets/img/rightid_logo.png) no-repeat!important;background-size:145px!important;background-position:10px 10px!important}';
      jQuery('#customStyles').html(customStyle);
    }
  }

}
