export const LANG_ES_NAME = 'es';

export const LANG_ES_TRANS = {
    "GOVERNMENT_ID_DOC": "Licencia de conducir de los EE. UU. / Identificación del estado",
    "GOVERNMENT_ID_DOC_WITHOUT_BARCODE": "Licencia de conducir no estadounidense / ID estatal",
    "PASSPORT": "Pasaporte",
    "NEXT": "Siguiente",
    "WELCOME": "Bienvenido",
    "WELCOME_TO": "Bienvenido %0",
    "LANDING_MSG": "Vamos a llevarlo a través de los siguientes pasos para validar su identidad. Sólo debe tomar unos minutos.",
    "CHOOSE_ID_TYPE_LANDING_MSG": "Elija el tipo de documento de identidad que desea utilizar",
    "ID_LANDING_MSG": "Captura fotos de la parte delantera y trasera de tu ID",
    "SELFIE_LANDING_MSG": "Tomaremos dos fotos autofotos para la verificación.",
    "THATS_IT": "¡Eso es!",
    "LET_STARTED": "EMPECEMOS",
  
    "APPLICANT_INFO_HEADER_RIGHTID" : "Please enter your name exactly as it appears on your ID document, including middle name or initial.",
    "FIRST_NAME":"Nombre de pila",
    "MIDDLE_NAME":"Segundo nombre",
    "LAST_NAME": "Apellido",
    "EMAIL_ID":"Identificación de correo:",
    "COUNTRY_CODE":"Código de país",
    "PHONE_NUMBER":"Número de teléfono",   
    "ID_FRONT_INSTR_MSG_1": "Cómo tomar una foto de su licencia de conducir / identificación estatal",
    "ID_INSTR_MSG_2": "Coloque su licencia de conducir / identificación estatal para garantizar que sea legible y sin deslumbramiento",
    "PASSPORT_INSTR_MSG_1": "Cómo tomar una foto de su pasaporte",
    "PASSPORT_INSTR_MSG_2": "Coloque su pasaporte en forma de L para garantizar la legibilidad y no deslumbrar",
    "ID_BACK_INSTR_MSG_1": "Cómo tomar una foto de la parte posterior de su licencia de conducir / identificación estatal",
    "CONFIRM_STATIC_CONTENT": "Confirmar contenido estático",
    "CAMERA_STATIC_CONTENT": "Contenido estático de la cámara",
    "CONFIRM_PICTURE": "Confirmar imagen",
    "RETAKE": "VOLVER A TOMAR",
    "CONFIRM_NEXT": "SIGUIENTE",
    "ERROR_TITLE": "¡Error!",
    "GOT_IT": "LO TENGO",
  
    "SELFIE_INSTR_MSG_1": "Estaremos tomando dos fotos autofotos.",
    "SELFIE_INSTR_MSG_2": "Por favor continúe tomando dos fotos de su cara en diferentes distancias",
    "SELFIE_SINGLE_INSTR_MSG_1": "Nosotras tomaremos una foto selfie",
    "SELFIE_SINGLE_INSTR_MSG_2": "Continúa tomando una foto de tu cara",
  
    "CONFIRMATION_ID_SUBMITTED":"ID enviado",
    "CONFIRMATION_SELFIE_SUBMITTED":"Selfie Enviado",
    "CONFIRMATION_LIVENESS_SELFIE_SUBMITTED":"Liveness Selfie Enviado",
    "WAIT_MSG":"Procesando .. por favor espere...",
    "DECISION_PROCESSING":"Procesamiento de decisiones",
    "ERROR_NOTIFY_MSG":"Parece un problema?",
    "RESULTS": "RESULTADOS: %0",
    "RETRY_ATTEMPT_MSG": "Reintentar intento: %0",
    "SUCCESS_MSG":"Verificado con éxito",
    "ID_DOC_VERIFIED": "Documento de identidad verificado",
    "PASSPORT_VERIFIED": "Pasaporte verificado",
    "SELFIE_VERIFIED": "Autofoto verificado",
    "LIVENESS_SELFIE_VERIFIED": "Liveness Selfie Verificado", 
    "UNAUTHORIZED_MSG": "El documento de ID dado no es compatible, utilice un documento de ID alternativo",   

    "RESULT_THANK_YOU": "¡Gracias! Parece que tenemos todo lo que necesitamos ahora.",
    "WE_WILL_GET_BACK": "Uno de nuestros representantes se pondrá en contacto con usted pronto.",

    "ERROR_MSG_BAD_REQUEST":"¡Uy! Por favor, compruebe la URL. Parece que la URL no es válida o el servicio no está disponible temporalmente.",
    "ERROR_MSG_BAD_REQUEST_WITH_LINK":"¡Uy! Por favor, compruebe la URL. Parece que la URL no es válida o el servicio no está disponible temporalmente. <a href='%0'>Haga clic aquí para relanzar</a>.",
    "ERROR_MSG_LINK_EXPIRED":"Este enlace ya no está activo.",
    "ERROR_MSG_NOT_FOUND":"Este enlace no esta disponible.",
    "ERROR_MSG_NOT_FOUND_WITH_LINK":"Este enlace no esta disponible.  <a href='%0'>Haga clic aquí para relanzar</a>.",
    "ERROR_MSG_INVITE_FAILURE":"El servicio no está disponible actualmente, inténtalo de nuevo más tarde.",
    "ERROR_MSG_VERIFIED_PASSED":"Gracias, ya has sido verificado.",
    "CONSENT_AUTHORIZATION_HEADER" : "Authorization",
    "COMMONS_NEXT" : "NEXT",
    "CONSENT_AUTHORIZATION_CONTENT" : "<p>The individual understands that, for security, identity verification, and fraud-prevention purposes, %0 (“the Company”) sometimes employs biometric technology to verify identity, to prevent fraud, and to control and limit access to the Company’s services and property.  In furtherance of these security, identity verification, and fraud-prevention purposes, the Company may collect biometric identifiers on individuals.  Under some applicable laws, biometric identifiers include a retina or iris scan, fingerprint, voiceprint, or record of hand or face geometry.</p><p>The biometric identifiers will be used exclusively for security, identity verification, and fraud prevention in connection with your access to the Company’s services or property.  The Company will not sell or lease your biometric identifiers unless required by law.</p><p>Please contact %1 if you have any questions.</p><p class=\"secondli\">I acknowledge that I have read and understand this notice regarding the collection and use of biometric identifiers.  I consent, as a condition of receiving or being considered to receive the Company’s services or access to its property, to providing biometric identifiers for the limited purpose of security, identity verification, and fraud prevention.</p>",
    "PLEASE_WAIT_PAGE_IS_BEING_REDIRECTED" : "Thank you! We are processing your request, please wait…",
    "CHOOSE_DOCUMENT_TYPE" :  "Choose the type of ID Document you'd like to use",
    "ERROR_MSG_DESKTOP_TRUE" : "Lo sentimos, esta capacidad no es compatible con un navegador portátil o de escritorio. Deberá hacer esto en un dispositivo móvil como un teléfono inteligente, utilizando el mismo enlace.",
    "OPTOUT_MODAL_HEADER_1" : "Haga clic en OPT-OUT o haga clic en GO BACK para continuar completando la solicitud.",
    "ABORT_MODAL_HEADER_1" : "Haga clic en ABORT o haga clic en GO BACK para continuar completando la solicitud.",
    "COMMONS_OPTOUT" : "OPT-OUT",
    "COMMONS_GOBACK" : "GO BACK",
    "ERROR_MSG_OPT_OUT" : "Has optado por no participar. Gracias.",
    "DONT_CONTINUE" : "No quiero continuar",
    "CONSENT_SIGN_TEXT" : "Authorization Signature",
    "CONSENT_SIGN_TEXT_1" : " * Use your mouse or touch.",
    "SIGN_MODAL_BOTTOM_1" : "%0",
    "SIGN_MODAL_BTN_3" : "CLEAR",
    "SIGN_MODAL_BTN_1" : "SIGN",
    "COMMONS_CANCEL" : "CANCEL",
    "PLEASE_WAIT_FOR_A_MOMENT" : "Please wait for a moment.",
    "BUTTON_OK": "OK",
    "CAMERA_PERMISSION_BLOCKED": "La Cámara está bloqueada, abra la configuración de la aplicación del dispositivo y desbloquee la Cámara. <a href='%0'>Haga clic aquí para relanzar</a>.",
    "RETAKE_LIMIT_EXCEEDED": "Límite de retoma excedido.",
    "BUTTON_DECLINE" : "DISMINUCIÓN",
    "DECLINE_ESIGNATURE_SIGN_TEXT" : "Rechazar firma electrónica",
    "DECLINE_ESIGNATURE_CONFIRM_TEXT": "Se requiere su firma para completar el proceso de identificación. estas seguro que quieres declinar?",
    "DECLINE_ESIGNATURE_TEXT" : "Póngase en contacto con la Compañía si aún está interesado en continuar.",
    "BUTTON_GO_BACK": "REGRESA",
    "ID_SELECT_ABORT_OPTION": "No tengo uno de esos",
    "RESULT_CONFIRM_ABORT_OPTION": "Déjame probar algo más",
    "COMMONS_ABORT" : "ABORT",
    "COMMONS_CONTINUE": "CONTINUE",
    "ID_DO_HAVE_BARCODE1" : "Tengo un código de barras en el parte",
    "ID_DO_HAVE_BARCODE2" : "posterior de mi identificación",
    "ID_DO_NOT_HAVE_BARCODE1" : "No tengo nada el reverso",
    "ID_DO_NOT_HAVE_BARCODE2" : " de mi identificación",
    "COMMONS_SKIP": "SKIP",
    "RID_VIDEO_LABEL_1": "Click Here",
    "RID_VIDEO_LABEL_2": " for a video tutorial",
    "RID_RESULT_1": "ID Verification Completed",
    "RID_RESULT_2": "Unable to verify ID",
    "RID_RESULT_3": "Recapture Required",
    "RID_RESULT_4": "Error",
    "RIGHTID_ERROR_10": "The quality of the image captured is not excellent, and please re-scan the IDs for better results",
  "RIGHTID_ERROR_12": "The quality of the image captured is not excellent, and please re-scan the IDs for better results",
  "RIGHTID_ERROR_14": "The quality of the image captured is not excellent, and please re-scan the front side of the ID for better results",
  "RIGHTID_ERROR_16": "The quality of the image captured is not excellent, and please re-scan the back side of the ID for better results",
  "RIGHTID_ERROR_20": "The quality of the image captured is not excellent, and please recapture the selfie again for better results",
  "RIGHTID_ERROR_22": "The quality of the image captured is not excellent, and please recapture the front side of the ID and a selfie for better results",
  "RIGHTID_ERROR_24": "The quality of the image captured is not excellent, and please recapture the back side of the ID and a selfie for better results",
  "RIGHTID_ERROR_50": "We do not support the ID provided by you. Would you please use a different ID listed in the user instructions and try again?",
  "RIGHTID_ERROR_52": "We noticed the ID provided by you has expired. Would you please use a different ID listed in the user instructions and try again?",
  "DOCUMENT_FRONT_NOT_PROVIDED" : "Document Front not provided.",
  "DOCUMENT_BACK_NOT_PROVIDED" : "Document Back not provided.",
  "SELFIE_NEAR_NOT_PROVIDED" : "Selfie near not provided.",
  "SELFIE_LIVENESS_NOT_PROVIDED" : "Selfie liveness not provided."
  };
