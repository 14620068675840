import { Component, OnInit } from '@angular/core';
import { CameraConfig, CAPTURE_TYPE, DOCUMENT_MODE, DOCUMENT_SIDE } from '@fadv/id-camera'
import { TrackEventService } from '../shared/Services/trackEvent.service';
import { LocalStorageService } from '@fadv/web-common';
import { SharedService } from '../shared/Services/shared.service';
import { Router } from '@angular/router';

declare var window: any;
declare var $: any;
@Component({
  selector: 'app-camera-flow',
  templateUrl: './camera-flow.component.html',
  styleUrls: ['./camera-flow.component.css']
})
export class CameraFlowComponent implements OnInit {
  currentStep: any;
  stepConfig: any;
  currentStepIndex: any;
  currentSlide: any;
  config: CameraConfig;
  resultImage: any;
  captureMode: any;

  constructor(public trackEventService: TrackEventService,
    public localStorage: LocalStorageService, public sharedService: SharedService, public router: Router) {
    console.log("****** --- Initializing Camera Component...");
    this.stepConfig = ['instruction', 'camera', 'confirm'];
    this.currentStep = 'instruction';
    this.currentStepIndex = 0;
    this.resultImage = 'abc';
    this.captureMode = this.localStorage.getItem('capture_mode');
    this.config = {
      documentType: DOCUMENT_MODE.LICENSE,
      documentSide: DOCUMENT_SIDE.FRONT,
      captureType: CAPTURE_TYPE.DOCUMENTID,
      enableFarSelfie: false,
      captureMode: this.captureMode
    }
  }

  ngOnInit() {
  }

  onInstructionNext(obj) {
    console.log('----- in Camera-flow-Instruction-Next ' + obj.message);
    let that = this;
    if (window.navigator != undefined && window.navigator.permissions){
      window.navigator.permissions.query({name: 'camera'})
        .then((permissionObj) => {
            console.log(permissionObj.state);
            if (permissionObj.state === 'denied') {
              this.redirectToError();
            } else { 
              this.onCameraPermissionSuccess();
            }
          })
        .catch((error) => {
          console.log('Got device permission error :', error);
          that.checkUserMedia();
        })      
    } else {     
      this.checkUserMedia();
    }
  }

  checkUserMedia(){
    let that = this;
    navigator.mediaDevices.getUserMedia({ audio: false, video: true })
    .then(function(stream) {
      /* use the stream */
      console.log('Camera allowed.');
      that.onCameraPermissionSuccess();
    })
    .catch(function(err) {
      /* handle the error */
      console.log('Camera blocked/error.' + err.name);
      that.redirectToError();
     });
  }

  redirectToError(){
    this.sharedService.setErrorCodeAndMsg('CAMERA_PERMISSION', 'Camera is blocked.');
    this.trackEventService.updateTrackEvent('camera permission error', {stage_name: 'camera', action_value: 'next clicked'}, true, true);
    this.router.navigate(['/app-error'], { queryParams: { invite_id: this.localStorage.getItem('invite_id')}, queryParamsHandling: 'merge' });
  }

  onCameraPermissionSuccess(){
    console.log('----- in Camera-permission-Next ');
    this.currentStepIndex++;
    this.currentStep = this.stepConfig[this.currentStepIndex];
    this.setFlowSlider(this.currentStepIndex, 0);
  }

  onCameraNext(obj) {
    console.log('----- in Camera-flow-Camera-Next ' + (obj.resultImage.length));
    this.trackEventService.updateTrackEvent('show captured image', {stage_name: 'camera', action_value: 'next clicked'}, true, false);
    this.resultImage = obj.resultImage;
    this.currentStepIndex++;
    this.currentStep = this.stepConfig[this.currentStepIndex];
    this.setFlowSlider(this.currentStepIndex, 0);
  }

  onCameraTimeout(obj) {
    console.log('----- in Camera-flow-Timeout ' + obj.message);
    this.trackEventService.updateTrackEvent('camera timeout', {stage_name: 'camera', action_value: 'auto timeout'}, true, false);
  }

  onConfirmNext(obj) {
    console.log('----- in Camera-flow-Confirm-Next ' + obj.message);
    this.trackEventService.updateTrackEvent('captured image confirmed', {stage_name: 'camera', action_value: 'next clicked'}, true, true);
    this.currentStepIndex++;
    this.currentStep = this.stepConfig[this.currentStepIndex];
    this.setFlowSlider(this.currentStepIndex, 0);
  }

  onConfirmRetake(obj) {
    console.log('----- in Camera-flow-Confirm-Retake ' + obj.message);
    this.trackEventService.updateTrackEvent('retake picture', {stage_name: 'camera', action_value: 'retake clicked'}, true, false);
    this.currentStepIndex--;
    this.currentStep = this.stepConfig[this.currentStepIndex];
    this.setFlowSlider(this.currentStepIndex, 0);
  }

  setFlowSlider(index, i?, j?): void {
    let seprator: string = '_';
    this.currentSlide = this.stepConfig[index];
    this.currentStep = this.stepConfig[index];
    if (i > -1) {
      this.currentSlide += `${seprator}${i}`;
    }
    if (j > -1) {
      this.currentSlide += `${seprator}${j}`;
    }
  }

  onCameraAborted(obj) {
    console.log('----- in Camera-flow-aborted ' + obj.message);
    let trackObj = {};
    this.addProperties(trackObj, obj);
    console.log("onCameraAborted trackObj ", trackObj);

    this.trackEventService.updateTrackEvent('camera closed', {stage_name: 'camera', action_value: 'close clicked'}, true, false);
    this.currentStepIndex--;
    this.currentStep = this.stepConfig[this.currentStepIndex];
    this.setFlowSlider(this.currentStepIndex, 0);
  }

  onCameraUIEvent(obj) {
    console.log('----- in Camera-flow-ui event ' + obj.code + " " + obj.message);
    let trackObj = {};
    this.addProperties(trackObj, obj);
    console.log("onCameraUIEvent trackObj ", trackObj);

    this.trackEventService.updateTrackEvent('show error', {stage_name: 'camera', action_value: 'ui event'}, true, false);
  }

  onCameraError(obj) {
    console.log('----- in Camera-flow-ui onCameraError ', obj);
    let trackObj = {};
    this.addProperties(trackObj, obj);
    console.log("onCameraUIEvent onCameraError ", trackObj);

    this.trackEventService.updateTrackEvent('camera error', {stage_name: 'camera', action_value: 'ui event'}, true, true);
  }

  addProperties(trackObj, obj) {
    if (typeof obj === 'object') {
      Object.keys(obj).forEach(k => {
        if (obj[k].constructor.name === 'DOMException') {
          trackObj['code'] = obj[k]['code'];
          trackObj['message'] = obj[k]['message'];
          trackObj['name'] = obj[k]['name'];
        } else if (typeof obj[k] === 'object') {
          this.addProperties(trackObj, obj[k]);
        } else {
          trackObj[k] = obj[k];
        }
      });
    } else if (typeof obj === 'string') {
      trackObj['msg'] = obj;
    }
  }
}
