export class SelfieConfig {

    static steps: any = [
        'instructions',
        'selfie_near',
        'selfie_far'
    ];

    static getSteps(): any {
        return SelfieConfig.steps;
    }    
}
