import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDConfig } from './idconfig';
import { CameraFlowComponent } from '../camera-flow/camera-flow.component';
import { DOCUMENT_MODE, DOCUMENT_SIDE, CAPTURE_TYPE } from '@fadv/id-camera';
import { LocalStorageService } from '@fadv/web-common';
import { TrackEventService } from '../shared/Services/trackEvent.service';
import { SharedService } from '../shared/Services/shared.service';
import { VENDOR } from '../identity-config';

declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'app-id',
  templateUrl: './id.component.html',
  styleUrls: ['./id.component.css']
})
export class IDComponent extends CameraFlowComponent implements OnInit {
  private idSteps: any;
  private sliderIndex: number;
  isFormInit: boolean;
  selectedIDType: string;
  errorMessage: string;
  idFrontImage: any;
  idBackImage: any;
  private startTime: any;
  private inviteKey: any;
  captureMode: any;
  webSdkVendor: string;
  VENDOR_MITEK  = VENDOR.MITEK;
  formerSlide: any;

  constructor(public router: Router, private _fb: UntypedFormBuilder, public localStorage: LocalStorageService,
    public trackEventService: TrackEventService, public sharedService: SharedService) {
    super(trackEventService, localStorage, sharedService, router);
    this.captureMode = this.localStorage.getItem('capture_mode');
    this.webSdkVendor = this.localStorage.getItem('websdk_vendor');
    console.log('Web Vendor -' + this.webSdkVendor);
  }

  ngOnInit() {
    this.startTime = new Date().getTime();
    this.idSteps = IDConfig.getSteps();
    this.sliderIndex = 0;
    let retakesteps = this.localStorage.getItem("retakesteps");
    this.selectedIDType = this.localStorage.getItem("selectedIDType");
    this.inviteKey = this.localStorage.getItem("invite_key");
    this.setConfig();

    if (!!retakesteps) {
      let retakeIDFrontStep = (retakesteps.indexOf('id-front') !== -1);
      let retakeIDBackStep = (retakesteps.indexOf('id-back') !== -1);

      if (!retakeIDFrontStep && !retakeIDBackStep) {
        this.afterIdCapture();
        return;
      }
      if (!retakeIDFrontStep) {
        let index = this.idSteps.indexOf('instruction_frontId');
        if (index != -1) {
          this.idSteps.splice(index, 3);
        }
        if (this.selectedIDType !== 'non_us_gid') {
          this.config = {
            documentType: DOCUMENT_MODE.LICENSE,
            documentSide: DOCUMENT_SIDE.BACK,
            captureType: CAPTURE_TYPE.DOCUMENTID,
            enableFarSelfie: false,
            captureMode: this.captureMode
          }
        }
      }
      if (!retakeIDBackStep && this.selectedIDType !== 'non_us_gid') {
        let index = this.idSteps.indexOf('instruction_backId');
        if (index != -1) {
          this.idSteps.splice(index, 3);
        }
      }
    }
    console.log("idSteps :", this.idSteps);
    this.stepConfig = this.idSteps;
    this.setSlider(this.sliderIndex, 0);
    this.isFormInit = true;
  }

  setConfig() {
    if (this.selectedIDType === 'gid' || this.selectedIDType === 'non_us_gid') {
      this.config = {
        documentType: DOCUMENT_MODE.LICENSE,
        documentSide: DOCUMENT_SIDE.FRONT,
        captureType: CAPTURE_TYPE.DOCUMENTID,
        enableFarSelfie: false,
        captureMode: this.captureMode
      }
    } else {
      this.config = {
        documentType: DOCUMENT_MODE.PASSPORT,
        documentSide: DOCUMENT_SIDE.FRONT,
        captureType: CAPTURE_TYPE.DOCUMENTID,
        enableFarSelfie: false,
        captureMode: this.captureMode
      }
    }
  }

  setSlider(index, i?, j?): void {
    let seprator: string = '_';
    this.currentSlide = this.idSteps[index];
    this.currentStep = this.idSteps[index];
    if (i > -1) {
      this.currentSlide += `${seprator}${i}`;
    }
    if (j > -1) {
      this.currentSlide += `${seprator}${j}`;
    }
  }

  onConfirmNext(obj) {
    let trackObj = {
      action_value: 'next clicked'
    };
    if (this.currentStep === 'confirm_frontId') {
      this.idFrontImage = this.resultImage;
      let imageType = '';
      if (this.selectedIDType === 'gid' || this.selectedIDType === 'non_us_gid') {
        imageType = 'idFront';
      } else {
        imageType = 'passport';
      }
      this.startTime = new Date().getTime();

      this.addImageToStore(imageType, this.idFrontImage);
      this.trackEventService.processTrackEvent('image type ' + imageType, 'id info', this.startTime, trackObj, true, true);
    } else if (this.currentStep === 'confirm_backId') {
      this.idBackImage = this.resultImage;
      let imageType = 'idBack';
      this.addImageToStore(imageType, this.idBackImage);
      this.trackEventService.processTrackEvent('image type ' + imageType, 'id info', this.startTime, trackObj, true, true);
    }

    let index = this.idSteps.indexOf('instruction_backId');
    if (this.selectedIDType === 'gid' && this.currentStep === 'confirm_frontId' && index !== -1) {
      this.config = {
        documentType: DOCUMENT_MODE.LICENSE,
        documentSide: DOCUMENT_SIDE.BACK,
        captureType: CAPTURE_TYPE.DOCUMENTID,
        enableFarSelfie: false,
        captureMode: this.captureMode
      }
      this.sliderIndex += 3;
      this.currentStepIndex += 1;
      this.setSlider(this.sliderIndex, 0);
    } else if (this.selectedIDType === 'non_us_gid') {
      this.formerSlide = this.currentSlide;
      this.currentSlide = 'showBarcodeDecisionPage';
    } else {
      this.afterIdCapture();
    }
  }

  private afterIdCapture() {
    this.router.navigate(['/app-selfie'], { queryParams: { invite_id: this.localStorage.getItem("invite_id")}, queryParamsHandling: 'merge' });
  }

  onCameraUIEvent(obj) {
    super.onCameraUIEvent(obj);
    this.errorMessage = obj.message;
    jQuery('#errorModal').modal('show');
  }

  addImageToStore(imageType, image) {
    let inviteId = this.localStorage.getItem("invite_id");
    if (inviteId === undefined) {
      inviteId = '';
    }
    let key = 'rightId-' + inviteId + '-' + imageType;
    this.localStorage.setItem(key, image);
  }

  selectedType(barcodeAvailability) {
    this.currentSlide = this.formerSlide;
    this.trackEventService.updateTrackEvent('id ' + barcodeAvailability + ' selected', {stage_name: 'id select', action_value: 'next clicked'}, true, true);
    if (barcodeAvailability === 'do_not_have_barcode') {
      this.selectedIDType = 'non_us_gid';
      this.localStorage.setItem("selectedIDType", this.selectedIDType);
      this.afterIdCapture();
    }
    if (barcodeAvailability === 'do_have_barcode') {
      this.selectedIDType = 'gid';
      this.localStorage.setItem("selectedIDType", this.selectedIDType);
      let retakesteps = this.localStorage.getItem("retakesteps");
      if (!!retakesteps) {
        let retakeIDBackStep = (retakesteps.indexOf('id-back') !== -1);
        if (!retakeIDBackStep) {
          retakesteps.push('id-back');
        }
      }
      this.currentStep = 'confirm_frontId';
      let option = {};
      this.onConfirmNext(option);
    }
  }
}
