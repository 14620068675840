import { Injectable, Inject } from '@angular/core';
import { TRANSLATIONS } from './translations';

@Injectable()
export class TranslateService {
	private _currentLang: string;

	public get currentLang() {
		return this._currentLang;
	}

	headerOptions: any;

	// inject our translations
	constructor(@Inject(TRANSLATIONS) private _translations: any,) {

	}

	public use(lang: string): void {
		// set current language
		this._currentLang = lang;
	}

	public translate(key: string): string {
		// private perform translation
		let translation = key;

		if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
			return this._translations[this.currentLang][key];
		}
		return translation;
	}

	public instant(key: string, words?: string | string[]) { // add optional parameter
		const translation: string = this.translate(key);
		if (!words) return translation;

		return this.replace(translation, words); // call replace function
	}

	public replace(word: string = '', words: string | string[] = '') {
		let translation: string = word;
		let PLACEHOLDER = '%';
		const values: string[] = [].concat(words);
		values.forEach((e, i) => {
			translation = translation.replace(PLACEHOLDER.concat(<any>i), e);
		});

		return translation;
	}
}
