import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IdentityConfig } from '../identity-config';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class IdentityPreCheckService {
  isValidToken: Object = {};
  private trackObj: Object;
  constructor(private _http: HttpClient, private _router: Router) {  }

  validateToken(token: string): Observable<any> {
    this.trackObj = {
      invite_key: token
    };

    return this._http
                .get(IdentityConfig.API_ENDPOINT()+ `/api/web/invite/${token}` + '?d=' + (new Date()).getTime())
                .pipe(
                  map(res => this._extractData(res)),
                  map(res => this._doAction(res)),
                  catchError(this._handleError));
  }

  getAccountInfo(inviteType: string, accountId: string, accountTag: string, inviteSource: string, userPrefCountryCode: string, userPrefLanguageCode: string): Observable<any> {
   console.log("Inside getAccountInfo method");
    return this._http
                .get(IdentityConfig.API_ENDPOINT()+ `/api/web/account/${inviteType}/${accountId}/${accountTag}` + '?source=' + `${inviteSource}` + '&country_cd=' + userPrefCountryCode + '&language_cd=' + userPrefLanguageCode)
                .pipe(
                  map(res => this._extractData(res)),
                  map(res => this._doAction(res)),
                  catchError(this._handleError));
  }

  private _extractData( res: Object ) {
    return (res) || { };
  }

  private _doAction(response: Object) {
    console.log("identity-pre-check.service.ts response ", response);
    return response;
  }

  private _handleError (error: Response | any) {
    let errorMessage: string;
    let errorCode = 'BAD_REQUEST';
    switch (error.status) {
      case 400:
        break;
      case 401:
        errorMessage = error.error || 'Unauthorized';
        break;
      case 404:
        errorCode="404";
        break;
      case 410:
        errorMessage = error.error || 'RightID link has expired';
        errorCode = 'LINK_EXPIRED';
    }
    return throwError({errorMessage, errorCode});
  }
}
