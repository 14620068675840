import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service'; // our translate service

@Pipe({
    name: 'translate'
   // pure: false // impure pipe, update value when we change language
})

export class TranslatePipe implements PipeTransform {

	constructor(private _translate: TranslateService) { }

	public use(lang: string): void {
		// set current language
		this._translate.use(lang);
	}	
	transform(value: string, args?: any[]): any {
		if (!value) return;
		return this._translate.instant(value, args);
	}
}
