
export enum ApplicantionConstants {
    APPLICANT = 'Applicant',
    ACCEPTED = 'ACCEPTED'
};
export enum Result {
    Verified = 'Verified',
    Error = 'Error',
    Retake = 'Retake',
    NotVerified = 'Not Verified',
    Unauthorized = 'Unauthorized',
    Expired = 'Expired'
};
export class AppConfig {
    static API_ENDPOINT(): string {
        // return 'https://id-qa.fadv.com';
        return '';
    }

    static getDefaultLanguage(): string {
        return 'en';
    }

    static getDiplayLanguageList(): Object {
        let diplayLanguageList = {
            'en': 'English',
            'es': 'Español',
        };
        return diplayLanguageList;
    }

}
