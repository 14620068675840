import { Component, OnInit, ViewChildren, QueryList, ViewChild, ElementRef } from '@angular/core';

import { Router } from '@angular/router';
import { LocalStorageService } from '@fadv/web-common';
import { TrackEventService } from '../shared/Services/trackEvent.service';
import { SharedService } from '../shared/Services/shared.service';
import { DatePipe } from '@angular/common';
import { SignaturePadExtendedComponent } from '@fadv/signature-pad-extended';
import { TranslateService } from '../framework/i18n/translate.service';
import { ApplicantStatus } from '../identity-config';

declare var $: any;
@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.css']
})
export class ConsentComponent implements OnInit {
  errorMessage: string;
  consent_date: Date;
  companyName: string;
  consentHTML: string;
  displayLabels: any;
  signaturePadNote: any;
  confirmSignatureText: string;
  signatureValue: any;
  disableSignCleanButton: boolean = true;
  consentForm: any;
  declineESigConfirmText: string;
  declineESigCText: string;
  redirectURL: string;
  useFontBasedSignature: boolean = false;
  signatureFont: string = '';
  signatureLabel: string = '';
  signatureInitialValue: string = '';

  @ViewChildren('signaturePadExtendedContainer') public signaturePadExtendedContainer: QueryList<SignaturePadExtendedComponent>;
  @ViewChild('signatureActionsBar', { static: true }) signatureActionsBar: ElementRef;
  public confirmSignatureActions = false;
  public signatureActions = true;
  public confirmSignatureFlag = false;

  constructor(private router: Router, private localStorage: LocalStorageService,
    private trackEventService: TrackEventService, private sharedService: SharedService, private datePipe: DatePipe,
    private ts: TranslateService) { }

  ngOnInit() {
    this.errorMessage = "";
    this.companyName = this.localStorage.getItem("companyName");
    this.consentForm = this.localStorage.getItem("consent_form");
    this.consentHTML = this.consentForm['consent_text'];
    this.signatureValue = '';
    this.disableSignCleanButton = true;
    this.declineESigConfirmText = this.localStorage.getItem('decline_esignature_confirm_text');
    this.declineESigCText = this.localStorage.getItem('decline_esignature_text');
    this.redirectURL = this.localStorage.getItem("redirect_url");

    if (this.declineESigConfirmText === undefined || this.declineESigConfirmText === ''){
      this.declineESigConfirmText = this.ts.translate('DECLINE_ESIGNATURE_CONFIRM_TEXT');
    }
    if (this.declineESigCText === undefined || this.declineESigCText === ''){
      this.declineESigCText = this.ts.translate('DECLINE_ESIGNATURE_TEXT');
    }

    this.useFontBasedSignature = this.localStorage.getItem('use_font_based_signature') === 'YES';
    console.log("consent.component useFontBasedSignature ", this.useFontBasedSignature);
    if (this.useFontBasedSignature) {
      this.signatureFont = this.localStorage.getItem('SIGNATURE_FONT');
      this.signatureLabel = this.ts.translate('FONT_BASED_SIGNATURE');
      let firstName = this.localStorage.getItem("first_name");
      let lastName = this.localStorage.getItem("last_name");
      this.signatureInitialValue = `${firstName} ${lastName}`;
    }
  }

  private readonly ACCEPTED = 'ACCEPTED';
  private readonly DECLINED = 'DECLINED';

  public ngAfterViewInit() {
    this.confirmSignatureActions = false;
    this.signatureActions = true;
    this.signaturePadExtendedContainer.first.setPenColor("black");
  }

  closeDecline(){
    $('#declineEsignature_Modal').modal('hide');
    $('.modal-backdrop').remove();
  }

  showDeclineESignaturePopup(){
    $('#declineEsignature_Modal').modal('show');
  }
  
  processPostDecline(){
    let inviteId = this.localStorage.getItem("invite_id");

    let data = {
      'status' : 'CONSENT_DECLINE'
    }
    this.trackEventService.updateTrackEvent('applicant data updated', { stage_name: 'consent page', action_value: 'declined clicked' }, true, true);
    this.sharedService.updateInviteStatus(data, inviteId).subscribe(response => {
      if (this.redirectURL != undefined && this.redirectURL != '') {
        let url = new URL(this.redirectURL);
        var search_params = url.searchParams;
        let id = this.localStorage.getItem('invite_id');
        if (id != undefined){
            search_params.set('id', id);
        }
        search_params.set('action', ApplicantStatus.DECLINED);
        let new_url = url.toString();
        window.location.href = new_url;
        return;
      } else {
        this.hidePleaseWait();
        this.sharedService.setErrorCodeAndMsg('MESSAGE', this.declineESigCText);
        this.router.navigate(['/app-error']);
      }
    },
      error => {
        this.trackEventService.updateTrackEvent('update applicant data failed', { stage_name: 'consent page', action_value: 'decline clicked' }, true, false);
        this.hidePleaseWait();
        this.router.navigate(['/app-error']);
      });
  }

  confirmDeclineESignature() {
    $('#declineEsignature_Modal').modal('hide');
    $('.modal-backdrop').remove();
    $('#pleaseWaitModal').modal('show');

    this.localStorage.setItem("consent", this.DECLINED);
    this.consent_date = new Date();
    let consent_date_str = this.consent_date.toISOString();
    this.localStorage.setItem("consent_date", consent_date_str);
    let invite_key = this.localStorage.getItem("invite_key");
    let invite_id = this.localStorage.getItem("invite_id");
    this.errorMessage = "";

    if (invite_key != undefined && invite_key != '') {
      let body = {
        consent: this.DECLINED,
        consent_date: consent_date_str
      }

      this.sharedService.consentAccepted(body, invite_id).subscribe(response => {
        this.trackEventService.updateTrackEvent('consent decline, date ' + consent_date_str, { stage_name: 'consent', action_value: 'decline clicked' }, true, true);
        this.processPostDecline();
      }, error => {
        this.hidePleaseWait();
        this.trackEventService.updateTrackEvent('consent decline failed', { stage_name: 'consent', action_value: 'decline clicked' }, true, false);
        this.errorMessage = error.errorMessage;
      });
    }
  }

  acceptConsent(obj: any) {
    this.localStorage.setItem("consent", this.ACCEPTED);
    this.consent_date = new Date();
    let consent_date_str = this.consent_date.toISOString();
    this.localStorage.setItem("consent_date", consent_date_str);
    let invite_key = this.localStorage.getItem("invite_key");
    let invite_id = this.localStorage.getItem("invite_id");
    this.errorMessage = "";

    if (invite_key != undefined && invite_key != '') {
      let body = {
        consent: this.ACCEPTED,
        consent_date: consent_date_str
      }
      this.sharedService.consentAccepted(body, invite_id).subscribe(response => {
        this.hidePleaseWait();
        this.trackEventService.updateTrackEvent('consent accepted, date ' + consent_date_str, { stage_name: 'consent', action_value: 'sign clicked' }, true, true);
        this.router.navigate(['/app-landing'], { queryParams: { invite_id: invite_id}, queryParamsHandling: 'merge' });
      }, error => {
        this.hidePleaseWait();
        this.trackEventService.updateTrackEvent('consent accept failed', { stage_name: 'consent', action_value: 'sign clicked' }, true, false);
        this.errorMessage = error.errorMessage;
      });
    }
  }

  hidePleaseWait(){
    $('#pleaseWaitModal').modal('hide');
    $('.modal-backdrop').remove();
  }

  esignature() {
    let firstName = this.localStorage.getItem("first_name");
    let lastName = this.localStorage.getItem("last_name");
    let fullname = `${firstName} ${lastName}`;
    this.displayLabels = [fullname];
    this.signaturePadNote =  this.ts.translate('E_SIGNATURE_PAD_NOTE');
    this.confirmSignatureText =  this.ts.translate('E_SIGNATURE_PAD_CONFIRM_TEXT');
    if (this.useFontBasedSignature) {
      this.signatureValue = this.signaturePadExtendedContainer.first.getSignature();
    } else {
      this.signaturePadExtendedContainer.first.setSignature(this.signatureValue);
    }
    if (this.signatureValue == undefined || this.signatureValue == "" || this.signatureValue == null) {
      this.disableSignCleanButton = true;
    } else {
      this.disableSignCleanButton = false;
    }
    this.signaturePadExtendedContainer.first.signatureContent.nativeElement.classList.remove("confirm-sign-animate-right");
    this.signatureActionsBar.nativeElement.classList.remove("confirm-sign-animate-right");
    $('#signature_Modal').modal('show');
  }

  modalSignatureClose() {
    $('#signature_Modal').modal('hide');
    $('.modal-backdrop').remove();
  }

  onSign() {
    this.trackEventService.updateTrackEvent('save signature', { stage_name: 'consent', action_value: 'sign clicked' }, true, true);
    this.signaturePadExtendedContainer.first.promptConfirmSignature();
    this.confirmSignatureActions = true;
    this.signatureActions = false;
  }

  onConfirmSignGoBack(action: string) {
    if (action === 'back') {
      this.trackEventService.updateTrackEvent('signature retry', { stage_name: 'consent', action_value: 'clicked on back' }, true, true);
    }
    this.signaturePadExtendedContainer.first.hideConfirmSignature();
    this.signatureActions = true;
    this.signatureActionsBar.nativeElement.classList.add("confirm-sign-animate-right");
    this.confirmSignatureActions = false;
  }

  onConfirmSign(){
    this.modalSignatureClose();
    this.signaturePadExtendedContainer.first.hideConfirmSignature();
    this.signatureActions = true;
    this.confirmSignatureActions = false;
    $('#pleaseWaitModal').modal('show');
    this.signatureValue = this.signaturePadExtendedContainer.first.getSignature();
    var obj = {
      consent: 'ACCEPTED',
      consent_date: new Date().getTime(),
      consent_signature: this.signatureValue
    }
    let isFontBasedSignature = this.signaturePadExtendedContainer.first.isFontBasedSignatureUsed();
    if (isFontBasedSignature) {
      this.trackEventService.updateTrackEvent('font/text based signature captured', { stage_name: 'consent', action_value: 'entered' }, true, true);
    } else {
      this.trackEventService.updateTrackEvent('mouse/finger based signature captured', { stage_name: 'consent', action_value: 'clicked' }, true, true);
    }

    this.trackEventService.updateTrackEvent('confirm signature', { stage_name: 'consent', action_value: 'confirm sign clicked' }, true, true);

    this.authorizeConsent(obj);
  }

  authorizeConsent(obj: any) {
    let workflow_instance_id = this.localStorage.getItem("workflow_instance_id");
    let consentDataListArray = [];
    let consentData = {
      "consent_id": this.consentForm['id'],
      "consent-base64": this.signatureValue,
      "consent-acceptance": "Agree",
      "consent-name": this.consentForm['consent-name'],
      "is_std_consent_checked": false
    }
    consentDataListArray.push(consentData);
    let state = {
      "current_stage": "agreement-v1",
      "current_step": "stdconsent_1",
      "current_step_index": 1,
      "current_sub_step": "consent-step-v1",
      "current_sub_step_index": 0,
      "current_status": "COMPLETED",
      "stage_status": "COMPLETED"
    }
    let consentDataList = {
      'consent-data-list': consentDataListArray,
      'state': state
    };
    let invite_key = this.localStorage.getItem("invite_key");

    this.sharedService.putConsentStep(consentDataList, workflow_instance_id).subscribe(consentReponse => {
      this.trackEventService.updateTrackEvent('save consent and sign', { stage_name: 'consent', action_value: 'confirm sign clicked' }, true, true);
      this.acceptConsent(obj);
    }, error => {
      this.hidePleaseWait();
      this.trackEventService.updateTrackEvent('save consent failed ' + error.errorMessage, { stage_name: 'consent', action_value: 'confirm sign clicked' }, true, false);
      this.errorMessage = error.errorMessage;
    });
  }

  onSignCancel() {
    this.trackEventService.updateTrackEvent('close signature modal', { stage_name: 'consent', action_value: 'cancel clicked' }, true, true);
    console.log('On Sign Cancel...')
    this.onConfirmSignGoBack('cancel');
    this.modalSignatureClose();
    this.onSignClear();
  }

  onSignClear() {
    this.trackEventService.updateTrackEvent('clear signature', { stage_name: 'consent', action_value: 'clear clicked' }, true, true);
    console.log('On Sign Clear...')
    this.signaturePadExtendedContainer.first.clear();
    this.signatureValue = "";
    this.disableSignCleanButton = true;
  }

  onDrawComplete(obj: any) {
    let tempSgnatureValue = this.signaturePadExtendedContainer.first.getSignature();
    if (tempSgnatureValue == undefined || tempSgnatureValue == "" || tempSgnatureValue == null) {
      this.disableSignCleanButton = true;
    } else {
      this.disableSignCleanButton = false;
    }
  }
}
