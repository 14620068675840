// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --uat` replaces `environment.ts` with `environment.uat.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl: 'https://pa-ua.fadv.com',
  production: false,
  camerasettings: {
    captureMode: 'Manual'
  },
  websdk_vendor: 'MITEK',
  verification_api_vendor: 'MITEK',
  show_non_us_flow: 'Yes',
  havingTroubleToastConfig: {
    gotItButtonClicksThreshold: 5,
    idleTimeThresholdSeconds: 50
  },
  mitekLicenseKey: 'eyJzaWduYXR1cmUiOiJEeDBWbFA5R2R0RkJoQURTakpHY21HVDdDcnVkOVloWU5GQjJEWDFpNzlIUjg0TzhJODc2eTVZOXlkUFpMUFJkYktvUStTbHREOEJrcU1OUHArM3pJT2dmaGxwYjVWT3dyUkJQdnZrRXdxRHZ4K29lU1ROU3R1VTNrdVFjK3RVWFhLc1diNkVyek5rck5oYTlJK0U0azRaSWRGeWNhM01keFVvZE4vY2hwQVV1TDBmd0U4RG9DY25HaDczcFVaelBEbUhBNzhRSXR5bkM5OEV6MDBPQzZ5OWE0ZHdvcE5FN3VRWmNmTmZqMGdBekJRK0g4NW8ySmVsZzc4dXhrWGIyck1BL0VyREYyVE9ZcGVvVVNCSE05ZFlDUy9leW5jSVNpLytHazZOR0w0cXB2Y0V2UmdBQUxXUUZtcWR3ZEpEN0FZN3lhMjFGTjZXL2l6RnN2N2R6OHc9PSIsInNpZ25lZCI6eyJleHBpcnkiOiIyMDI1LTEyLTA1IiwiZmVhdHVyZXMiOlsiYmFyY29kZSIsImdlbmVyaWMiLCJpZCIsImZhY2UiXSwiYXBwbGljYXRpb25JZGVudGlmaWVyIjp7IndlYiI6WyIqLmZhZHYubmV0IiwiKi5mYWR2LmNvbSIsIiouZW50ZXJwcmlzZXRlc3QuZmFkdi5jb20iLCJsb2NhbGhvc3QiXX0sImdwbyI6IjE2ZCIsIm9yZ2FuaXphdGlvbiI6Ik1pdGVrIFN5c3RlbXMgSW5jLiIsInZlcnNpb24iOiIxLjAifX0=',
  salesForceInitializeUrl: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
  salesForceChatSettings: {
    displayHelpButton: true,
    language: '',
    defaultMinimizedText: '',
    disabledMinimizedText: '',
    loadingText: '',
    storageDomain: '',
    prepopulatedPrechatFields: {},
    enabledFeatures: ['LiveAgent'],
    entryFeature: 'LiveAgent',
    initEnvUrl: 'https://firstadv--staging.sandbox.my.salesforce.com',
    initAgentUrl: 'https://firstadv--staging.sandbox.my.site.com',
    initParam1: '00D7e00000Jywkx',
    initAgentName: 'Customer_Care_Chat_Clone',
    customSettings: {
      baseLiveAgentContentURL: 'https://c.la3-c1cs-ia5.salesforceliveagent.com/content',
      deploymentId: '5725a000000Goie',
      buttonId: '5735a000000oLor',
      baseLiveAgentURL: 'https://d.la3-c1cs-ia5.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7e00000000ZcEAI_18abd7a271c',
      isOfflineSupportEnabled: false
    }
  },
  salesForceChatEmbeddedServiceMinJS: 'https://firstadv--staging.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
  salesForceChatBaseUrl: 'https://service.force.com',
  salesForceChatPosition: 'left',
  default_chat_exp : 'SFC',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
