<div>
  <!-- <div id="div_camera_help" style="display: ;">
        <img id="camera_help_image" height="550" width="450" src="../../assets/img/photoId_3.png"/>
        <div>
            <button (click)="captureImage();" >GOT IT</button>
        </div>
      </div>

      <div id="div_captured_image"  style="display: none;">
          <img id="captured_image" height="550" width="450" src="../../assets/img/photoId_4.png"/>
          <button (click)="captureImage();">RETAKE</button>
          <button (click)="next();">NEXT</button>
      </div> -->
  <ng-content></ng-content>
  <div class="cameraLoading">
    Loading camera...
    <!--<button class="btn button btn-align"
      (click)="clickCameraNextButton();">NEXT</button> -->
  </div>
</div>
