import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@fadv/web-common';
import { FileUploadService } from '../fileupload/file-upload.service';
import { ConfirmationConfig } from './confirmation-config';
import { TrackEventService } from '../shared/Services/trackEvent.service';
import { TranslateService } from '../framework/i18n/translate.service';
import { Result, ApplicantStatus, IdentityConfig } from '../identity-config';
import { SharedService } from '../shared/Services/shared.service';


declare var jQuery: any;
declare var window: any;

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  private confirmationSteps: any;
  private sliderIndex: number;
  errorObj: any;
  errorMessage: string;
  message: string;
  result: string;
  retakesteps: any;
  enableNext: boolean = false;
  isError: boolean = false;
  currentStep: any;
  isRetake: boolean = false;
  isIDDocumentVerified: boolean = false;
  isPassportVerified: boolean = false;
  isSelfieVerified: boolean = false;
  isLivenessSelfieVerified: boolean = false;
  selectedIDType: string;
  sucessResponse: boolean = false;
  uploadCount: number = 0;
  uploadAttempt: number = 1;
  retakeAttempt: number = 0;
  totalRetryAttempt: number = 0;
  private startTime: any;
  private inviteKey: any;
  showNext: boolean = false;
  showAbortOptionLink: boolean = false;
  redirectUrl: string;
  abortPopupMsg: string;
  showAbortOptionPref: string;
  inviteId: string;
  abortLinkLabel: string;
  maskDecision: string = 'NO';
  isAbort: boolean = true;
  inProgress: boolean = true;

  @Input() emitData: boolean;
  @Output() onConfirmationNext: EventEmitter<any> = new EventEmitter();
  actionCode: number = 0;
  
  constructor(private router: Router, private localStorage: LocalStorageService, private _ts: TranslateService,
    private uploadService: FileUploadService, private trackEventService: TrackEventService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.inProgress = true;
    this.startTime = new Date().getTime();
    this.confirmationSteps = ConfirmationConfig.getSteps();
    this.sliderIndex = 0;
    this.isRetake = false;
    this.sucessResponse = false;
    this.inviteId = this.localStorage.getItem("invite_id");
    this.inviteKey = this.localStorage.getItem("invite_key");
    this.selectedIDType = this.localStorage.getItem("selectedIDType");
    this.redirectUrl = this.localStorage.getItem("redirect_url");
    this.totalRetryAttempt = this.localStorage.getItem("retry_attempt");
    this.abortPopupMsg = this.localStorage.getItem('result_confirmation_abort_popup_msg');
    this.abortLinkLabel = this.localStorage.getItem('result_confirmation_abort_link_label');
    this.redirectUrl = this.localStorage.getItem('redirect_url');
    this.showAbortOptionPref = this.localStorage.getItem('show_abort_options');
    console.log("confirmation component totalRetryAttempt ", this.totalRetryAttempt);
    if (this.totalRetryAttempt === undefined) {
      this.totalRetryAttempt = 3;
    }
    if (this.inviteId === undefined) {
      this.inviteId = '';
    }
    if (this.redirectUrl === undefined || this.redirectUrl === '') {
      this.showNext = false;
    }

    if (this.redirectUrl != undefined && this.redirectUrl != '' && this.redirectUrl
      && this.showAbortOptionPref != undefined && this.showAbortOptionPref.toUpperCase() === 'YES'){
      this.showAbortOptionLink = true;
    }
    if (this.abortPopupMsg === '' || this.abortPopupMsg === undefined) {
      this.abortPopupMsg = this._ts.instant('ABORT_MODAL_HEADER_1');
    }
    if (this.abortLinkLabel === '' || this.abortLinkLabel === undefined) {
      this.abortLinkLabel = this._ts.instant('RESULT_CONFIRM_ABORT_OPTION');
    }
    if (this.emitData) {
      this.showNext = true;
    }
    this.setSlider(this.sliderIndex, 0);
    this.uploadCount = 0;
    this.retakeAttempt = this.localStorage.getItem("retake-attempt");
    if (this.retakeAttempt === undefined) {
      this.retakeAttempt = 0;
    }

    this.uploadImages(this.inviteId);
  }

  uploadImages(inviteId) {
    let that = this;
    this.uploadCount += 1;
    this.uploadService.uploadImages(inviteId).subscribe(
      response => that.processResponse(response),
      error => this.handleUploadError(error)
    );
  }

  handleRecapture(retakesteps) {
    let trackObj = {
      response_result: retakesteps,
      action_value: 'handleRecapture'
    };
    this.trackEventService.processTrackEvent('retake attempt: ' + this.retakeAttempt + ' Total re-try attempt: ' + this.totalRetryAttempt, 'confirm page', this.startTime, trackObj, true, true);
    
    if (retakesteps !== undefined && retakesteps !== '') {
      let idFrontStep = (retakesteps.indexOf('id-front') !== -1);
      let idBackStep = (retakesteps.indexOf('id-back') !== -1);
      let retakeSelfieNearStep = (retakesteps.indexOf('selfie-near') !== -1);
      let retakeSelfieFarStep = (retakesteps.indexOf('selfie-far') !== -1);

      let selectedIDType = this.localStorage.getItem("selectedIDType");
      if (selectedIDType === 'gid' && !idFrontStep && !idBackStep) {
        this.isIDDocumentVerified = true;
      } else if (selectedIDType === 'passport' && !idFrontStep) {
        this.isPassportVerified = true;
      } else if(selectedIDType === 'non_us_gid' && !idFrontStep){
        this.isIDDocumentVerified = true;
      }
      this.isSelfieVerified = !retakeSelfieNearStep;
      this.isLivenessSelfieVerified = !retakeSelfieFarStep;

      if(this.retakeAttempt < this.totalRetryAttempt){
        this.isRetake = true;
        this.inProgress = false;
        this.enableNext = false;
        this.retakeAttempt += 1;
        this.localStorage.setItem("retake-attempt", this.retakeAttempt);
      } else {
        if (this.errorMessage === undefined){
          this.errorMessage =  this._ts.instant('RETAKE_LIMIT_EXCEEDED');
        }
        this.result = Result.NotVerified;
        this.maskDecision = this.localStorage.getItem('mask_decision');
        this.isRetake = false;
        this.sucessResponse = false;
        this.isError = false;

        if (this.emitData || (this.redirectUrl != undefined && this.redirectUrl != '')) {
          this.showNext = false;
          this.enableNext = false;
          this.processAbortForRetakeExeedAtempt();
        }
      }
    } else {
      let trackObj = {
        response_result: 'retake step is empty',
        action_value: 'handleRecapture'
      };
      this.trackEventService.processTrackEvent('retake step is empty: retake attempt: ' + this.retakeAttempt + ' Total re-try attempt: ' + this.totalRetryAttempt, 'confirm page', this.startTime, trackObj, true, true);
    }
  }

  handleUploadError(error) {
    let that = this;
    this.inProgress = false;
    let errorCode = error.errorCode;

    if (errorCode === 'RETRY' && that.uploadCount < that.totalRetryAttempt) {
      jQuery('#retryMsg').css('display', 'block');
      if (that.uploadCount == 1) {
        that.uploadAttempt = 1;
      } else {
        that.uploadAttempt += 1;
      }
      let trackObj = {
        RetryAttempt: that.uploadAttempt,
        action_value: 'error'
      };
      that.trackEventService.processTrackEvent('upload images failed', 'confirm page', that.startTime, trackObj, true, true);
      that.startTime = new Date().getTime();
      //Retrying to upload Images.
      that.uploadImages(this.inviteId);
    } else {
      let selectedIDType = that.localStorage.getItem("selectedIDType");
      that.sliderIndex = 1;
      that.sucessResponse = false;
      jQuery('#retryMsg').css('display', 'none');
      jQuery('#waiting').css('display', 'none');
      that.errorObj = <any>error;
      // console.log("errorObj ", that.errorObj);
      that.errorMessage = that.errorObj['errorMessage'];
      if (!!that.errorMessage) {
        that.errorMessage = that._ts.instant(that.errorMessage);
      }
      that.result = that.errorObj['result'];
      
      if (that.result === undefined) {
        that.result = 'Error';
      }
      
      let trackObj = {
        response_result: that.result,
        action_value: 'error'
      };
      that.trackEventService.processTrackEvent('upload images failed', 'confirm page', that.startTime, trackObj, true, true);
      
      that.startTime = new Date().getTime();
      /* if (that.result === 'Error') {
         //TODO
         // window.notify(that._ts.instant("ERROR_NOTIFY_MSG"));
       } else*/
      if (that.result === Result.Retake) {
        //Retake
        that.retakesteps = that.errorObj['type'];
        that.handleRecapture(that.retakesteps);
      } else if (that.result === Result.Expired || that.result === Result.Unauthorized) {
        if (that.selectedIDType === 'gid') {
          that.retakesteps = ['id-front', 'id-back'];
        } else {
          that.retakesteps = ['id-front'];
        }
        that.handleRecapture(that.retakesteps);
      } else if (that.result === Result.NotVerified || that.result === Result.Verified) {
        that.enableNext = false;
        if (that.emitData) {
          that.enableNext = true;
        } else {
          that.next();
        }
      } else if (that.result === Result.Error) {
        if (selectedIDType === 'gid') {
          that.retakesteps = ['id-front', 'id-back', 'selfie-near', 'selfie-far'];
        } else {
          that.retakesteps = ['id-front', 'selfie-near', 'selfie-far'];
        }
        that.handleRecapture(that.retakesteps);
      }
      that.isError = true;
      if (!that.isRetake && !that.showNext) {
        that.close();
      }
      that.setSlider(that.sliderIndex, 0);
    }
  }

  next() {
    this.localStorage.removeItem("retakesteps");

    if (this.emitData) {
      let obj = {};
      obj['selectedIDType'] = this.selectedIDType;
      obj['result'] = this.result;
      this.localStorage.setItem('rid_status', this.result);
      this.trackEventService.updateTrackEvent('submit completed, redirect', { stage_name: 'Rightid', action_value: 'Rightid result' }, true, true);
      this.onConfirmationNext.emit(obj);
    } else if (this.redirectUrl != undefined && this.redirectUrl != '') {
      this.trackEventService.updateTrackEvent('submit completed, redirect', { stage_name: 'Rightid', action_value: 'Rightid result' }, true, true);
      this.trackEventService.updateTrackEvent('result' + this.result, { stage_name: 'confirmation page', redirectUrl: this.redirectUrl, action_value: 'redirect' }, true, false);
      jQuery('#pleaseWaitModal').modal('show');
      
      setTimeout(() => {
        let url = new URL(this.redirectUrl);
        var search_params = url.searchParams;
        search_params.set('id', this.inviteId);
        search_params.set('action', ApplicantStatus.COMPLETED);
        this.localStorage.setItem('rid_status', ApplicantStatus.COMPLETED);
        let new_url = url.toString();
        window.location.href = new_url;
      }, 1000);
    }
  }

  processResponse(response) {
    this.sliderIndex = 1;
    this.sucessResponse = true;
    this.isError = false;
    // console.log("ConfirmationComponent response " + JSON.stringify(response));
    this.result = response['result'];
    this.message = response['message'];
    this.localStorage.setItem('rid_status', this.result);

    // Code for error message based on action_code returned from AuthenticId
    this.actionCode = (!!response && !!response['action_code']) ? response['action_code'] : 0;
    let errorKeyValue = IdentityConfig.errorCodeMapping(this.actionCode);

    if (this.actionCode !== 0 && errorKeyValue !== '')
      this.message = this._ts.translate(errorKeyValue);
    
    if (this.actionCode !== 0) {
      let trackObj = { 
        action_value : this.result + ', ' + this.actionCode
      }      

      this.trackEventService.processTrackEvent('error code', 'confirm page', this.startTime, trackObj, true, true);
    }
    
    //#2079 Do not show the response message for Verified result.
    if (this.result === Result.Verified){
      this.message = '';
    }

    let selectedIDType = this.localStorage.getItem("selectedIDType");
    let trackObj = {
      response_result: this.result,
      action_value: 'confirm page loaded'
    };
    this.trackEventService.processTrackEvent('uploading images', 'confirm page', this.startTime, trackObj, true, true);
    this.startTime = new Date().getTime();

    if (this.result === Result.Retake) {
      //Retake
      this.retakesteps = response["data"]["type"];
      this.handleRecapture(this.retakesteps);
    } else if (this.result === Result.Expired || this.result === Result.Unauthorized) {
      if (selectedIDType === 'gid') {
        this.retakesteps = ['id-front', 'id-back'];
      } else {
        this.retakesteps = ['id-front'];
      }
      this.handleRecapture(this.retakesteps);
    } else if (this.result === Result.NotVerified || this.result === Result.Verified) {
      this.maskDecision = this.localStorage.getItem('mask_decision');
      this.enableNext = false;
      if (this.emitData) {
        this.enableNext = true;
      } else {
        this.next();
      }
      this.inProgress = false;
    } else if (this.result === Result.Error) {
      if (selectedIDType === 'gid') {
        this.retakesteps = ['id-front', 'id-back', 'selfie-near', 'selfie-far'];
      } else {
        this.retakesteps = ['id-front', 'selfie-near', 'selfie-far'];
      }
      this.handleRecapture(this.retakesteps);
    }
    jQuery('#retryMsg').css('display', 'none');
    jQuery('#waiting').css('display', 'none');

    if (!this.isRetake && !this.showNext) {
      this.close();
    }

    this.setSlider(this.sliderIndex, 0);
  }

  close() {
    this.trackEventService.updateTrackEvent('flow completed', {stage_name: 'confirmation page', action_value: 'next clicked'}, true, false);
  }

  retake() {
    this.trackEventService.updateTrackEvent('retake steps ' + this.retakesteps, {stage_name: 'confirmation page', action_value: 'retake clicked'}, true, false);
    this.localStorage.setItem("retakesteps", this.retakesteps);
    let idFrontStep = (this.retakesteps.indexOf('id-front') !== -1);
    let idBackStep = (this.retakesteps.indexOf('id-back') !== -1);
    if (idFrontStep || idBackStep) {
      this.router.navigate(['/app-id'], { queryParams: { invite_id: this.localStorage.getItem("invite_id")}, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/app-selfie'], { queryParams: { invite_id: this.localStorage.getItem("invite_id")}, queryParamsHandling: 'merge' });
    }
  }

  setSlider(index, i?, j?): void {
    this.currentStep = this.confirmationSteps[index];
  }

  showAbortModal(){
     jQuery('#abortmodal').modal('show');
  }

  processAbort(){
    this.trackEventService.updateTrackEvent('retake abort', {stage_name: 'confirmation page', action_value: 'abort clicked'}, true, true);
    jQuery('#abortmodal').modal('hide');
    jQuery('#pleaseWaitModal').modal('show');
    this.localStorage.setItem('rid_status', ApplicantStatus.ALTERNATE_WORKFLOW);

    let data = {
      'status' : ApplicantStatus.ALTERNATE_WORKFLOW
    }
    if (this.inviteKey !== undefined && this.inviteKey !== '') {
      this.sharedService.updateInviteStatus(data, this.inviteId).subscribe(response => {
        this.trackEventService.updateTrackEvent('retake abort, applicant data updated', { stage_name: 'confirmation page', action_value: 'abort clicked' }, true, false);
        
        if(this.isAbort) {
          let obj = {};
          obj['selectedIDType'] = this.selectedIDType;
          obj['result'] = this.result;
          this.onConfirmationNext.emit(obj);
          jQuery('#pleaseWaitModal').modal('hide');
        } else {
          if (this.redirectUrl != undefined && this.redirectUrl != '') {
            this.trackEventService.updateTrackEvent('redirect to ' + this.redirectUrl, { stage_name: 'confirmation page', redirectUrl: this.redirectUrl, action_value: 'abort clicked' }, true, false);
            let url = new URL(this.redirectUrl);
            var search_params = url.searchParams;
            search_params.set('id', this.inviteId);
            search_params.set('action', ApplicantStatus.ALTERNATE_WORKFLOW);
            let new_url = url.toString();
            setTimeout(() => {
              window.location.href = new_url;
              }, 1000);
          } else {
            this.trackEventService.updateTrackEvent('redirect to error page', { stage_name: 'confirmation page', action_value: 'abort clicked' }, true, true);
            this.sharedService.setErrorCodeAndMsg('OPT_OUT', this.abortPopupMsg);
            this.router.navigate(['/identity/app-error']);
            jQuery('#pleaseWaitModal').modal('hide');
            return;
          }
        }
      },
        error => {
          this.trackEventService.updateTrackEvent('update applicant data failed', { stage_name: 'confirmation page', action_value: 'abort clicked' }, true, false);
          console.log("Error occurred ", error);
          jQuery('#pleaseWaitModal').modal('hide');
        });
    }
  }


  processAbortForRetakeExeedAtempt(){
    this.trackEventService.updateTrackEvent('retake exceeded', {stage_name: 'confirmation page', action_value: 'retake exceeded'}, true, true);
    jQuery('#pleaseWaitModal').modal('show');
    let data = {
      'status' : ApplicantStatus.RETAKE_ATTEMPT_EXCEEDED
    }
    if (this.inviteKey !== undefined && this.inviteKey !== '') {
      this.sharedService.updateInviteStatus(data, this.inviteId).subscribe(response => {
        this.localStorage.setItem('rid_status', ApplicantStatus.RETAKE_ATTEMPT_EXCEEDED);
        this.trackEventService.updateTrackEvent('applicant data updated', { stage_name: 'confirmation page', action_value: 'retake exceeded' }, true, false);

        if (this.redirectUrl != undefined && this.redirectUrl != '') {
          this.trackEventService.updateTrackEvent('redirect to ' + this.redirectUrl, { stage_name: 'confirmation page', redirectUrl: this.redirectUrl, action_value: 'retake exceeded' }, true, false);
          let url = new URL(this.redirectUrl);
          var search_params = url.searchParams;
          search_params.set('id', this.inviteId);
          search_params.set('action', ApplicantStatus.RETAKE_ATTEMPT_EXCEEDED);
          let new_url = url.toString();
          setTimeout(() => {
            window.location.href = new_url;
            }, 1000);
        } else {
          this.showNext = true;
          this.enableNext = true;
          this.inProgress = false;
          jQuery('#pleaseWaitModal').modal('hide');
        }
      },
        error => {
          this.trackEventService.updateTrackEvent('update applicant data failed', { stage_name: 'confirmation page', action_value: 'retake exceeded' }, true, false);
          console.log("Error occurred ", error);
          jQuery('#pleaseWaitModal').modal('hide');
        });
    }
  }

  closeAbortModal(){
    jQuery('#abortmodal').modal('hide');
    jQuery('.modal-backdrop').remove();
  }
}
