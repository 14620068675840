import { InjectionToken } from '@angular/core';

// import translations
import { LANG_EN_NAME, LANG_EN_TRANS } from '../../assets/i18n/language-en';
import { LANG_ES_NAME, LANG_ES_TRANS } from '../../assets/i18n/language-es';

/*
 * One solution to choosing a provider token for non-class dependencies is to define and use an OpaqueToken.
 * Token - TRANSLATION
*/
export const TRANSLATIONS = new InjectionToken('translations');

// all traslations
export const dictionary = {
	[LANG_EN_NAME]: LANG_EN_TRANS,
	[LANG_ES_NAME]: LANG_ES_TRANS,
};

// providers
export const TRANSLATION_PROVIDERS = [
	{ provide: TRANSLATIONS, useValue: dictionary },
];
