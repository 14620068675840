import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/app-config';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private errorCode: string;
  private errorMessage: string;

  constructor(private _http: HttpClient) { }

  updateInvite(body: any): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this._http
      .put(AppConfig.API_ENDPOINT() + `/api/web/invite`, body, httpOptions).pipe(
        catchError(this._handleError)
      );
  }

  consentAccepted(body: any, invite_id: string): Observable<Object>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
     return this._http.put(AppConfig.API_ENDPOINT() + '/api/web/invite/' + invite_id + '/applicant/consent', body, httpOptions)
    .pipe(catchError(this._handleError));
  }

  private _handleError(error: Response | any) {
    let errorMessage: string;
    if(error['_body']){
      let _body = JSON.parse(error['_body']) || {};      
      switch (error.status) {
        case 400:
        case 401:
          // Bad request, Invalid Credentials - login invalid
          errorMessage = _body['error'] || 'Invalid Login';
          break;
        case 404:
          break;
      }
    } else {
      errorMessage = 'Error occurred';
    }

    return throwError({
      'errorMessage': errorMessage,
    });
  }

  setErrorCodeAndMsg(code, msg) {
    this.errorCode = code;
    this.errorMessage = msg;
  }

  getErrorCode() {
    return (this.errorCode) ? this.errorCode : 'BAD_REQUEST';
  }

  getErrorMessage() {
    return (this.errorMessage) ? this.errorMessage : 'Something went wrong';
  }
}
