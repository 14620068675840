import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { IdentityConfig } from '../identity-config';

@Injectable({
  providedIn: 'root'
})
export class ApplicantInfoService {

  constructor(private http: HttpClient) { }

  countryCodeLookUpService(body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
        
    return this.http.get(IdentityConfig.API_ENDPOINT() + `/_internal/v1/workflow/lookup-isdcodes/?code=${body}` + '&language=' + 'EN', httpOptions)
      .pipe(
        map(res => this._extractNewData(res)),
        map(res => this._doGetAction(res)),
        catchError(this._handleError)
      );
  }

  private _extractNewData(res) {
    return (res) || {};
  }

  private _doGetAction(res) {
    return res;
  }

  private _handleError() {
    return throwError('Data is missing');
  }
}
