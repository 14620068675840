import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CameraConfig, CAPTURE_TYPE, objDocumentSettingsDefault, objSelfieSettingssDefault, DOCUMENT_MODE, DOCUMENT_SIDE } from './id-camera-config'
import * as _ from 'lodash';
import { LocalStorageService } from '@fadv/web-common';

declare let window: any;
declare let $: any;


@Component({
  selector: 'lib-id-camera',
  templateUrl: './id-camera.component.html',
  styleUrls: ['./id-camera.component.css']
})
export class IdCameraComponent implements OnInit {

  @Input() resultImage: string;
  private capture: any;
  @Input() config: CameraConfig;
  @Output() onCameraNext: EventEmitter<any> = new EventEmitter();
  @Output() onCameraUIEvent: EventEmitter<any> = new EventEmitter();
  @Output() onCameraAborted: EventEmitter<any> = new EventEmitter();
  @Output() onCameraError: EventEmitter<any> = new EventEmitter();
  trackObj: Object;

  constructor(private localStorage: LocalStorageService) {
    console.log('loaded camera compoenent');
  }

  ngOnInit() {
    this.capture = window.IDMetricsCaptureFramework;
    window.captureFrameworkDebug = null;

    this.loadCamera();
  }

  loadCamera() {
    // window.captureFrameworkDebug = null;
    // this.capture = window.IDMetricsCaptureFramework;
    console.log('loaded camera');
    this.capture.GetSDKVersion((data) => {
      console.log('------ sdk version', data);
    });

    // console.log(this.config.captureMode);
    console.log('capture type - ', this.config.captureType);
    if (this.config.captureType == CAPTURE_TYPE.DOCUMENTID) {
      this.captureDocument();
    } else if (this.config.captureType == CAPTURE_TYPE.SELFIE) {
      this.captureSelfie(this.config.enableFarSelfie);
    }
  }

  captureDocument() {
    let objDocumentSettings = new window.DocumentSettings();
    _.merge(objDocumentSettings, objDocumentSettingsDefault);

    objDocumentSettings.documentType = this.config.documentType;
    objDocumentSettings.documentSide = this.config.documentSide;
    objDocumentSettings.captureMode = this.config.captureMode;

    if (this.config.documentSide == DOCUMENT_SIDE.FRONT) {
      // 03-09-21 CPIC: v4.5.4 - Making enable Face Detection false for doc settings, based on discussion with Hari
      objDocumentSettings.enableFaceDetection = false;
      objDocumentSettings.isBarcodeDetectedEnabled = false;
      if (this.config.documentType == DOCUMENT_MODE.LICENSE) {
        objDocumentSettings.frontFocusThreshold = window.deviceThreshold.DLFrontFocus;
        objDocumentSettings.frontGlareThreshold = window.deviceThreshold.DLFrontGlare;
      } else {
        objDocumentSettings.frontFocusThreshold = window.deviceThreshold.PassportFrontFocus;
        objDocumentSettings.frontGlareThreshold = window.deviceThreshold.PassportFrontGlare;
      }
      console.log('doc front settings -- ', objDocumentSettings);
    } else {
      objDocumentSettings.backFocusThreshold = window.deviceThreshold.DLBackFocus;
      objDocumentSettings.backGlareThreshold = window.deviceThreshold.DLBackGlare;
      objDocumentSettings.enableFaceDetection = false;
      if (this.config.documentType == DOCUMENT_MODE.LICENSE) {
        // 03-09-21 CPIC: v4.5.4 - Making barcode detect enabled false for doc settings, based on discussion with Hari
        objDocumentSettings.isBarcodeDetectedEnabled = false;
      } else {
        objDocumentSettings.isBarcodeDetectedEnabled = false;
      }

      console.log('doc back settings -- ', objDocumentSettings);
    }

    var captureResult = new window.CaptureResult();
    let that = this;
    captureResult.setOnEvent(function (erorCode, errorDesc, data) {
      console.log('Code ' + erorCode + ' Message ' + errorDesc);
      let obj = {};
      obj['code'] = erorCode;
      obj['message'] = errorDesc;
      obj['captureResult'] = captureResult;
      $("#errorModelOKButton").click(function () {
        $('#errorModal').hide();
        $('.modal-backdrop').remove();
        captureResult.continue();
        $("#errorModelOKButton").unbind("click");
      });
      that.onCameraUIEvent.emit(obj);
    });

    /* captureResult.fireUiEvent = (code, message) => {
       console.log('Code ' + code + ' Message ' + message);
       let obj = {};
       obj['code'] = code;
       obj['message'] = message;
       that.onCameraUIEvent.emit(obj);
     }*/

    captureResult.setOnFinish(() => {
      that.resultImage = captureResult._result;
      let obj = {};
      obj['resultImage'] = that.resultImage;
      that.onCameraNext.emit(obj);
    });

    if (this.config.documentSide == DOCUMENT_SIDE.BACK && this.config.documentType == DOCUMENT_MODE.LICENSE) {
      captureResult.setOnCaptureModeChange(function (captureMode, config) {
        console.log("New Capture Mode " + captureMode);
        //if (captureMode === "Manual") {
        //  config.isBarcodeDetectedEnabled = true;
        //}
        //return;
      });
    }
    captureResult.setOnAborted((e) => {
      that.onCameraAborted.emit(e);
    });

    var errorCallback = function (data) {
      console.log('errorCallback Error - ', data);
      that.onCameraError.emit(data);
    }

    this.capture.scanDocument(objDocumentSettings, captureResult, errorCallback);
  }

  captureSelfie(far) {
    var objSelfieSettings = new window.SelfieSettings();
    _.merge(objSelfieSettings, objSelfieSettingssDefault);
    objSelfieSettings.enableFarSelfie = far;
    objSelfieSettings.captureMode = this.config.captureMode;
    objSelfieSettings.enableFaceDetection = true;
    console.log('selfie settings - ', objSelfieSettings);
    var captureResult = new window.CaptureResult();
    let that = this;
    captureResult.setOnEvent(function (erorCode, errorDesc, data) {
      console.log('Code ' + erorCode + ' Message ' + errorDesc);
      let obj = {};
      obj['code'] = erorCode;
      obj['message'] = errorDesc;
      obj['captureResult'] = captureResult;
      $("#errorModelOKButton").click(function () {
        $('#errorModal').modal('hide');
        $('.modal-backdrop').remove();
        captureResult.continue();
        $("#errorModelOKButton").unbind("click");
      });
      that.onCameraUIEvent.emit(obj);
    });

    /*captureResult.fireUiEvent = function (code, message) {
      console.log('Code ' + code + ' Message ' + message);
      let obj = {};
      obj['code'] = code;
      obj['message'] = message;
      that.onCameraUIEvent.emit(obj);
    }*/

    captureResult.setOnFinish(() => {
      that.resultImage = captureResult._result;
      let obj = {};
      obj['resultImage'] = that.resultImage;
      that.onCameraNext.emit(obj);
    });

    captureResult.setOnAborted((e) => {
      that.onCameraAborted.emit(e);
    });

    var errorCallback = function (data) {
      console.log('errorCallback Error', data);
      that.onCameraError.emit(data);
    }

    this.capture.scanSelfie(objSelfieSettings, captureResult, errorCallback);
  }

  clickCameraNextButton() {
    let obj = {};
    obj['message'] = 'Clicked next';
    console.log('----- in Camera Next- ' + obj['message']);
    this.onCameraNext.emit(obj);
  }
}
