export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
  "GOVERNMENT_ID_DOC": "Driver's License / State ID",
  "PASSPORT": "Passport",
  "NEXT": "Next",
  "WELCOME": "Welcome",
  "WELCOME_TO": "Welcome %0",
  "LANDING_MSG": "We're going to take you through the following steps to validate your identity. It should only take a few minutes.",
  "CHOOSE_ID_TYPE_LANDING_MSG": "Choose the type of ID Document you'd like to use",
  "ID_LANDING_MSG": "Capture front and back photos of your ID",
  "SELFIE_LANDING_MSG": "We'll take two selfie photos for verification",
  "THATS_IT": "Thats It!",
  "LET_STARTED": "LET'S GET STARTED",

  "FIRST_NAME":"First Name",
  "LAST_NAME": "Last Name",
  "EMAIL_ID":"Email ID",
  "COUNTRY_CODE":"Country Code",
  "PHONE_NUMBER":"Phone Number",
  "ID_FRONT_INSTR_MSG_1": "How to take a photo of your Driver's License / State ID",
  "ID_INSTR_MSG_2": "Please position your Driver's License / State ID to ensure readability and no glare",
  "PASSPORT_INSTR_MSG_1": "How to take a photo of your passport",
  "PASSPORT_INSTR_MSG_2": "Please position your passport in an L shape to ensure readability and no glare",
  "ID_BACK_INSTR_MSG_1": "How to take a photo of the back of your Driver's License / State ID",
  "CONFIRM_STATIC_CONTENT": "Confirm static content",
  "CAMERA_STATIC_CONTENT": "Camera static content",
  "CONFIRM_PICTURE": "Confirm Picture",
  "RETAKE": "RETAKE",
  "CONFIRM_NEXT": "NEXT",
  "ERROR_TITLE": "Error!",
  "GOT_IT": "GOT IT",

  "SELFIE_INSTR_MSG_1": "We'll be taking two selfie photos",
  "SELFIE_INSTR_MSG_2": "Please continue to take two photos of your face in varying distances",
  "SELFIE_SINGLE_INSTR_MSG_1": "We'll be taking a selfie photo",
  "SELFIE_SINGLE_INSTR_MSG_2": "Please continue to take one photo of your face. This image may be used as a profile photo. Please consider appropriate clothing and background.",

  "CONFIRMATION_ID_SUBMITTED":"ID Submitted",
  "CONFIRMATION_SELFIE_SUBMITTED":"Selfie Submitted",
  "CONFIRMATION_LIVENESS_SELFIE_SUBMITTED":"Liveness Selfie Submitted",
  "WAIT_MSG":"Processing, please wait...",
  "DECISION_PROCESSING":"Decision Processing",
  "ERROR_NOTIFY_MSG":"Looks like an issue?",
  "SUCCESS_MSG":"Successfully Verified",
  "RESULTS": "Result: %0",
  "RETRY_ATTEMPT_MSG": "Retry Attempt: %0",
  "ID_DOC_VERIFIED": "ID Document Verified",
  "PASSPORT_VERIFIED": "Passport Verified",
  "SELFIE_VERIFIED": "Selfie Verified",
  "LIVENESS_SELFIE_VERIFIED": "Liveness Selfie Verified",
  "UNAUTHORIZED_MSG": "Given ID document is not supported, Please use alternate ID document",

  "RESULT_THANK_YOU": "Thank You! Looks like we've got everything we need right now.",
  "WE_WILL_GET_BACK": "One of our representatives will be in touch with you soon.",

  "ERROR_MSG_BAD_REQUEST":"Oops! Please check the URL. It seems the URL is invalid or service is temporary unavailable.",
  "ERROR_MSG_LINK_EXPIRED":"This link is no longer active.",
  "ERROR_MSG_INVITE_FAILURE":"Service is currently unavailable, please try again later.",
  "ERROR_MSG_VERIFIED_PASSED":"Thank you, you have already been verified.",
  "CONSENT_AUTHORIZATION_HEADER" : "Authorization",
  "COMMONS_NEXT" : "NEXT",
  "CONSENT_AUTHORIZATION_CONTENT" : "<p>The individual understands that, for security, identity verification, and fraud-prevention purposes, %0 (“the Company”) sometimes employs biometric technology to verify identity, to prevent fraud, and to control and limit access to the Company’s services and property.  In furtherance of these security, identity verification, and fraud-prevention purposes, the Company may collect biometric identifiers on individuals.  Under some applicable laws, biometric identifiers include a retina or iris scan, fingerprint, voiceprint, or record of hand or face geometry.</p><p>The biometric identifiers will be used exclusively for security, identity verification, and fraud prevention in connection with your access to the Company’s services or property.  The Company will not sell or lease your biometric identifiers unless required by law.</p><p>Please contact %1 if you have any questions.</p><p class=\"secondli\">I acknowledge that I have read and understand this notice regarding the collection and use of biometric identifiers.  I consent, as a condition of receiving or being considered to receive the Company’s services or access to its property, to providing biometric identifiers for the limited purpose of security, identity verification, and fraud prevention.</p>",
  "PLEASE_WAIT_PAGE_IS_BEING_REDIRECTED" : "Please wait for the next transition.",
  "CHOOSE_DOCUMENT_TYPE" :  "Choose the type of ID Document you'd like to use",
  "ERROR_MSG_DESKTOP_TRUE" : "Sorry, this capability isn't supported using a laptop or desktop browser. You'll need to do this on a mobile device, like a smart phone, using the same link.",
  "OPTOUT_MODAL_HEADER_1" : "Click OPT-OUT or click GO BACK to resume completing the application.",
  "COMMONS_OPTOUT" : "OPT-OUT",
  "COMMONS_GOBACK" : "GO BACK",
  "ERROR_MSG_OPT_OUT" : "You have opted out. Thank You.",
  "DONT_CONTINUE" : "I don't want to continue",
  "CONSENT_SIGN_TEXT" : "Authorization Signature",
  "CONSENT_SIGN_TEXT_1" : " * Use your mouse or touch.",
  "SIGN_MODAL_BOTTOM_1" : "%0",
  "SIGN_MODAL_BTN_3" : "CLEAR",
  "SIGN_MODAL_BTN_1" : "SIGN",
  "COMMONS_CANCEL" : "CANCEL",
  "HAVING_TROUBLE": "Having trouble?",
  "TRY_ALTERNATE_METHODS": "to try alternate methods",
  "COMMONS_TRYALT": "Try alternate methods",
  "TRY_ALTERNATE_CLICK_HERE": "Tap here",
  "REVIEW_SELFIE": "Review Picture"
};
