import { NgModule } from '@angular/core';
import { IdentityComponent } from './identity.component';
import { TranslateService } from './framework/i18n/translate.service';
import { TRANSLATION_PROVIDERS } from './framework/i18n/translations';
import { TranslatePipe } from './framework/i18n/translate.pipe';
import { ConsentComponent } from './consent/consent.component';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { IdentityRoutingModule } from './identity-routing.module';
import { IdCameraModule } from '@fadv/id-camera';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { environment } from './environments/environment';
import { LandingComponent } from './landing/landing.component';
import { ApplicantInfoComponent } from './applicant-info/applicant-info.component';
import { ErrorComponent } from './error/error.component';
import { CameraFlowComponent } from './camera-flow/camera-flow.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { IDComponent } from './id/id.component';
import { IdSelectComponent } from './id/id-select.component';
import { SelfieComponent } from './selfie/selfie.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { DatePipe } from '@angular/common';
import { IdentityPreCheckComponent } from './identity-pre-check/identity-pre-check.component';
import {SignaturePadExtendedModule} from '@fadv/signature-pad-extended';
import { MitekCameraModule } from '@fadv/mitek-camera';
import {SelfieReviewComponent} from "./selfie-review/selfie-review.component";
import { DebounceDirective } from './directive/debounce.directive';

@NgModule({
  declarations: [IdentityComponent, TranslatePipe,
    ConsentComponent, LandingComponent, ApplicantInfoComponent,
    ErrorComponent, InstructionsComponent, ConfirmComponent, CameraFlowComponent,
    IDComponent, IdSelectComponent, SelfieComponent, ConfirmationComponent, IdentityPreCheckComponent, SelfieReviewComponent, DebounceDirective],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    IdentityRoutingModule,
    IdCameraModule,
    MitekCameraModule,
    SignaturePadExtendedModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [TranslateService, TRANSLATION_PROVIDERS, DatePipe, TranslatePipe],
  exports: [InstructionsComponent, ConfirmComponent, CameraFlowComponent, LandingComponent, IdentityPreCheckComponent,
    ApplicantInfoComponent, IdSelectComponent, IDComponent, SelfieComponent, ConfirmationComponent,
    ErrorComponent, TranslatePipe, SelfieReviewComponent]
})
export class IdentityModule { }
