export class IDConfig {

    static steps: any = [
        'instruction_frontId',
        'id_frontId',
        'confirm_frontId',
        'instruction_backId',
        'id_backId',
        'confirm_backId',
    ];

    static getSteps(): any {
        return IDConfig.steps;
    }
}
