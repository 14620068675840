import { Component, Inject, OnInit } from '@angular/core';
import { LocalStorageService } from '@fadv/web-common';
import { AppConfig } from './app-config';
import { TranslateService } from 'src/framework/i18n';
import { SharedService } from './shared/Services/shared.service';
import { TranslatePipe } from '@fadv/identity';
import { environment } from '../../projects/fadv/identity/src/lib/environments/environment';
import { DOCUMENT } from '@angular/common';

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'TruID';
  displayLanguage: any[] = [];

  constructor(private localStorage: LocalStorageService,
              private _ts: TranslateService,
              private shareService: SharedService,
              private _tp: TranslatePipe,
              @Inject(DOCUMENT) private document: any,) {
    let body = this.document.getElementsByTagName('body')[0];
    let myScript = document.createElement("script");
    
    myScript.setAttribute("type", "module");
    myScript.setAttribute("src", "./../assets/js/fadv-support-app.cjs.production.min.js");
    body.appendChild(myScript);

    let customScript = document.createElement("fadv-support-app");
    body.appendChild(customScript);
  }

  public get currentLang(): string {
    return this._ts.currentLang;
  }

  ngOnInit() {
    this.localStorage.clear();
    let defaultLanguage = AppConfig.getDefaultLanguage();
    
    this.localStorage.setItem('websdk_vendor',environment.websdk_vendor);
    this.localStorage.setItem('verification_api_vendor',environment.verification_api_vendor);
    console.log('$$$WEB - VENDOR$$$ ' + this.localStorage.getItem('websdk_vendor'));
    console.log('$$$API - VENDOR$$$ ' + this.localStorage.getItem('verification_api_vendor'));
    this.localStorage.setItem('show_non_us_flow',environment.show_non_us_flow);
    console.log('SHOW_NON_US_FLOW ' + this.localStorage.getItem('show_non_us_flow'));

    this.displayLanguage = [];
    let diplayLanguageList = AppConfig.getDiplayLanguageList();
    let languageCodes = Object.keys(diplayLanguageList);
    for (const languageCode of languageCodes) {
      let languageObj: any;
      languageObj = {};
      languageObj['languageCode'] = languageCode;
      languageObj['languageName'] = diplayLanguageList[languageCode];
      this.displayLanguage.push(languageObj);
    }
    console.log("displayLanguage :" + this.displayLanguage);

    this._ts.use(defaultLanguage);
    this._tp.use(defaultLanguage);
    console.log("GOVERNMENT_ID_DOC value :" + this._ts.instant("GOVERNMENT_ID_DOC"));

  }

  updateLanguage(language) {
    this._ts.use(language);
    this._tp.use(language);
    let inviteId = this.localStorage.getItem("invite_id");
    let inviteKey = this.localStorage.getItem("invite_key");
    if (inviteId === undefined) {
      inviteId = '1';
    }

    let obj = {};
    obj['language'] = language;
    obj['language_country_code'] = this.localStorage.getItem("userPrefCountryCode");;
    obj['key'] = inviteKey;
    if (inviteKey !== undefined && inviteKey !== '') {
      this.shareService.updateInvite(obj).subscribe(response => {
        console.log("updateLanguage response :", response);
        window.location = AppConfig.API_ENDPOINT() + '/invite/' + inviteId;
      },
        error => {
          //TODO
          console.log("Error occurred ", error);
          window.location = AppConfig.API_ENDPOINT() + '/invite/' + inviteId;
        });
    } else {
      window.location = AppConfig.API_ENDPOINT() + '/invite/' + inviteId;
    }


    //
  }
}
