import { Component, ViewChild, OnInit, Input, Output, EventEmitter, ElementRef} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';


@Component({
  selector: 'lib-signature-pad-extended',
  templateUrl: './signature-pad-extended.component.html'
})
export class SignaturePadExtendedComponent implements OnInit {
  
  constructor() { }
  @ViewChild(SignaturePad, { static: true }) private signaturePad: SignaturePad;

  @ViewChild('confirmSignatureImage', { static: true }) confirmSignatureImage: ElementRef;
  @ViewChild('signatureContent', { static: true }) public signatureContent: ElementRef;
  @ViewChild('confirmSignatureContent', { static: true }) confirmSignatureContent: ElementRef;
  @ViewChild('confirmSignatureTextSection', { static: true }) public confirmSignatureTextSection: ElementRef;

  private signature: any = null;

  @Input() displayLabels: any;
  @Input() padNote: any;
  @Input() confirmSignatureText: any;
  @Input() useFontBasedSignature: boolean;
  @Input() signatureFont: string;
  @Input() signatureLabel: string;
  @Input() signatureInitialValue: string;
  @Output() onDrawComplete: EventEmitter<any> = new EventEmitter();

  fontBasedSignature: string = '';
  isFontBasedSignature: boolean = false;

  ngOnInit() {
    console.log("Signature init..");
    this.fontBasedSignature = this.signatureInitialValue;
    this.resize();
  }
  
  public ngAfterViewInit() {
    this.hideConfirmSignature();
    this.signaturePad.clear();
    this.resize();
    if (this.useFontBasedSignature) {
      this.drawFontBasedSignature();
    }
  }

  // set the dimensions of the signature pad canvas
  public resize() {
    console.log('Resizing signature pad canvas to suit container size');
    var canvas_bg : any =  document.getElementById("canvas_bg");
    var canvas : any = document.getElementById('sigPad').firstChild;
    this.size(canvas_bg, canvas);
  }

  public size(container: any, canvas: any) {
    let winWidth = window.screen.width;
    let canvsWidthNew = winWidth;
    let ctx = canvas.getContext('2d');
    if (canvsWidthNew <= 450) {
      canvsWidthNew = canvsWidthNew - 60;
       ctx.canvas.width = canvsWidthNew;
    }
    else {
       if (ctx.canvas.width == 0 || ctx.canvas.width == ''){
         ctx.canvas.width = 566;
        } else {
          ctx.canvas.width = container.clientWidth;
        }
    }
  }

  // public setOptions() {
  //   this.signaturePad.set('penColor', 'rgb(0, 0, 0)');
  // }

  public setPenColor(color: any){
    this.signaturePad.set('penColor', color);
  }

  public getSignature(): any {
    return this.signature;
  }

  public setSignature(value: any) {
    console.log('signature data :');
    this.signature = value;
    this.signaturePad.fromDataURL(value)
    console.log('set signature to ' + this.signature);
  }

  public isFontBasedSignatureUsed(): boolean {
    return this.isFontBasedSignature;
  }

  drawBegin(): void {
    console.log('Begin Drawing');
    if (this.useFontBasedSignature && this.isFontBasedSignature) {
      this.isFontBasedSignature = false;
      let canvas: any = document.getElementById('sigPad').firstChild;
      let ctx = canvas.getContext('2d');
      //Clear the canvas
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      this.signature = '';
    }
  }

  drawComplete(): void {
    this.signature = this.signaturePad.toDataURL();
    console.log("signature content - " + this.signature);
    var obj = {};
    this.onDrawComplete.emit(obj);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = '';
    var obj = {};
    this.onDrawComplete.emit(obj);
  }

  public promptConfirmSignature(){
    let img = this.confirmSignatureImage.nativeElement;
    img.src = this.signature;
    this.showConfirmSignature();
  }

  public hideConfirmSignature(){
    this.signatureContent.nativeElement.style.display = "";
    this.signatureContent.nativeElement.classList.add("confirm-sign-animate-right");
    this.confirmSignatureContent.nativeElement.style.display = "none";
    this.confirmSignatureTextSection.nativeElement.style.display = "none";
  }

  public showConfirmSignature(){
    this.signatureContent.nativeElement.style.display = "none";
    this.confirmSignatureTextSection.nativeElement.style.display = "";
    this.confirmSignatureContent.nativeElement.style.display = "";
  }

  drawFontBasedSignature() {
    let fbSignature = this.fontBasedSignature;
    this.signaturePad.clear();
    this.signature = '';
    if (!!fbSignature && !!fbSignature.trim()) {
      let canvas: any = document.getElementById('sigPad').firstChild;
      let ctx = canvas.getContext('2d');
      let x = 20;
      let y = 50;
      ctx.font = this.signatureFont;
      ctx.fillText(fbSignature.trim(), x, y);
      this.isFontBasedSignature = true;
      this.signature = this.signaturePad.toDataURL();
    }
    let obj = {};
    this.onDrawComplete.emit(obj);
  }
}
