import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const appRoutes: Routes = [
 /* {
    path: 'app-consent',
    component: ConsentComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-landing',
    component: LandingComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'invite/:token',
    component: PreAuthComponent,
  },
  {
    path: 'app-applicant-info',
    component: ApplicantInfoComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-id-select',
    component: IdSelectComponent,
    canActivate: [DirectAccessGuardService]
  },  
  {
    path: 'app-id',
    component: IDComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-selfie',
    component: SelfieComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-confirmation',
    component: ConfirmationComponent,
    canActivate: [DirectAccessGuardService]
  },*/
/*  {
    path: 'app-error',
    component: ErrorComponent
  }, */ 
  { path: 'external', redirectTo: '/www.google.com', pathMatch: 'full' },
  { path: '', redirectTo: '/app-landing', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
