<div class="padding-15" *ngIf="currentStep  === 'instructions'">
  <app-instructions (onInstructionNext)="onInstructionNext($event)">
  <div *ngIf="webSdkVendor === VENDOR_MITEK">
      <div class="row">
        <h3>{{'SELFIE_SINGLE_INSTR_MSG_1' | translate}}</h3>
      </div>
      <div class="row m-t-15">
        {{'SELFIE_SINGLE_INSTR_MSG_2' | translate}}
      </div>
    </div>
    <div class="row m-t-15 text-center">
      <img alt="Selfie img" class="mx-auto d-block m-4" style="max-width: 300px !important;"
        src="assets/img/selfie_help.gif">
    </div>
  </app-instructions>
</div>
<div *ngIf="currentStep  === 'selfie_near' && webSdkVendor === VENDOR_MITEK">
  <lib-mitek-camera  (onCameraTimeout)="onCameraTimeout($event)" (onCameraNext)="onCameraNext($event)" [resultImage]="resultImage" [config]="config">
    <div>

    </div>
  </lib-mitek-camera>
</div>

<div class="modal fade custom_modal" id="errorModal" role="dialog" data-backdrop="static" data-keyboard="false"
  style="z-index: 16777100;">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="transparentbg modal-body">
        <div style="text-align: center;">
          <h4 class="modal-title">
            {{'ERROR_TITLE' | translate}}
          </h4>
          <h6 class="modal-title">
            {{errorMessage}}
          </h6>          
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary col-md-offset-3 col-md-6 col-sm-12 col-lg-3" type="button" data-dismiss="modal" id="errorModelOKButton">{{ 'BUTTON_OK' | translate }}</button>
      </div>
    </div>
  </div>
</div>
