<div>
  <div *ngIf="currentStep == 'instruction'">
    <app-instructions (onInstructionNext)="onInstructionNext($event)">
      <div>

      </div>
    </app-instructions>
  </div>
  <div *ngIf="currentStep == 'camera'">
    <lib-id-camera (onCameraNext)="onCameraNext($event)" [resultImage]="resultImage" [config]="config"
      (onCameraAborted)="onCameraAborted($event)" (onCameraUIEvent)="onCameraUIEvent($event)"
      (onCameraError)="onCameraError($event)">
      <div>

      </div>
    </lib-id-camera>
  </div>
  <div *ngIf="currentStep == 'confirm'">
    <app-confirm (onConfirmNext)="onConfirmNext($event)" (onConfirmRetake)="onConfirmRetake($event)"
      [resultImage]="resultImage">
      <div>

      </div>
    </app-confirm>
  </div>
</div>
