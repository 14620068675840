import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsentComponent } from './consent/consent.component';
import { DirectAccessGuardService } from './shared/Services/direct-access-guard.guard';
import { LandingComponent } from './landing/landing.component';
import { ApplicantInfoComponent } from './applicant-info/applicant-info.component';
import { ErrorComponent } from './error/error.component';
import { IdSelectComponent } from './id/id-select.component';
import { IDComponent } from './id/id.component';
import { SelfieComponent } from './selfie/selfie.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { IdentityPreCheckComponent } from './identity-pre-check/identity-pre-check.component';
import {SelfieReviewComponent} from './selfie-review/selfie-review.component';

const appRoutes: Routes = [
 {
    path: 'app-consent',
    component: ConsentComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-landing',
    component: LandingComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'invite/:token',
    component: IdentityPreCheckComponent,
  },

  {
    path: 'app-applicant-info',
    component: ApplicantInfoComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-id-select',
    component: IdSelectComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-id',
    component: IDComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-selfie',
    component: SelfieComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'selfie-review',
    component: SelfieReviewComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-confirmation',
    component: ConfirmationComponent,
    canActivate: [DirectAccessGuardService]
  },
  {
    path: 'app-error',
    component: ErrorComponent
  },
  { path: 'external', redirectTo: '/www.google.com', pathMatch: 'full' },
  { path: '', redirectTo: '/app-landing', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
    enableTracing: false,
    useHash: true,
    relativeLinkResolution: 'legacy'
}
    )
  ],
  exports: [
    RouterModule
  ]
})
export class IdentityRoutingModule { }
